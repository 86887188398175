/**
 * @file ProjectDetailPage.js
 * @description プロジェクト詳細を表示するコンポーネント
 */

import React, { useState, useRef } from 'react';
import { 
  Box, 
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Snackbar,
  Alert,
  Paper,
  Typography,
  Tabs,
  Tab,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  ButtonBase,
  Zoom,
  ClickAwayListener,
  ToggleButtonGroup,
  ToggleButton,
  Tooltip,
  IconButton,
  Collapse,
} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import ja from 'date-fns/locale/ja';
import AddIcon from '@mui/icons-material/Add';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ViewListIcon from '@mui/icons-material/ViewList';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ProjectCard from './ProjectCard';
import ProjectDialog from './ProjectDialog';
import { useProjectData } from '../../hooks/useProjectData';
import { useGoalData } from '../../hooks/useGoalData';
import { useTaskData } from '../../hooks/useTaskData';
import GoalTreeChart from '../charts/GoalTreeChart';
import GoalsTable from '../goals/GoalsTable';
import TaskTableContainer from '../tasks/TaskTableContainer';
import TaskProgress from '../tasks/TaskProgress';
import SlidingPage from '../common/SlidingPage';
import GoalDetailPage from '../../pages/GoalDetailPage';
import RecentReflections from '../reflections/RecentReflections';
import ReflectionTable from '../reflections/ReflectionTable';
import ReflectionDialog from '../reflections/ReflectionDialog';
import ReflectionRemoveDialog from '../reflections/ReflectionRemoveDialog';
import { useReflectionData } from '../../hooks/useReflectionData';
import FlagIcon from '@mui/icons-material/Flag';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import GoalForm from '../../pages/goals/GoalForm';
import ReflectionForm from '../reflections/ReflectionForm';
import { reflectionService } from '../../services/reflectionService';
import DeleteIcon from '@mui/icons-material/Delete';
import LinkIcon from '@mui/icons-material/Link';

/**
 * プロジェクト詳細ページコンポーネント
 * プロジェクトの詳細情報、関連する目標、タスク、実績を表示する
 * 
 * @component
 * @param {Object} props - コンポーネントのプロパティ
 * @param {Object} props.project - プロジェクトの情報
 * @param {Array} props.relatedGoals - 関連する目標の配列
 * @param {Array} props.relatedTasks - 関連するタスクの配列
 */
const ProjectDetailPage = ({ 
  project,
  relatedGoals = [],
  relatedTasks = []
}) => {
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [viewMode, setViewMode] = useState('tree'); // 'tree' or 'table'
  const [selectedGoalId, setSelectedGoalId] = useState(null);
  const [isDetailOpen, setIsDetailOpen] = useState(false);
  
  // タスク管理用のstate
  const [showCompleted, setShowCompleted] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedPriority, setSelectedPriority] = useState('all');
  const [selectedDueDate, setSelectedDueDate] = useState('all');
  const [selectedGoalFilter, setSelectedGoalFilter] = useState('all');

  // タスク追加フォーム用のstate
  const [newTask, setNewTask] = useState({
    content: '',
    priority: 'medium',
    dueDate: null,
    goal_id: null,
    reference_links: [{ url: '', title: '' }],
  });
  const [showProjectGoalForm, setShowProjectGoalForm] = useState(false);
  const formRef = useRef(null);

  // 振り返り関連のstate
  const [reflectionViewMode, setReflectionViewMode] = useState('card');
  const [selectedReflections, setSelectedReflections] = useState([]);
  const [editingReflection, setEditingReflection] = useState(null);
  const [isReflectionFormOpen, setIsReflectionFormOpen] = useState(false);
  const [deletingReflection, setDeletingReflection] = useState(null);

  // 目標・実績作成用のstate
  const [isGoalFormOpen, setIsGoalFormOpen] = useState(false);
  const [isNewReflectionFormOpen, setIsNewReflectionFormOpen] = useState(false);
  const [goalToEdit, setGoalToEdit] = useState(null);

  const { 
    handleUpdateProject,
    handleDeleteProject,
    handleCompleteProject
  } = useProjectData();

  const {
    goals,
    completedGoals,
    handleDeleteGoal,
    handleEditGoal,
    fetchGoals
  } = useGoalData(project?.id);

  const {
    tasks,
    handleAddTask,
    handleEditTask,
    handleToggleTask,
    handleDeleteTask
  } = useTaskData(project?.id, selectedGoalFilter === 'all' ? null : selectedGoalFilter);

  // 振り返りデータの取得
  const { 
    reflections, 
    loading: reflectionsLoading, 
    error: reflectionsError, 
    handleDeleteReflection,
    handleEditReflection: updateReflection,
    fetchReflections 
  } = useReflectionData(null, project?.id);

  // 目標データにプロジェクト情報を追加
  const goalsWithProjects = React.useMemo(() => {
    return goals.map(goal => ({
      ...goal,
      project: project,
    }));
  }, [goals, project]);

  // プロジェクトの編集ハンドラー
  const handleEditProject = () => {
    setIsEditDialogOpen(true);
  };

  // プロジェクトの編集保存ハンドラー
  const handleSaveProject = async (updatedProject) => {
    try {
      await handleUpdateProject(project.id, updatedProject);
      setIsEditDialogOpen(false);
      setSnackbar({
        open: true,
        message: 'プロジェクトを更新しました',
        severity: 'success'
      });
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'プロジェクトの更新に失敗しました',
        severity: 'error'
      });
    }
  };

  // プロジェクトの削除確認ダイアログを表示
  const handleDeleteClick = () => {
    setIsDeleteDialogOpen(true);
  };

  // プロジェクトの削除実行
  const handleDeleteConfirm = async () => {
    try {
      await handleDeleteProject(project.id);
      setIsDeleteDialogOpen(false);
      setSnackbar({
        open: true,
        message: 'プロジェクトを削除しました',
        severity: 'success'
      });
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'プロジェクトの削除に失敗しました',
        severity: 'error'
      });
    }
  };

  // プロジェクトの完了ハンドラー
  const handleCompleteClick = async () => {
    try {
      await handleCompleteProject(project.id, new Date().toISOString());
      setSnackbar({
        open: true,
        message: 'プロジェクトを完了しました',
        severity: 'success'
      });
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'プロジェクトの完了に失敗しました',
        severity: 'error'
      });
    }
  };

  // 表示モード切り替えハンドラー
  const handleViewModeChange = (event, newValue) => {
    setViewMode(newValue);
  };

  // 目標クリックハンドラー
  const handleGoalClick = (goalId) => {
    setSelectedGoalId(goalId);
    setIsDetailOpen(true);
  };

  // 目標詳細を閉じるハンドラー
  const handleCloseDetail = () => {
    setIsDetailOpen(false);
    setSelectedGoalId(null);
    fetchGoals(); // 目標データを再取得
  };

  // スナックバーを閉じる
  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const handleFormFocus = () => {
    setShowProjectGoalForm(true);
  };

  const handleFormClickAway = () => {
    // フォーカスを外してもフォームは閉じない
  };

  const resetForm = () => {
    setNewTask({
      content: '',
      priority: 'medium',
      dueDate: null,
      goal_id: null,
      reference_links: [{ url: '', title: '' }],
    });
    setShowProjectGoalForm(false);
  };

  const handleAddNewTask = async (e) => {
    e.preventDefault();
    if (newTask.content.trim()) {
      // 空の参照情報を除外
      const filteredLinks = newTask.reference_links.filter(link => link.url && link.title);
      await handleAddTask(
        newTask.content,
        newTask.priority,
        newTask.dueDate,
        project.id,
        newTask.goal_id === 'none' ? null : newTask.goal_id,
        filteredLinks
      );
      resetForm();
    }
  };

  const handleGoalSelect = (goalId) => {
    setNewTask(prev => ({
      ...prev,
      goal_id: goalId === 'none' ? null : goalId,
    }));
  };

  // 振り返り表示モード切り替えハンドラー
  const handleReflectionViewModeChange = (event, newMode) => {
    if (newMode !== null) {
      setReflectionViewMode(newMode);
    }
  };

  // 振り返り編集ハンドラー
  const handleReflectionEditClick = (reflection) => {
    setEditingReflection(reflection);
    setIsReflectionFormOpen(true);
  };

  // 振り返り削除ハンドラー
  const handleDeleteReflectionClick = async (reflection) => {
    setDeletingReflection(reflection);
  };

  // 振り返り削除確認ハンドラー
  const handleConfirmDelete = async () => {
    if (deletingReflection) {
      try {
        await handleDeleteReflection({ id: deletingReflection.id });
        setDeletingReflection(null);
        fetchReflections();
      } catch (error) {
        console.error('振り返りの削除に失敗しました:', error);
        setSnackbar({
          open: true,
          message: '振り返りの削除に失敗しました',
          severity: 'error'
        });
      }
    }
  };

  // 振り返り保存ハンドラー
  const handleSaveReflection = async (updatedReflection) => {
    try {
      await updateReflection(updatedReflection);
      setIsReflectionFormOpen(false);
      setEditingReflection(null);
      fetchReflections();
      setSnackbar({
        open: true,
        message: '振り返りを更新しました',
        severity: 'success'
      });
    } catch (error) {
      console.error('振り返りの更新に失敗しました:', error);
      setSnackbar({
        open: true,
        message: '振り返りの更新に失敗しました',
        severity: 'error'
      });
    }
  };

  // 目標作成ハンドラー
  const handleCreateGoal = (initialData = {}) => {
    setGoalToEdit({
      ...initialData,
      project_id: project.id, // 現在のプロジェクトIDを設定
      parent_goal_id: initialData.parent_goal_id || null,
      parentGoal: initialData.parentGoal || null
    });
    setIsGoalFormOpen(true);
  };

  // 目標フォームを閉じるハンドラー
  const handleCloseGoalForm = () => {
    setIsGoalFormOpen(false);
    setGoalToEdit(null);
  };

  // 目標保存後のハンドラー
  const handleGoalSave = async (savedGoal) => {
    await fetchGoals();
    handleCloseGoalForm();
  };

  // 新規振り返り作成ハンドラー
  const handleCreateReflection = () => {
    // デフォルトの振り返りデータを設定
    const defaultReflection = {
      project_id: project.id,
      project: project,  // プロジェクト情報全体
      goals: goals,      // プロジェクトに紐づく目標一覧
      date: new Date()   // 現在の日付をデフォルトで設定
    };
    setEditingReflection(defaultReflection);
    setIsNewReflectionFormOpen(true);
  };

  // 新規振り返りフォームを閉じるハンドラー
  const handleCloseNewReflectionForm = () => {
    setIsNewReflectionFormOpen(false);
  };

  // 新規振り返り保存ハンドラー
  const handleSaveNewReflection = async (reflectionData) => {
    try {
      // 新規作成の場合は createReflection を使用
      await reflectionService.createReflection(reflectionData);
      await fetchReflections();
      handleCloseNewReflectionForm();
      setSnackbar({
        open: true,
        message: '振り返りを作成しました',
        severity: 'success'
      });
    } catch (error) {
      console.error('振り返りの作成に失敗しました:', error);
      setSnackbar({
        open: true,
        message: '振り返りの作成に失敗しました',
        severity: 'error'
      });
    }
  };

  // 参照情報の操作関数を追加
  const handleAddLink = () => {
    setNewTask(prev => ({
      ...prev,
      reference_links: [...prev.reference_links, { url: '', title: '' }]
    }));
  };

  const handleRemoveLink = (index) => {
    setNewTask(prev => ({
      ...prev,
      reference_links: prev.reference_links.filter((_, i) => i !== index)
    }));
  };

  const handleLinkChange = (index, field, value) => {
    setNewTask(prev => ({
      ...prev,
      reference_links: prev.reference_links.map((link, i) => 
        i === index ? { ...link, [field]: value } : link
      )
    }));
  };

  if (!project) return null;

  return (
    <Container maxWidth="xl">
      <Box sx={{ py: 4 }}>
        {/* 目標・実績追加ボタン */}
        <Box sx={{ 
          display: 'flex', 
          gap: 2, 
          mb: 3,
          justifyContent: 'center'
        }}>
          <Zoom in={true} style={{ transitionDelay: '100ms' }}>
            <ButtonBase
              onClick={() => handleCreateGoal()}
              sx={{
                borderRadius: '24px',
                overflow: 'hidden',
                position: 'relative',
                '&:hover': {
                  transform: 'scale(1.02)',
                  transition: 'transform 0.2s ease-in-out',
                },
              }}
            >
              <Box
                sx={{
                  background: 'linear-gradient(45deg, #FF9800 30%, #FFC107 90%)',
                  color: 'white',
                  py: 1,
                  px: 2.5,
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                  boxShadow: '0 3px 5px 2px rgba(255, 193, 7, .3)',
                  '&:hover': {
                    background: 'linear-gradient(45deg, #F57C00 30%, #FFA000 90%)',
                  },
                }}
              >
                <AddIcon />
                <FlagIcon />
                <Typography variant="button">
                  新しい目標
                </Typography>
              </Box>
            </ButtonBase>
          </Zoom>

          <Zoom in={true} style={{ transitionDelay: '200ms' }}>
            <ButtonBase
              onClick={handleCreateReflection}
              sx={{
                borderRadius: '24px',
                overflow: 'hidden',
                position: 'relative',
                '&:hover': {
                  transform: 'scale(1.02)',
                  transition: 'transform 0.2s ease-in-out',
                },
              }}
            >
              <Box
                sx={{
                  background: 'linear-gradient(45deg, #9C27B0 30%, #E91E63 90%)',
                  color: 'white',
                  py: 1,
                  px: 2.5,
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                  boxShadow: '0 3px 5px 2px rgba(233, 30, 99, .3)',
                  '&:hover': {
                    background: 'linear-gradient(45deg, #7B1FA2 30%, #C2185B 90%)',
                  },
                }}
              >
                <AddIcon />
                <EmojiEventsIcon />
                <Typography variant="button">
                  新しい実績
                </Typography>
              </Box>
            </ButtonBase>
          </Zoom>
        </Box>

        {/* プロジェクトカード */}
        <ProjectCard
          project={project}
          relatedGoals={relatedGoals}
          relatedTasks={relatedTasks}
          onEdit={handleEditProject}
          onDelete={handleDeleteClick}
          onComplete={handleCompleteClick}
        />

        {/* 目標管理セクション */}
        <Paper sx={{ p: 2, mt: 4 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
            <Typography variant="h6">目標一覧</Typography>
            <Tabs value={viewMode} onChange={handleViewModeChange}>
              <Tab label="ツリー表示" value="tree" />
              <Tab label="テーブル表示" value="table" />
            </Tabs>
          </Box>
          <Box sx={{ minHeight: 400 }}>
            {viewMode === 'tree' ? (
              <GoalTreeChart 
                goals={goalsWithProjects}
                projects={[project]}
                completedGoals={completedGoals}
                onAddChildGoal={(initialData) => handleCreateGoal(initialData)}
                onGoalClick={handleGoalClick}
                onProjectClick={() => {}}
                selectedProjectId={project.id}
              />
            ) : (
              <GoalsTable 
                goals={goalsWithProjects}
                onGoalClick={handleGoalClick}
                onEditGoal={handleEditGoal}
                onDeleteGoal={handleDeleteGoal}
              />
            )}
          </Box>
        </Paper>

        {/* タスク管理セクション */}
        <Paper sx={{ p: 2, mt: 4 }}>
          <Box sx={{ mb: 3 }}>
            <Typography variant="h6" sx={{ mb: 2 }}>
              タスク一覧
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
              <TaskProgress tasks={tasks} />
            </Box>
          </Box>

          {/* タスク追加フォーム */}
          <Box component="form" onSubmit={handleAddNewTask} sx={{ mb: 3 }} ref={formRef}>
            <Typography variant="h6" sx={{ mb: 2 }}>
              新規タスク
            </Typography>
            <ClickAwayListener onClickAway={handleFormClickAway}>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <Box sx={{ display: 'flex', gap: 2, alignItems: 'flex-start' }}>
                  <Box sx={{ flex: 1 }}>
                    <TextField
                      size="small"
                      value={newTask.content}
                      onChange={(e) => setNewTask({ ...newTask, content: e.target.value })}
                      onFocus={handleFormFocus}
                      placeholder="タスクを入力"
                      fullWidth
                    />
                    <Collapse in={showProjectGoalForm}>
                      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
                        <Box sx={{ display: 'flex', gap: 2 }}>
                          <FormControl 
                            size="small" 
                            sx={{ minWidth: 200 }}
                          >
                            <InputLabel>目標（任意）</InputLabel>
                            <Select
                              value={newTask.goal_id || 'none'}
                              label="目標（任意）"
                              onChange={(e) => handleGoalSelect(e.target.value)}
                            >
                              <MenuItem value="none">未設定</MenuItem>
                              {goals.map((goal) => (
                                <MenuItem key={goal.id} value={goal.id}>
                                  {goal.title}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Box>

                        {/* 参照情報フォーム */}
                        <Box sx={{ mt: 2 }}>
                          <Typography variant="subtitle2" gutterBottom>
                            参照情報
                          </Typography>
                          {newTask.reference_links.map((link, index) => (
                            <Box key={index} display="flex" alignItems="center" mb={2}>
                              <TextField
                                label="URL"
                                value={link.url}
                                onChange={(e) => handleLinkChange(index, 'url', e.target.value)}
                                size="small"
                                fullWidth
                                sx={{ mr: 1 }}
                              />
                              <TextField
                                label="タイトル"
                                value={link.title}
                                onChange={(e) => handleLinkChange(index, 'title', e.target.value)}
                                size="small"
                                fullWidth
                                sx={{ mr: 1 }}
                              />
                              <IconButton onClick={() => handleRemoveLink(index)}>
                                <DeleteIcon />
                              </IconButton>
                            </Box>
                          ))}
                          <Button
                            startIcon={<LinkIcon />}
                            onClick={handleAddLink}
                            variant="outlined"
                            size="small"
                            fullWidth
                          >
                            参照リンクを追加
                          </Button>
                        </Box>
                      </Box>
                    </Collapse>
                  </Box>
                  <FormControl size="small" sx={{ minWidth: 120 }}>
                    <InputLabel>優先度</InputLabel>
                    <Select
                      value={newTask.priority}
                      label="優先度"
                      onChange={(e) => setNewTask({ ...newTask, priority: e.target.value })}
                    >
                      <MenuItem value="high">高</MenuItem>
                      <MenuItem value="medium">中</MenuItem>
                      <MenuItem value="low">低</MenuItem>
                    </Select>
                  </FormControl>
                  <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ja}>
                    <DateTimePicker
                      label="期限"
                      value={newTask.dueDate}
                      onChange={(newValue) => setNewTask({ ...newTask, dueDate: newValue })}
                      slotProps={{ textField: { size: 'small' } }}
                    />
                  </LocalizationProvider>
                  <Zoom in={true} style={{ transitionDelay: '100ms' }}>
                    <ButtonBase
                      type="submit"
                      sx={{
                        borderRadius: '24px',
                        overflow: 'hidden',
                        position: 'relative',
                        ml: 1,
                        '&:hover': {
                          transform: 'scale(1.02)',
                          transition: 'transform 0.2s ease-in-out',
                        },
                      }}
                    >
                      <Box
                        sx={{
                          background: 'linear-gradient(45deg, #4CAF50 30%, #8BC34A 90%)',
                          color: 'white',
                          py: 0.75,
                          px: 2,
                          display: 'flex',
                          alignItems: 'center',
                          gap: 1,
                          boxShadow: '0 3px 5px 2px rgba(139, 195, 74, .3)',
                          '&:hover': {
                            background: 'linear-gradient(45deg, #388E3C 30%, #689F38 90%)',
                          },
                        }}
                      >
                        <AddIcon />
                        <AssignmentIcon />
                        <Typography variant="button">
                          追加
                        </Typography>
                      </Box>
                    </ButtonBase>
                  </Zoom>
                </Box>
              </Box>
            </ClickAwayListener>
          </Box>

          <TaskTableContainer
            tasks={tasks}
            projects={[project]}
            goals={goals}
            showCompleted={showCompleted}
            setShowCompleted={setShowCompleted}
            tableProps={{
              page,
              rowsPerPage,
              selectedProject: project.id,
              selectedGoal: selectedGoalFilter,
              selectedPriority,
              selectedDueDate,
              handleToggleTask,
              handleTaskContentEdit: async (taskId, content) => {
                await handleEditTask(taskId, { content });
              },
              handleTaskProjectEdit: () => {}, // プロジェクトの変更は不可
              handleTaskGoalEdit: async (taskId, goalId) => {
                await handleEditTask(taskId, { goal_id: goalId });
              },
              handleTaskPriorityEdit: async (taskId, priority) => {
                await handleEditTask(taskId, { priority });
              },
              handleTaskDueDateEdit: async (taskId, dueDate) => {
                await handleEditTask(taskId, { due_date: dueDate });
              },
              handleDeleteTask,
              setSelectedProject: () => {}, // プロジェクトの変更は不可
              setSelectedGoal: setSelectedGoalFilter,
              setSelectedPriority,
              setSelectedDueDate,
              setPage,
              setRowsPerPage,
              getPriorityColor: (priority) => {
                switch (priority) {
                  case 'high':
                    return '#ffebee';
                  case 'medium':
                    return '#fff3e0';
                  case 'low':
                    return '#f1f8e9';
                  default:
                    return 'transparent';
                }
              },
              getPriorityText: (priority) => {
                switch (priority) {
                  case 'high':
                    return '高';
                  case 'medium':
                    return '中';
                  case 'low':
                    return '低';
                  default:
                    return '中';
                }
              },
            }}
          />
        </Paper>

        {/* 振り返りセクション */}
        <Paper sx={{ p: 2, mt: 4 }}>
          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'space-between', 
            alignItems: 'center', 
            mb: 2 
          }}>
            <Typography variant="h6">振り返り一覧</Typography>
            <ToggleButtonGroup
              value={reflectionViewMode}
              exclusive
              onChange={handleReflectionViewModeChange}
              aria-label="表示モード"
              size="small"
            >
              <Tooltip title="カード表示">
                <ToggleButton value="card" aria-label="カード表示">
                  <ViewModuleIcon />
                </ToggleButton>
              </Tooltip>
              <Tooltip title="リスト表示">
                <ToggleButton value="list" aria-label="リスト表示">
                  <ViewListIcon />
                </ToggleButton>
              </Tooltip>
            </ToggleButtonGroup>
          </Box>

          {reflectionsLoading ? (
            <Typography>振り返りデータを読み込み中...</Typography>
          ) : reflectionsError ? (
            <Typography color="error">エラー: {reflectionsError}</Typography>
          ) : reflectionViewMode === 'list' ? (
            <ReflectionTable
              reflections={reflections}
              selectedReflections={selectedReflections}
              onSelectionChange={setSelectedReflections}
              projectId={project.id}
              onView={handleReflectionEditClick}
              onDelete={handleDeleteReflectionClick}
              initialDisplayCount={10}
            />
          ) : (
            <RecentReflections
              defaultProjectFilter={project.id}
              refreshTrigger={reflectionViewMode}
              onEdit={handleReflectionEditClick}
            />
          )}
        </Paper>

        {/* プロジェクト編集ダイアログ */}
        <ProjectDialog
          open={isEditDialogOpen}
          project={project}
          onClose={() => setIsEditDialogOpen(false)}
          onSave={handleSaveProject}
        />

        {/* 削除確認ダイアログ */}
        <Dialog
          open={isDeleteDialogOpen}
          onClose={() => setIsDeleteDialogOpen(false)}
        >
          <DialogTitle>プロジェクトの削除</DialogTitle>
          <DialogContent>
            <DialogContentText>
              プロジェクト「{project.name}」を削除してもよろしいですか？
              この操作は取り消せません。
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setIsDeleteDialogOpen(false)}>
              キャンセル
            </Button>
            <Button onClick={handleDeleteConfirm} color="error" variant="contained">
              削除
            </Button>
          </DialogActions>
        </Dialog>

        {/* 目標詳細スライディングページ */}
        <SlidingPage isOpen={isDetailOpen} onClose={handleCloseDetail}>
          {selectedGoalId && (
            <GoalDetailPage
              goalId={selectedGoalId}
              onClose={handleCloseDetail}
              onUpdate={fetchGoals}
            />
          )}
        </SlidingPage>

        {/* 振り返り編集フォーム */}
        <SlidingPage isOpen={isReflectionFormOpen} onClose={() => {
          setIsReflectionFormOpen(false);
          setEditingReflection(null);
        }}>
          {editingReflection && (
            <ReflectionForm
              goal={editingReflection.goals}
              reflection={editingReflection}
              onClose={() => {
                setIsReflectionFormOpen(false);
                setEditingReflection(null);
              }}
              onSave={handleSaveReflection}
              isDialog={true}
              initialProjectId={project?.id}
            />
          )}
        </SlidingPage>

        {/* 振り返り削除確認ダイアログ */}
        {deletingReflection && (
          <ReflectionRemoveDialog
            reflections={[deletingReflection.id]}
            onClose={() => setDeletingReflection(null)}
            onRemove={handleConfirmDelete}
            onActivityChange={fetchReflections}
          />
        )}

        {/* 目標作成フォーム */}
        <SlidingPage isOpen={isGoalFormOpen} onClose={handleCloseGoalForm}>
          <GoalForm
            goal={goalToEdit || { project_id: project.id }}
            onClose={handleCloseGoalForm}
            onSave={handleGoalSave}
          />
        </SlidingPage>

        {/* 新規振り返りフォーム */}
        <SlidingPage isOpen={isNewReflectionFormOpen} onClose={handleCloseNewReflectionForm}>
          <ReflectionForm
            onClose={handleCloseNewReflectionForm}
            onSave={handleSaveNewReflection}
            isDialog={true}
            defaultProjectId={project.id}
            defaultProject={project}
            defaultGoals={goals}
            reflection={editingReflection}
            initialProjectId={project.id}
          />
        </SlidingPage>

        {/* 通知用スナックバー */}
        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
          <Alert
            onClose={handleCloseSnackbar}
            severity={snackbar.severity}
            variant="filled"
            sx={{ width: '100%' }}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Box>
    </Container>
  );
};

export default ProjectDetailPage;
