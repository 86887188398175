/**
 * @fileoverview 日報詳細表示コンポーネント
 * @module ReportDetail
 */

import React, { useState, useEffect, useMemo } from 'react';
import {
  Box,
  Typography,
  Grid,
  Button,
  Divider,
  Chip,
  IconButton,
  TextField,
  Stack,
  Autocomplete,
  Paper,
  CircularProgress
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import { format, parseISO, startOfDay, endOfDay } from 'date-fns';
import { ja } from 'date-fns/locale';
import { useNavigate, useLocation } from 'react-router-dom';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import ReflectionCard from '../reflections/ReflectionCard';
import ReflectionSummaryStats from '../summary/ReflectionSummaryStats';
import ReflectionSkillPieChart from '../charts/ReflectionSkillPieChart';
import DailyActivityChart from '../charts/DailyActivityChart';
import MarkdownRenderer from '../common/MarkdownRenderer';
import { useReportData } from '../../hooks/useReportData';
import { useProjectData } from '../../hooks/useProjectData';
import { useGoalData } from '../../hooks/useGoalData';
import { useMessage } from '../../hooks/useMessage';
import { MDXEditor } from '@mdxeditor/editor';
import { getMDXEditorPlugins, getMDXEditorStyles, EDITOR_PLACEHOLDER_TEXT } from '../../utils/mdxEditorConfig';
import { openaiService } from '../../services/openaiService';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import LoadingButton from '@mui/lab/LoadingButton';

/**
 * 日報詳細表示コンポーネント
 * @param {Object} props - コンポーネントのプロパティ
 * @param {Object} props.report - 日報データ
 * @param {Function} props.onEdit - 編集時のコールバック
 * @param {Array} props.reflections - その日の振り返りデータ
 * @returns {JSX.Element} 日報詳細表示
 */
const ReportDetail = ({ report: initialReport, onEdit, reflections = [] }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isGeneratingSummary, setIsGeneratingSummary] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [report, setReport] = useState(initialReport);
  const { updateReport, refreshReports } = useReportData();
  const { projects } = useProjectData();
  const { goals } = useGoalData(report?.project_id);
  const { showMessage, MessageSnackbar } = useMessage();

  // 編集用の一時的な状態
  const [editedTitle, setEditedTitle] = useState('');
  const [editedContent, setEditedContent] = useState('');
  const [editedDate, setEditedDate] = useState(null);
  const [editedProjectId, setEditedProjectId] = useState('');
  const [editedGoalIds, setEditedGoalIds] = useState([]);
  const [isSaving, setIsSaving] = useState(false);

  // 振り返りデータを活動データとして整形
  const formattedActivities = useMemo(() => {
    return reflections.map(reflection => ({
      ...reflection,
      type: 'reflection',
      goals: {
        ...reflection.goals,
        title: reflection.goals?.title || '目標なし'
      }
    }));
  }, [reflections]);

  useEffect(() => {
    setReport(initialReport);
  }, [initialReport]);

  useEffect(() => {
    if (report) {
      setEditedTitle(report.title || '');
      setEditedContent(report.content || '');
      setEditedDate(report.report_date ? new Date(report.report_date) : null);
      setEditedProjectId(report.project_id || '');
      setEditedGoalIds(report.goal_ids || []);
    }
  }, [report]);

  useEffect(() => {
    if (report?.id) {
      const newUrl = `/reports/${report.id}`;
      if (location.pathname !== newUrl) {
        navigate(newUrl, { replace: true });
      }
    }
  }, [report, navigate, location]);

  if (!report) {
    return null;
  }

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
    // 編集内容を元に戻す
    setEditedTitle(report.title || '');
    setEditedContent(report.content || '');
    setEditedDate(report.report_date ? new Date(report.report_date) : null);
    setEditedProjectId(report.project_id || '');
    setEditedGoalIds(report.goal_ids || []);
    showMessage('編集をキャンセルしました', 'info');
  };

  const validateForm = () => {
    if (!editedDate) {
      showMessage('日付を選択してください', 'error');
      return false;
    }
    return true;
  };

  const handleSaveClick = async () => {
    if (!validateForm()) return;

    try {
      setIsSaving(true);
      const formattedDate = editedDate ? editedDate.toISOString().split('T')[0] : null;
      const updatedReport = {
        ...report,
        title: editedTitle.trim(),
        content: editedContent,
        report_date: formattedDate,
        project_id: editedProjectId || null,
        goal_ids: editedGoalIds,
        is_ai_generated: true,
        ai_summary: editedContent
      };

      const updated = await updateReport(report.id, updatedReport);
      
      if (onEdit) {
        await onEdit(updated);
      }

      setReport(updated);
      await refreshReports();
      setIsEditing(false);
      showMessage('日報を保存しました', 'success');
    } catch (error) {
      console.error('Failed to update report:', error);
      showMessage('日報の保存に失敗しました', 'error');
    } finally {
      setIsSaving(false);
    }
  };

  const handleGenerateSummary = async () => {
    if (!reflections || reflections.length === 0) {
      showMessage('振り返りデータがありません', 'warning');
      return;
    }

    try {
      setIsGeneratingSummary(true);
      const summary = await openaiService.generateSummary(reflections, 'gpt-4');
      
      // プロジェクトと目標の抽出
      const projectIds = new Set();
      const goalIds = new Set();
      
      reflections.forEach(reflection => {
        if (reflection.project_id) {
          projectIds.add(reflection.project_id);
        }
        if (reflection.goal_id) {
          goalIds.add(reflection.goal_id);
        }
      });

      // 最も頻出するプロジェクトを設定
      if (projectIds.size > 0) {
        const projectIdArray = Array.from(projectIds);
        const projectId = projectIdArray[0]; // 複数ある場合は最初のものを使用
        setEditedProjectId(projectId);
      }

      // 関連する目標をすべて設定
      if (goalIds.size > 0) {
        const goalIdArray = Array.from(goalIds);
        setEditedGoalIds(goalIdArray);
      }
      
      // 要約結果をタイトルと内容に反映
      setEditedTitle(`${format(editedDate || new Date(), 'yyyy/MM/dd')}の振り返り`);
      setEditedContent(summary);
      
      // 編集モードに切り替え
      setIsEditing(true);
      showMessage('AI要約を生成しました', 'success');
    } catch (error) {
      console.error('Failed to generate summary:', error);
      showMessage('要約の生成に失敗しました', 'error');
    } finally {
      setIsGeneratingSummary(false);
    }
  };

  // 日付範囲の設定
  let dateRange = {
    start: null,
    end: null
  };

  try {
    if (report.report_date) {
      const parsedDate = parseISO(report.report_date);
      if (!isNaN(parsedDate.getTime())) {
        dateRange = {
          start: format(startOfDay(parsedDate), 'yyyy-MM-dd'),
          end: format(endOfDay(parsedDate), 'yyyy-MM-dd')
        };
      }
    }
  } catch (error) {
    console.error('Error processing date:', error);
  }

  return (
    <Box sx={{ p: 2, pb: 8, position: 'relative', minHeight: '100vh' }}>
      <Paper elevation={1} sx={{ p: 3, mb: 3 }}>
        {/* ヘッダー部分 */}
        <Box sx={{ mb: 3 }}>
          <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between" sx={{ mb: 2 }}>
            <Box>
              {isEditing ? (
                <Stack spacing={2} sx={{ width: '100%' }}>
                  <DatePicker
                    label="日付"
                    value={editedDate}
                    onChange={setEditedDate}
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        required: true,
                        error: !editedDate,
                        helperText: !editedDate ? '日付は必須です' : ''
                      }
                    }}
                  />
                  <TextField
                    value={editedTitle}
                    onChange={(e) => setEditedTitle(e.target.value)}
                    fullWidth
                    label="タイトル"
                    variant="outlined"
                  />
                </Stack>
              ) : (
                <>
                  <Typography variant="h5" component="h1" gutterBottom>
                    {report.title}
                  </Typography>
                  <Typography variant="subtitle1" color="text.secondary">
                    {report.report_date ? format(new Date(report.report_date), 'yyyy年MM月dd日', { locale: ja }) : '日付未設定'}
                  </Typography>
                </>
              )}
            </Box>
            <Box>
              {!isEditing ? (
                <Stack direction="row" spacing={1}>
                  <LoadingButton
                    variant="outlined"
                    onClick={handleGenerateSummary}
                    disabled={!reflections?.length}
                    loading={isGeneratingSummary}
                    loadingPosition="start"
                    startIcon={<AutoFixHighIcon />}
                    loadingIndicator={
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <CircularProgress size={16} sx={{ mr: 1 }} />
                        要約生成中...
                      </Box>
                    }
                  >
                    {isGeneratingSummary ? '要約生成中...' : 'AI要約'}
                  </LoadingButton>
                  <Button
                    variant="outlined"
                    onClick={handleEditClick}
                    startIcon={<EditIcon />}
                  >
                    編集
                  </Button>
                </Stack>
              ) : (
                <Stack spacing={2} sx={{ width: '100%' }}>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<SaveIcon />}
                    onClick={handleSaveClick}
                    disabled={isSaving}
                  >
                    保存
                  </Button>
                  <Button
                    variant="outlined"
                    color="error"
                    startIcon={<CancelIcon />}
                    onClick={handleCancelEdit}
                    disabled={isSaving}
                  >
                    キャンセル
                  </Button>
                </Stack>
              )}
            </Box>
          </Stack>
          {isEditing ? (
            <Stack spacing={2}>
              <Autocomplete
                value={projects.find(p => p.id === editedProjectId) || null}
                onChange={(event, newValue) => setEditedProjectId(newValue?.id || '')}
                options={projects}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField {...params} label="プロジェクト" />
                )}
              />
              <Autocomplete
                multiple
                value={goals.filter(goal => editedGoalIds.includes(goal.id))}
                onChange={(event, newValue) => setEditedGoalIds(newValue.map(goal => goal.id))}
                options={goals}
                getOptionLabel={(option) => option.title}
                renderInput={(params) => (
                  <TextField {...params} label="目標（複数選択可）" />
                )}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      label={option.title}
                      {...getTagProps({ index })}
                      key={option.id}
                    />
                  ))
                }
                disabled={!editedProjectId}
              />
            </Stack>
          ) : (
            <Stack spacing={1}>
              {report.project && (
                <Box>
                  <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                    プロジェクト
                  </Typography>
                  <Chip label={report.project.name} />
                </Box>
              )}
              {goals.length > 0 && report.goal_ids && report.goal_ids.length > 0 && (
                <Box>
                  <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                    目標
                  </Typography>
                  <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
                    {goals
                      .filter(goal => report.goal_ids.includes(goal.id))
                      .map((goal) => (
                        <Chip key={goal.id} label={goal.title} variant="outlined" />
                      ))}
                  </Box>
                </Box>
              )}
            </Stack>
          )}
        </Box>

        {/* 日報内容 */}
        <Box>
          <Typography variant="h6" gutterBottom>
            内容
          </Typography>
          <Paper variant="outlined" sx={{ p: 2 }}>
            {isEditing ? (
              <Box sx={getMDXEditorStyles()}>
                <MDXEditor
                  markdown={editedContent}
                  onChange={setEditedContent}
                  plugins={getMDXEditorPlugins()}
                  placeholder={EDITOR_PLACEHOLDER_TEXT}
                />
              </Box>
            ) : (
              <MarkdownRenderer>
                {report.content}
              </MarkdownRenderer>
            )}
          </Paper>
        </Box>
      </Paper>

      {/* 活動サマリー */}
      <Paper elevation={1} sx={{ p: 3, mb: 3 }}>
        <Typography variant="h5" gutterBottom>
          本日の活動サマリー
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Paper variant="outlined" sx={{ p: 2 }}>
              <ReflectionSummaryStats 
                reflections={reflections}
                projectId={report.project?.id}
                startDate={dateRange.start}
                endDate={dateRange.end}
              />
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper variant="outlined" sx={{ p: 2 }}>
              <ReflectionSkillPieChart
                projectID={report.project?.id}
                startDate={dateRange.start}
                endDate={dateRange.end}
                reflections={reflections}
              />
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper variant="outlined" sx={{ p: 2 }}>
              <DailyActivityChart
                activities={formattedActivities}
                onActivityChange={() => {}}
                selectedProject={report.project?.id}
                startDate={dateRange.start}
                endDate={dateRange.end}
              />
            </Paper>
          </Grid>
        </Grid>
      </Paper>

      {/* 振り返り一覧 */}
      {reflections.length > 0 && (
        <Paper elevation={1} sx={{ p: 3 }}>
          <Typography variant="h5" gutterBottom>
            本日の振り返り
          </Typography>
          <Grid container spacing={2}>
            {reflections.map((reflection) => (
              <Grid item xs={12} md={6} key={reflection.id}>
                <ReflectionCard
                  reflection={reflection}
                  projectName={report.project?.name}
                  isViewingOther={false}
                />
              </Grid>
            ))}
          </Grid>
        </Paper>
      )}

      <MessageSnackbar />

      {/* 固定の編集ボタンエリア */}
      <Paper
        sx={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          p: 2,
          backgroundColor: 'background.paper',
          borderTop: 1,
          borderColor: 'divider',
          zIndex: 1000,
          display: 'flex',
          justifyContent: 'flex-end'
        }}
      >
        {isEditing ? (
          <Stack spacing={2} sx={{ width: '100%' }}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<SaveIcon />}
              onClick={handleSaveClick}
              disabled={isSaving}
            >
              保存
            </Button>
            <Button
              variant="outlined"
              color="error"
              startIcon={<CancelIcon />}
              onClick={handleCancelEdit}
              disabled={isSaving}
            >
              キャンセル
            </Button>
          </Stack>
        ) : (
          <Stack direction="row" spacing={1}>
            <LoadingButton
              variant="outlined"
              onClick={handleGenerateSummary}
              disabled={!reflections?.length}
              loading={isGeneratingSummary}
              loadingPosition="start"
              startIcon={<AutoFixHighIcon />}
              loadingIndicator={
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <CircularProgress size={16} sx={{ mr: 1 }} />
                  要約生成中...
                </Box>
              }
            >
              {isGeneratingSummary ? '要約生成中...' : 'AI要約'}
            </LoadingButton>
            <Button
              variant="outlined"
              onClick={handleEditClick}
              startIcon={<EditIcon />}
            >
              編集
            </Button>
          </Stack>
        )}
      </Paper>
    </Box>
  );
};

export default ReportDetail; 