/**
 * @file App.js
 * @description アプリケーションのメインコンポーネント
 */

import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ja from 'date-fns/locale/ja';

import theme from './theme';
import Navigation from './components/common/Navigation';
import PublicHeader from './components/common/PublicHeader';
import PrivateHeader from './components/common/PrivateHeader';
import LandingPage from './pages/LandingPage';
import Dashboard from './pages/Dashboard';
import LoginPage from './pages/LoginPage';
import RegisterPage from './pages/RegisterPage';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import ResetPasswordPage from './pages/ResetPasswordPage';
import ProfilePage from './pages/ProfilePage';
import AdminPage from './pages/AdminPage';
import MemberDetailPage from './pages/MemberDetailPage';
import Settings from './components/settings/Settings';
import { useAuth } from './hooks/useAuth';
import { useAdminRole } from './hooks/useAdminRole';
import { supabase } from './utils/supabase';
import PageLayout from './components/layout/PageLayout';
import DashboardPage from './pages/DashboardPage';
import GoalsPage from './pages/goals/GoalsPage';
import GoalDetailPage from './pages/GoalDetailPage';
import TasksPage from './pages/tasks/TasksPage';
import ProjectsPage from './pages/projects/ProjectsPage';
import AchievementsPage from './pages/achievements/AchievementsPage';
import { ReportsPage } from './pages/reports/ReportsPage';

function App() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { user, loading: authLoading } = useAuth();
  const { isAdmin, loading: adminLoading } = useAdminRole(user?.id);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleLogout = async () => {
    try {
      await supabase.auth.signOut();
    } catch (error) {
      console.error('ログアウトエラー:', error);
    }
  };

  if (authLoading || adminLoading) {
    return (
      <Box 
        sx={{ 
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center', 
          height: '100vh',
          width: '100vw'
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ja}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router>
          <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            {user ? (
              <>
                <PrivateHeader 
                  toggleMenu={toggleMenu} 
                  onLogout={handleLogout}
                />
                <Navigation 
                  isOpen={isMenuOpen} 
                  toggleMenu={toggleMenu}
                  isAdmin={isAdmin}
                />
                <Box component="main" sx={{ flexGrow: 1, pt: 9, px: 3 }}>
                  <Routes>
                    <Route path="/" element={<DashboardPage />} />
                    <Route path="/goals" element={<GoalsPage />} />
                    <Route path="/goals/:goalId" element={<GoalDetailPage />} />
                    <Route path="/tasks" element={<TasksPage />} />
                    <Route path="/projects" element={<ProjectsPage />} />
                    <Route path="/achievements" element={<AchievementsPage />} />
                    <Route path="/profile" element={<ProfilePage />} />
                    {isAdmin && (
                      <>
                        <Route path="/admin" element={<AdminPage />} />
                        <Route path="/admin/members/:memberId/:view" element={<MemberDetailPage />} />
                      </>
                    )}
                    <Route path="/settings" element={<Settings />} />
                    <Route path="/reports" element={<ReportsPage />} />
                    <Route path="/reports/:reportId" element={<ReportsPage />} />
                    <Route path="*" element={<Navigate to="/" replace />} />
                  </Routes>
                </Box>
              </>
            ) : (
              <>
                <Routes>
                  <Route path="/login" element={
                    <>
                      <PublicHeader hideAuthButtons />
                      <LoginPage />
                    </>
                  } />
                  <Route path="/register" element={
                    <>
                      <PublicHeader hideAuthButtons />
                      <RegisterPage />
                    </>
                  } />
                  <Route path="/forgot-password" element={
                    <>
                      <PublicHeader hideAuthButtons />
                      <ForgotPasswordPage />
                    </>
                  } />
                  <Route path="/reset-password" element={
                    <>
                      <PublicHeader hideAuthButtons />
                      <ResetPasswordPage />
                    </>
                  } />
                  <Route path="*" element={
                    <>
                      <PublicHeader />
                      <LandingPage />
                    </>
                  } />
                </Routes>
              </>
            )}
          </Box>
        </Router>
      </ThemeProvider>
    </LocalizationProvider>
  );
}

// 認証済みルートコンポーネント
const PrivateRoute = ({ children }) => {
  const { user, loading } = useAuth();

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!user) {
    return <Navigate to="/login" />;
  }

  return <PageLayout>{children}</PageLayout>;
};

export default App;
