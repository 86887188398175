/**
 * @fileoverview 目標カードコンポーネント
 * 
 * このファイルは、個々の目標を表示するためのカードコンポーネントを定義します。
 * 目標の詳細情報、進捗状況、子目標、および関連するアクションボタンを表示します。
 * 
 * @requires React
 * @requires @mui/material
 * @requires @mui/icons-material
 * @requires ../../utils/commonUtils
 * @requires ../common/GoalProgress
 * @requires ../common/MarkdownRenderer
 */

import React from 'react';
import { Card, CardContent, Typography, Box, Button, List, ListItem, ListItemText, Collapse, IconButton, Tooltip } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import EditIcon from '@mui/icons-material/Edit';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import UndoIcon from '@mui/icons-material/Undo';
import DeleteIcon from '@mui/icons-material/Delete';
import { ReferenceLinks, formatDate, formatNumber } from '../../utils/commonUtils';
import GoalProgress from '../common/GoalProgress';
import MarkdownRenderer from '../common/MarkdownRenderer';
import { PlayArrow as PlayArrowIcon, Pause as PauseIcon, OpenInNew as OpenInNewIcon } from '@mui/icons-material';
import { useNavigate, useLocation } from 'react-router-dom';

/**
 * 目標カードコンポーネント
 * 
 * @param {Object} props - コンポーネントのプロパティ
 * @param {Object} props.goal - 表示する目標オブジェクト
 * @param {boolean} props.isCompleted - 目標が完了しているかどうか
 * @param {Function} props.onEditClick - 編集ボタンクリック時のコールバック
 * @param {Function} props.onRemoveClick - 削除ボタンクリック時のコールバック
 * @param {Function} props.onCompleteClick - 完了ボタンクリック時のコールバック
 * @param {Function} props.onRevertClick - 元に戻すボタンクリック時のコールバック
 * @param {boolean} props.hideDetailLink - 詳細リンクを非表示にするかどうか
 * @param {boolean} props.showFullDetails - フル詳細を表示するかどうか
 * @param {Function} props.onStatusChange - ステータス変更用コールバック
 * @param {boolean} props.isKanban - カンバン表示かどうか
 * @param {Function} props.onGoalClick - 目標詳細ページへのコールバック
 * @param {boolean} props.hideOpenIcon - 詳細を開くアイコンを非表示にするかどうか
 * @returns {React.Component} 目標カードコンポーネント
 */
const GoalCard = ({ goal, isCompleted, onEditClick, onRemoveClick, onCompleteClick, onRevertClick, hideDetailLink, showFullDetails, onStatusChange, isKanban, onGoalClick, hideOpenIcon = false }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [showChildGoals, setShowChildGoals] = React.useState(false);
  const targetValue = parseFloat(goal.target_value) || 0;
  const totalActualValue = goal.totalActualValue || 0;

  const toggleChildGoals = () => {
    setShowChildGoals(!showChildGoals);
  };

  // ステータスに基づいてボタンの表示を制御
  const renderStatusToggle = () => {
    if (isCompleted) return null; // 完了時は表示しない

    return (
      <Tooltip title={goal.status === 'not_started' ? '進行中にする' : '未着手に戻す'}>
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            onStatusChange(goal.status === 'not_started' ? 'in_progress' : 'not_started');
          }}
          size="small"
          sx={{ 
            mr: 1,
            bgcolor: goal.status === 'in_progress' ? 'action.selected' : 'transparent',
          }}
        >
          {goal.status === 'not_started' ? <PlayArrowIcon /> : <PauseIcon />}
        </IconButton>
      </Tooltip>
    );
  };

  // onNavigateを内部で定義
  const handleNavigate = (goalId) => {
    // 現在のパスを維持したまま、goalパラメータを追加
    navigate(`${location.pathname}?goal=${goalId}`);
  };

  return (
    <Card 
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        '& .MuiCardContent-root': {
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          p: isKanban ? 1.5 : 2,
        },
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: 'action.hover',
        },
        position: 'relative',
      }}
      onClick={() => onGoalClick && onGoalClick(goal.id)}
    >
      {!hideOpenIcon && (
        <IconButton
          size="small"
          onClick={(e) => {
            e.stopPropagation();
            onGoalClick && onGoalClick(goal.id);
          }}
          sx={{
            position: 'absolute',
            top: 8,
            right: 8,
            zIndex: 1,
            padding: 0.5,
            backgroundColor: 'background.paper',
            '&:hover': {
              backgroundColor: 'action.hover',
            },
          }}
        >
          <OpenInNewIcon fontSize="small" />
        </IconButton>
      )}

      <CardContent>
        {/* 目標の基本情報 */}
        <Box sx={{ flex: 1 }}>
          {/* プロジェクト名 - カンバン表示時のみ */}
          {isKanban && goal.project && (
            <Typography 
              variant="caption" 
              sx={{ 
                display: 'inline-block',
                backgroundColor: 'primary.main',
                color: 'white',
                px: 1,
                py: 0.5,
                borderRadius: 1,
                mb: 1,
                fontSize: '0.75rem',
                lineHeight: 1,
              }}
            >
              {goal.project.name}
            </Typography>
          )}

          <Typography 
            variant={isKanban ? "subtitle1" : "h6"} 
            component="div" 
            gutterBottom
            sx={{ 
              fontWeight: 'bold',
              fontSize: isKanban ? '1rem' : undefined,
              mt: isKanban && goal.project ? 0 : undefined
            }}
          >
            {goal.title}
          </Typography>
          {(!isKanban || goal.description) && (
            <Box sx={{ mt: 1, mb: isKanban ? 1 : 2 }}>
              <MarkdownRenderer>
                {isKanban ? goal.description?.split('\n')[0] : goal.description}
              </MarkdownRenderer>
            </Box>
          )}

          {/* 目標の詳細情報 */}
          <Box sx={{ mt: isKanban ? 1 : 2 }}>
            {targetValue > 0 && goal.unit_name && (
              <Box sx={{ mb: isKanban ? 1 : 2 }}>
                {!isKanban && (
                  <Typography variant="body2" gutterBottom>
                    目標数値: {formatNumber(targetValue)} {goal.unit_name}
                  </Typography>
                )}
                {showFullDetails && !isKanban && (
                  <Typography variant="body2" gutterBottom>
                    現在の実績: {formatNumber(totalActualValue)} {goal.unit_name}
                  </Typography>
                )}
                <GoalProgress goal={goal} height={isKanban ? 2 : 4} />
              </Box>
            )}

            {showFullDetails && !isKanban && (
              <Box sx={{ mb: 2 }}>
                <Typography variant="body2" gutterBottom>
                  {isCompleted ? '完了日' : '期限'}: {formatDate(isCompleted ? goal.complete_date : goal.deadline)}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  振り返り数: {goal.reflectionCount}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  実施時間: {Math.floor(goal.totalDuration / 60)} 時間 {goal.totalDuration % 60} 分
                </Typography>
              </Box>
            )}

            {!isKanban && <ReferenceLinks links={goal.reference_links} />}

            {goal.parent_goal && !isKanban && (
              <Box sx={{ mt: 2 }}>
                <Typography variant="body2">
                  親目標: 
                  <Button
                    onClick={() => handleNavigate(goal.parent_goal.id)}
                    sx={{ ml: 1, cursor: 'pointer', textTransform: 'none' }}
                  >
                    {goal.parent_goal.title}
                  </Button>
                </Typography>
              </Box>
            )}
          </Box>
        </Box>

        {/* アクションボタン */}
        <Box sx={{ mt: 'auto', pt: isKanban ? 1 : 2 }}>
          {!hideDetailLink && !isKanban && (
            <Box sx={{ mb: 2 }}>
              <Button
                fullWidth
                onClick={(e) => {
                  e.stopPropagation();
                  onGoalClick && onGoalClick(goal.id);
                }}
                startIcon={<OpenInNewIcon />}
                sx={{ color: '#007bff', textTransform: 'none' }}
              >
                目標詳細を表示
              </Button>
            </Box>
          )}

          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'flex-end', 
            gap: 0.5,
            '& .MuiButton-root': {
              minWidth: isKanban ? '64px' : '64px',
              px: isKanban ? 1 : 2
            }
          }}>
            {renderStatusToggle()}
            {!isKanban && (
              <Button 
                size="small" 
                color="error" 
                variant="outlined" 
                onClick={(e) => {
                  e.stopPropagation();
                  onRemoveClick(goal);
                }}
                startIcon={<DeleteIcon />}
              >
                削除
              </Button>
            )}
            <Button 
              size="small" 
              color="primary" 
              variant="outlined" 
              onClick={(e) => {
                e.stopPropagation();
                onEditClick(goal);
              }}
              startIcon={<EditIcon />}
            >
              {isKanban ? '編集' : '編集'}
            </Button>
            {isCompleted ? (
              <Button 
                size="small" 
                color="primary" 
                variant="outlined" 
                onClick={(e) => {
                  e.stopPropagation();
                  onRevertClick(goal);
                }}
                startIcon={<UndoIcon />}
              >
                {isKanban ? '戻す' : '元に戻す'}
              </Button>
            ) : (
              <Button 
                size="small" 
                color="success" 
                variant="contained" 
                onClick={(e) => {
                  e.stopPropagation();
                  onCompleteClick(goal);
                }}
                startIcon={<CheckCircleIcon />}
              >
                {isKanban ? '完了' : '完了'}
              </Button>
            )}
          </Box>
        </Box>

        {/* 子目標の表示 - カンバン表示時は非表示 */}
        {!isKanban && goal.child_goals && goal.child_goals.length > 0 && (
          <Box sx={{ mt: 2 }}>
            <Button
              onClick={toggleChildGoals}
              startIcon={showChildGoals ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              fullWidth
            >
              子目標 ({goal.child_goals.length})
            </Button>
            <Collapse in={showChildGoals}>
              <List dense>
                {goal.child_goals.map((childGoal) => (
                  <ListItem key={childGoal.id}>
                    <ListItemText
                      primary={childGoal.title}
                    />
                    <Button
                      onClick={() => handleNavigate(childGoal.id)}
                      size="small"
                    >
                      詳細
                    </Button>
                  </ListItem>
                ))}
              </List>
            </Collapse>
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

export default GoalCard;
