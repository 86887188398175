/**
 * @fileoverview 目標詳細ページコンポーネント
 * @description 目標の詳細情報、タスク、振り返り、進捗などを表示・管理するページ
 */

import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { 
  Box, 
  Typography, 
  Paper, 
  Grid, 
  Button, 
  ToggleButton, 
  ToggleButtonGroup, 
  Tooltip,
  Stack,
  ButtonBase,
  Zoom
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import { useGoalData } from '../hooks/useGoalData';
import { useReflectionData } from '../hooks/useReflectionData';
import { useActivityData } from '../hooks/useActivityData';
import { useTaskData } from '../hooks/useTaskData';
import { reflectionService } from '../services/reflectionService';
import GoalCard from '../components/goals/GoalCard';
import GoalDialog from '../components/goals/GoalDialog';
import RemoveDialog from '../components/goals/GoalRemoveDialog';
import CompleteDialog from '../components/goals/GoalCompleteDialog';
import RevertDialog from '../components/goals/GoalRevertDialog';
import ReflectionDialog from '../components/reflections/ReflectionDialog';
import ActivityHistory from '../components/activities/ActivityHistory';
import ReflectionSummaryCard from '../components/summary/ReflectionSummaryCard';
import AdComponent from '../components/common/AdComponent';
import AchievementProgressChart from '../components/charts/AchievementProgressChart';
import ReflectionRemoveDialog from '../components/reflections/ReflectionRemoveDialog';
import ReflectionPage from './ReflectionPage';
import RecentReflections from '../components/reflections/RecentReflections';
import TaskList from '../components/tasks/TaskList';
import ReflectionSummaryGenerator from '../components/summary/ReflectionSummaryGenerator';
import GoalSupportBubble from '../components/goals/GoalSupportBubble';
import { openaiService } from '../services/openaiService';
import GoalDetailChatBox from '../components/goals/GoalDetailChatBox';
import GoalSummaryStats from '../components/goals/GoalSummaryStats';
import ViewListIcon from '@mui/icons-material/ViewList';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import SlidingPage from '../components/common/SlidingPage';
import ReflectionForm from '../components/reflections/ReflectionForm';
import ReflectionTable from '../components/reflections/ReflectionTable';

/**
 * 目標詳細ページコンポーネント
 * @param {Object} props - コンポーネントのプロパティ
 * @param {Function} props.onClose - 閉じるボタンクリック時のコールバック
 * @param {Function} props.onUpdate - データ更新時のコールバック
 * @param {string} props.goalId - 表示する目標のID
 */
const GoalDetailPage = ({ onClose, onUpdate, goalId }) => {
  const navigate = useNavigate();
  const [selectedGoalForReflection, setSelectedGoalForReflection] = useState(null);
  const [defaultAchievement, setDefaultAchievement] = useState('');
  const [refreshTasksCounter, setRefreshTasksCounter] = useState(0);
  const { 
    goals, 
    loading: goalsLoading, 
    error: goalsError, 
    fetchGoals,
    handleEditGoal,
    handleDeleteGoal,
    handleStatusChange
  } = useGoalData(null, goalId);

  const goal = goals[0];

  const { goals: allGoals } = useGoalData();

  const {
    reflections,
    loading: reflectionsLoading,
    error: reflectionsError,
    handleEditReflection,
    handleDeleteReflection,
    fetchReflections
  } = useReflectionData(goalId);

  const {
    activities,
    loading: activitiesLoading,
    error: activitiesError,
    fetchActivities
  } = useActivityData(goalId);

  const {
    handleAddTask,
  } = useTaskData(goalId);

  const [showEditModal, setShowEditModal] = useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [showCompleteModal, setShowCompleteModal] = useState(false);
  const [showRevertModal, setShowRevertModal] = useState(false);
  const [showReflectionModal, setShowReflectionModal] = useState(false);
  const [showReflectionEditModal, setShowReflectionEditModal] = useState(false);
  const [showReflectionRemoveModal, setShowReflectionRemoveModal] = useState(false);
  const [selectedReflection, setSelectedReflection] = useState(null);
  const [selectedReflections, setSelectedReflections] = useState([]);
  const [isReflectionPageOpen, setIsReflectionPageOpen] = useState(false);
  const [selectedReflectionId, setSelectedReflectionId] = useState(null);
  const [refreshReflections, setRefreshReflections] = useState(false);
  const [supportMessage, setSupportMessage] = useState('');
  const [loadingMessage, setLoadingMessage] = useState(true);
  const [prevGoal, setPrevGoal] = useState(null);
  const [prevReflections, setPrevReflections] = useState([]);
  const [viewMode, setViewMode] = useState('card'); // 'card' or 'list'
  const [editingReflection, setEditingReflection] = useState(null);
  const [isReflectionFormOpen, setIsReflectionFormOpen] = useState(false);
  const [reflectionViewMode, setReflectionViewMode] = useState('card');

  // 更新が必要かどうかを判断するヘルパー関数
  const shouldUpdateMessage = (prevGoal, newGoal, prevReflections, newReflections) => {
    if (!prevGoal || !newGoal) return true;
    
    return (
      prevGoal.status !== newGoal.status ||
      prevGoal.is_completed !== newGoal.is_completed ||
      prevGoal.actual_value !== newGoal.actual_value ||
      prevReflections.length !== newReflections.length ||
      // 最新の振り返りの内容が変更された場合
      (newReflections[0] && prevReflections[0] && 
       (newReflections[0].id !== prevReflections[0].id ||
        newReflections[0].updated_at !== prevReflections[0].updated_at))
    );
  };

  // AIサポートメッセージを生成する関数
  const generateSupportMessage = async () => {
    if (!goal) return;

    // 更新が必要かどうかを判断
    if (!shouldUpdateMessage(prevGoal, goal, prevReflections, reflections)) {
      return;
    }

    try {
      const message = await openaiService.generateGoalSupportMessage({
        goal: {
          id: goal.id,
          title: goal.title,
          description: goal.description,
          deadline: goal.deadline,
          is_completed: goal.is_completed,
          target_value: goal.target_value,
          actual_value: goal.actual_value,
          status: goal.status
        },
        reflections
      });
      setSupportMessage(message);

      // 現在の状態を保存
      setPrevGoal(goal);
      setPrevReflections(reflections);
    } catch (error) {
      console.error('サポートメッセージの生成に失敗しました:', error);
      setSupportMessage('がんばっていますね！一緒に目標達成を目指しましょう！');
    } finally {
      setLoadingMessage(false);
    }
  };

  // 目標データが取得されたらサポートメッセージを生成
  useEffect(() => {
    if (goal && reflections) {
      generateSupportMessage();
    }
  }, [
    goal?.status,
    goal?.is_completed,
    goal?.actual_value,
    reflections.length,
    // 最新の振り返りの更新日時
    reflections[0]?.updated_at
  ]);

  const handleEditClick = () => setShowEditModal(true);
  const handleRemoveClick = () => setShowRemoveModal(true);
  const handleCompleteClick = () => setShowCompleteModal(true);
  const handleRevertClick = () => setShowRevertModal(true);
  const handleReflectionClick = (goal, reflectionId = null) => {
    setSelectedGoalForReflection(goal);
    setSelectedReflectionId(reflectionId);
    setIsReflectionPageOpen(true);
  };
  const handleReflectionEditClick = (reflection) => {
    setEditingReflection(reflection);
    setIsReflectionFormOpen(true);
  };
  const handleDeleteReflectionsClick = (reflectionIds) => {
    setSelectedReflections(reflectionIds);
    setShowReflectionRemoveModal(true);
  };
  const handleDeleteReflections = async () => {
    for (const reflectionId of selectedReflections) {
      await handleDeleteReflection({ id: reflectionId });
    }
    setShowReflectionRemoveModal(false);
    handleActivityChange();
  };

  const handleDeleteGoalAndNavigate = async () => {
    await handleDeleteGoal(goal);
    navigate('/');
  };

  const handleUpdateData = async () => {
    await Promise.all([
      fetchGoals(),
      fetchActivities(),
      fetchReflections()
    ]);
    setShowEditModal(false);
    setShowReflectionModal(false);
    setShowReflectionEditModal(false);
    if (onUpdate) {
      await onUpdate();
    }
  };

  const handleActivityChange = () => {
    fetchGoals();
    fetchActivities();
  };

  const handleCloseReflectionPage = () => {
    setIsReflectionPageOpen(false);
    setSelectedReflectionId(null);
    handleActivityChange();
  };

  const handleReflectionUpdate = () => {
    fetchReflections();
    handleActivityChange();
    setRefreshReflections(prev => !prev);
  };

  const handleTaskComplete = (taskContent) => {
    setDefaultAchievement(taskContent);
    setShowReflectionModal(true);
  };

  const handleTasksGenerated = async (tasks) => {
    // 生成された各タスクを追加
    for (const task of tasks) {
      await handleAddTask(task.content);
    }
    // タスクリストの更新をトリガー
    setRefreshTasksCounter(prev => prev + 1);
  };

  const handleViewModeChange = (event, newMode) => {
    if (newMode !== null) {
      setViewMode(newMode);
    }
  };

  const handleSaveReflection = async (reflectionData) => {
    try {
      if (editingReflection) {
        // 編集の場合
        await handleEditReflection(reflectionData);
      } else {
        // 新規作成の場合
        await reflectionService.createReflection(reflectionData);
      }
      setIsReflectionFormOpen(false);
      setEditingReflection(null);
      // 振り返りデータを再取得
      fetchReflections();
      handleActivityChange();
    } catch (error) {
      console.error('振り返りの保存に失敗しました:', error);
      alert('振り返りの保存中にエラーが発生しました。');
    }
  };

  // 振り返り削除ハンドラー
  const handleDeleteReflectionClick = async (reflection) => {
    try {
      await handleDeleteReflection({ id: reflection.id });
      fetchReflections();
    } catch (error) {
      console.error('振り返りの削除に失敗しました:', error);
      alert('振り返りの削除中にエラーが発生しました。');
    }
  };

  // 振り返り表示モード切り替えハンドラー
  const handleReflectionViewModeChange = (event, newMode) => {
    if (newMode !== null) {
      setReflectionViewMode(newMode);
    }
  };

  // 新規実績記録ハンドラー
  const handleCreateReflection = () => {
    setEditingReflection(null);
    setIsReflectionFormOpen(true);
  };

  const handleGoalStatusChange = async (newStatus) => {
    try {
      await handleStatusChange(goal, newStatus);
      await handleUpdateData();
    } catch (error) {
      console.error('ステータスの更新に失敗しました:', error);
    }
  };

  if (goalsLoading || reflectionsLoading) return <Typography>読み込み中...</Typography>;
  if (goalsError) return <Typography color="error">エラー: {goalsError}</Typography>;
  if (reflectionsError) return <Typography color="error">エラー: {reflectionsError}</Typography>;
  if (!goals[0]) return <Typography>目標が見つかりません。</Typography>;

  const availableParentGoals = allGoals.filter(g => g.id !== goal.id);

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ mb: 2 }}>
        <Typography
          variant="body1"
          color="primary"
          sx={{ cursor: 'pointer' }}
          onClick={onClose}
        >
          ← ダッシュボードに戻る
        </Typography>
      </Box>

      {/* タイトルと実績記録ボタン */}
      <Stack direction="row" spacing={3} alignItems="center" sx={{ mb: 3 }}>
        <Typography variant="h4">{goal?.title || '目標詳細'}</Typography>
        <Zoom in={true} style={{ transitionDelay: '100ms' }}>
          <ButtonBase
            onClick={handleCreateReflection}
            sx={{
              borderRadius: '24px',
              overflow: 'hidden',
              position: 'relative',
              '&:hover': {
                transform: 'scale(1.02)',
                transition: 'transform 0.2s ease-in-out',
              },
            }}
          >
            <Box
              sx={{
                background: 'linear-gradient(45deg, #9C27B0 30%, #E91E63 90%)',
                color: 'white',
                py: 1,
                px: 2.5,
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                boxShadow: '0 3px 5px 2px rgba(233, 30, 99, .3)',
                '&:hover': {
                  background: 'linear-gradient(45deg, #7B1FA2 30%, #C2185B 90%)',
                },
              }}
            >
              <AddIcon />
              <EmojiEventsIcon />
              <Typography variant="button">
                新しい実績
              </Typography>
            </Box>
          </ButtonBase>
        </Zoom>
      </Stack>

      {/* 目標カード */}
      <Box sx={{ mb: 3 }}>
        <GoalCard
          goal={goal}
          isCompleted={goal.is_completed}
          onReflectionClick={() => handleReflectionClick(goal)}
          onEditClick={handleEditClick}
          onRemoveClick={handleRemoveClick}
          onCompleteClick={handleCompleteClick}
          onRevertClick={handleRevertClick}
          onStatusChange={handleGoalStatusChange}
          hideDetailLink={true} 
          showFullDetails={true}
          hideOpenIcon={true}
          childGoals={goals.filter(g => g.parent_goal_id === goal.id)}
        />
      </Box>

      {/* 統計情報とタスクリスト */}
      <Grid container spacing={3} sx={{ mb: 3 }}>
        {/* 統計情報 */}
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: 3, height: '100%' }}>
            <GoalSummaryStats goal={goal} reflections={reflections} />
          </Paper>
        </Grid>

        {/* タスクリスト */}
        <Grid item xs={12} md={6}>
          {goal && goal.id && (
            <Paper elevation={3} sx={{ p: 3, height: '100%' }}>
              <TaskList 
                goalId={goal.id} 
                refreshTasks={refreshTasksCounter}
              />
            </Paper>
          )}
        </Grid>
      </Grid>

      {/* 振り返りセクション */}
      <Paper sx={{ p: 2, mt: 3, mb: 3 }}>
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center', 
          mb: 2 
        }}>
          <Typography variant="h6">振り返り一覧</Typography>
          <ToggleButtonGroup
            value={reflectionViewMode}
            exclusive
            onChange={handleReflectionViewModeChange}
            aria-label="表示モード"
            size="small"
          >
            <Tooltip title="カード表示">
              <ToggleButton value="card" aria-label="カード表示">
                <ViewModuleIcon />
              </ToggleButton>
            </Tooltip>
            <Tooltip title="リスト表示">
              <ToggleButton value="list" aria-label="リスト表示">
                <ViewListIcon />
              </ToggleButton>
            </Tooltip>
          </ToggleButtonGroup>
        </Box>

        {reflectionsLoading ? (
          <Typography>振り返りデータを読み込み中...</Typography>
        ) : reflectionsError ? (
          <Typography color="error">エラー: {reflectionsError}</Typography>
        ) : reflectionViewMode === 'list' ? (
          <ReflectionTable
            reflections={reflections}
            selectedReflections={selectedReflections}
            onSelectionChange={setSelectedReflections}
            onView={handleReflectionEditClick}
            onDelete={handleDeleteReflectionClick}
            initialDisplayCount={10}
          />
        ) : (
          <RecentReflections
            defaultGoalFilter={goalId}
            refreshTrigger={reflectionViewMode}
            onEdit={(reflection) => {
              // 振り返りデータをそのまま使用
              setEditingReflection(reflection);
              setIsReflectionFormOpen(true);
            }}
          />
        )}
      </Paper>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <ReflectionSummaryGenerator 
            goalId={goal.id} 
            reflections={reflections} 
          />
        </Grid>
      </Grid>

      {/* 進捗チャート */}
      <Box sx={{ mb: 3 }}>
        <Paper elevation={3} sx={{ p: 3 }}>
          {goal && goal.id && (
            <AchievementProgressChart goalID={goal.id} />
          )}
        </Paper>
      </Box>

      <Box sx={{ my: 4 }}>
        <AdComponent />
      </Box>

      {showEditModal && (
        <GoalDialog
          goal={goal}
          onClose={() => setShowEditModal(false)}
          onSave={async (updatedGoal) => {
            try {
              await handleEditGoal(updatedGoal);
              await handleUpdateData();
            } catch (error) {
              console.error('目標の更新に失敗しました:', error);
            }
          }}
          availableParentGoals={availableParentGoals}
        />
      )}
      {showRemoveModal && (
        <RemoveDialog
          goal={goal}
          onClose={() => setShowRemoveModal(false)}
          onRemove={handleDeleteGoalAndNavigate}
        />
      )}
      {showCompleteModal && (
        <CompleteDialog
          goal={goal}
          onClose={() => setShowCompleteModal(false)}
          onUpdate={handleUpdateData}
        />
      )}
      {showRevertModal && (
        <RevertDialog
          goal={goal}
          onClose={() => setShowRevertModal(false)}
          onUpdate={handleUpdateData}
        />
      )}
      {showReflectionModal && (
        <ReflectionDialog
          goal={goal}
          onClose={() => {
            setShowReflectionModal(false);
            setDefaultAchievement('');
          }}
          onSave={handleReflectionUpdate}
          open={showReflectionModal}
          onActivityChange={handleActivityChange}
          initialAchievement={defaultAchievement}
        />
      )}
      {showReflectionEditModal && (
        <ReflectionDialog
          goal={goal}
          reflection={selectedReflection}
          onClose={() => {
            setShowReflectionEditModal(false);
            setSelectedReflection(null);
          }}
          onSave={(updatedReflection) => {
            handleReflectionEditClick(updatedReflection);
            handleReflectionUpdate();
          }}
          open={showReflectionEditModal}
          onActivityChange={handleActivityChange}
        />
      )}
      {showReflectionRemoveModal && (
        <ReflectionRemoveDialog
          reflections={selectedReflections}
          onClose={() => setShowReflectionRemoveModal(false)}
          onRemove={handleDeleteReflections}
          onActivityChange={handleActivityChange}
        />
      )}
      <ReflectionPage
        isOpen={isReflectionPageOpen}
        onClose={handleCloseReflectionPage}
        goalId={selectedGoalForReflection?.id}
        reflectionId={selectedReflectionId}
      />
      <SlidingPage isOpen={isReflectionFormOpen} onClose={() => {
        setIsReflectionFormOpen(false);
        setEditingReflection(null);
      }}>
        {(editingReflection || isReflectionFormOpen) && (
          <ReflectionForm
            goal={editingReflection ? editingReflection.goals : goal}
            reflection={editingReflection}
            onClose={() => {
              setIsReflectionFormOpen(false);
              setEditingReflection(null);
            }}
            onSave={handleSaveReflection}
            isDialog={true}
            initialProjectId={editingReflection?.project_id || goal?.project_id}
          />
        )}
      </SlidingPage>
    </Box>
  );
};

export default GoalDetailPage;
