/**
 * @fileoverview 日々の活動を表示するチャートコンポーネント
 * @module DailyActivityChart
 */

import React, { useState, useMemo } from 'react';
import { 
  Box, 
  Typography, 
  Paper, 
  Tooltip, 
  ToggleButtonGroup, 
  ToggleButton 
} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { format, parseISO, isWithinInterval, eachDayOfInterval, startOfDay, endOfDay, subDays } from 'date-fns';
import { addMinutes } from 'date-fns';
import { ja } from 'date-fns/locale';
import FolderIcon from '@mui/icons-material/Folder';
import FlagIcon from '@mui/icons-material/Flag';
import DateRangePicker from './common/DateRangePicker';
import ReflectionDialog from '../reflections/ReflectionDialog';
import ReflectionRemoveDialog from '../reflections/ReflectionRemoveDialog';
import { useReflectionData } from '../../hooks/useReflectionData';

/**
 * 日々の活動を表示するチャートコンポーネント
 * @param {Object} props - コンポーネントのプロパティ
 * @param {Array} props.activities - 活動データの配列
 * @param {Function} props.onActivityChange - 活動変更時のコールバック関数
 * @param {string} props.selectedProject - 選択されたプロジェクトのID
 * @param {string} [props.startDate] - 開始日（YYYY-MM-DD形式）
 * @param {string} [props.endDate] - 終了日（YYYY-MM-DD形式）
 * @returns {JSX.Element} DailyActivityChartコンポーネント
 */
const DailyActivityChart = ({ 
  activities, 
  onActivityChange, 
  selectedProject,
  startDate: externalStartDate,
  endDate: externalEndDate 
}) => {
  // 外部から日付が指定されている場合はそれを使用し、そうでない場合は内部の状態を使用
  const [internalEndDate, setInternalEndDate] = useState(new Date());
  const [internalStartDate, setInternalStartDate] = useState(subDays(internalEndDate, 4));
  
  // 実際に使用する日付（外部指定があればそれを優先）
  const startDate = externalStartDate ? parseISO(externalStartDate) : internalStartDate;
  const endDate = externalEndDate ? parseISO(externalEndDate) : internalEndDate;

  const [selectedReflection, setSelectedReflection] = useState(null);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isRemoveDialogOpen, setIsRemoveDialogOpen] = useState(false);
  const [groupBy, setGroupBy] = useState('project'); // デフォルトを'project'に変更

  const { handleEditReflection, handleDeleteReflection } = useReflectionData();

  /**
   * 日付の変更を処理する（外部から日付が指定されていない場合のみ）
   * @param {Event} e - 日付変更イベント
   * @param {boolean} isStart - 開始日かどうか
   */
  const handleDateChange = (e, isStart) => {
    if (externalStartDate || externalEndDate) return; // 外部指定がある場合は変更不可

    const date = new Date(e.target.value);
    if (isStart) {
      setInternalStartDate(date);
    } else {
      setInternalEndDate(date);
    }
  };

  const handleGroupByChange = (_, newValue) => {
    if (newValue !== null) {
      setGroupBy(newValue);
    }
  };

  const filteredActivities = useMemo(() => {
    return activities.filter(activity => {
      // プロジェクトフィルター
      if (selectedProject && activity.type === 'reflection') {
        const activityProjectId = activity.project_id || activity.goals?.project_id;
        if (activityProjectId !== selectedProject) {
          return false;
        }
      }

      // 日付フィルター
      if (activity.type === 'reflection') {
        const activityStartTime = activity.start_time
          ? parseISO(activity.start_time)
          : parseISO(activity.created_at);
        const activityEndTime = activity.end_time
          ? parseISO(activity.end_time)
          : addMinutes(parseISO(activity.created_at), 15);

        return (
          (activityStartTime <= endOfDay(endDate) && activityEndTime >= startOfDay(startDate)) ||
          (activityStartTime >= startOfDay(startDate) && activityStartTime <= endOfDay(endDate)) ||
          (activityEndTime >= startOfDay(startDate) && activityEndTime <= endOfDay(endDate))
        );
      }
      return false;
    });
  }, [activities, startDate, endDate, selectedProject]);

  const activityColors = useMemo(() => {
    const uniqueItems = [...new Set(filteredActivities.map(a => {
      if (groupBy === 'project') {
        return a.projects?.name || a.goals?.projects?.name || '未分類';
      }
      return a.goals?.title || '目標なし';
    }))];

    return uniqueItems.reduce((acc, item, index) => {
      acc[item] = `hsl(${index * 360 / uniqueItems.length}, 70%, 50%)`;
      return acc;
    }, {});
  }, [filteredActivities, groupBy]);

  const dateRange = useMemo(() => {
    return eachDayOfInterval({ start: startDate, end: endDate });
  }, [startDate, endDate]);

  /**
   * 活動をクリックしたときの処理
   * @param {Object} activity - クリックされた活動オブジェクト
   */
  const handleActivityClick = (activity) => {
    setSelectedReflection(activity);
    setIsEditDialogOpen(true);
  };

  const handleEditDialogClose = () => {
    setIsEditDialogOpen(false);
    setSelectedReflection(null);
  };

  const handleRemoveDialogOpen = () => {
    setIsEditDialogOpen(false);
    setIsRemoveDialogOpen(true);
  };

  const handleRemoveDialogClose = () => {
    setIsRemoveDialogOpen(false);
    setSelectedReflection(null);
  };

  const handleReflectionSave = async (updatedReflection) => {
    try {
      await handleEditReflection(updatedReflection);
      onActivityChange(); // 親コンポーネントに変更を通知
      handleEditDialogClose();
    } catch (error) {
      console.error('振り返りの更新に失敗しました:', error);
    }
  };

  const handleReflectionRemove = async (reflection) => {
    try {
      await handleDeleteReflection(reflection);
      onActivityChange(); // 親コンポーネントに変更を通知
      handleRemoveDialogClose();
    } catch (error) {
      console.error('振り返りの削除に失敗しました:', error);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ja}>
      <Paper elevation={3} sx={{ p: 3, mt: 4, overflowX: 'auto' }}>
        <Box sx={{ minWidth: '800px' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
            <Typography variant="h6">
              期間の活動記録
            </Typography>
            <ToggleButtonGroup
              value={groupBy}
              exclusive
              onChange={handleGroupByChange}
              size="small"
            >
              <Tooltip title="プロジェクト別">
                <ToggleButton value="project">
                  <FolderIcon />
                </ToggleButton>
              </Tooltip>
              <Tooltip title="目標別">
                <ToggleButton value="goal">
                  <FlagIcon />
                </ToggleButton>
              </Tooltip>
            </ToggleButtonGroup>
          </Box>
          {/* 外部から日付が指定されていない場合のみ日付選択を表示 */}
          {!externalStartDate && !externalEndDate && (
            <DateRangePicker
              timeRange="daily"
              startDate={format(startDate, 'yyyy-MM-dd')}
              endDate={format(endDate, 'yyyy-MM-dd')}
              onDateChange={handleDateChange}
            />
          )}
          <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', gap: 1 }}>
            {dateRange.map(date => (
              <DayRow
                key={date.toISOString()}
                date={date}
                activities={filteredActivities}
                activityColors={activityColors}
                onActivityClick={handleActivityClick}
                groupBy={groupBy}
              />
            ))}
          </Box>
          <Box sx={{ mt: 2 }}>
            <Typography variant="subtitle2" gutterBottom>
              {groupBy === 'project' ? 'プロジェクト:' : '目標:'}
            </Typography>
            {Object.entries(activityColors).map(([name, color]) => (
              <Box key={name} sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                <Box sx={{ width: 20, height: 20, backgroundColor: color, mr: 1 }} />
                <Typography variant="body2">{name}</Typography>
              </Box>
            ))}
          </Box>
        </Box>
      </Paper>
      {isEditDialogOpen && (
        <ReflectionDialog
          reflection={selectedReflection}
          onClose={handleEditDialogClose}
          onSave={handleReflectionSave}
          open={isEditDialogOpen}
          goal={selectedReflection.goals}
        />
      )}
      {isRemoveDialogOpen && (
        <ReflectionRemoveDialog
          reflection={selectedReflection}
          onClose={handleRemoveDialogClose}
          onRemove={handleReflectionRemove}
        />
      )}
    </LocalizationProvider>
  );
};

/**
 * 1日分の活動行を表示するコンポーネント
 * @param {Object} props - コンポーネントのプロパティ
 * @param {Date} props.date - 表示する日付
 * @param {Array} props.activities - 活動データの配列
 * @param {Object} props.activityColors - 活動ごとの色情報
 * @param {Function} props.onActivityClick - 活動クリック時のコールバック関数
 * @param {string} props.groupBy - グループ化の基準 ('goal' or 'project')
 * @returns {JSX.Element} DayRowコンポーネント
 */
const DayRow = ({ date, activities, activityColors, onActivityClick, groupBy }) => {
  const dayActivities = activities.filter(activity => {
    const activityStartTime = activity.start_time
      ? parseISO(activity.start_time)
      : parseISO(activity.created_at);
    const activityEndTime = activity.end_time
      ? parseISO(activity.end_time)
      : addMinutes(parseISO(activity.created_at), 15);

    return (
      (activityStartTime <= endOfDay(date) && activityEndTime >= startOfDay(date)) ||
      (activityStartTime >= startOfDay(date) && activityStartTime <= endOfDay(date)) ||
      (activityEndTime >= startOfDay(date) && activityEndTime <= endOfDay(date))
    );
  });

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', height: 60, mb: 1 }}>
      <Typography variant="body2" sx={{ width: 100, flexShrink: 0 }}>
        {format(date, 'MM/dd (E)', { locale: ja })}
      </Typography>
      <Box sx={{ flex: 1, height: '100%', position: 'relative', backgroundColor: '#f0f0f0' }}>
        {[...Array(24)].map((_, i) => (
          <Box
            key={i}
            sx={{
              position: 'absolute',
              left: `${(i / 24) * 100}%`,
              top: 0,
              height: '100%',
              borderLeft: '1px solid #ddd',
              width: `${100 / 24}%`,
            }}
          >
            <Typography variant="caption" sx={{ position: 'absolute', top: -20, left: 2 }}>
              {`${i}:00`}
            </Typography>
          </Box>
        ))}
        {dayActivities.map(activity => (
          <ActivityBar
            key={activity.id}
            activity={activity}
            date={date}
            color={activityColors[
              groupBy === 'project' 
                ? (activity.projects?.name || activity.goals?.projects?.name || '未分類')
                : (activity.goals?.title || '目標なし')
            ]}
            onClick={onActivityClick}
          />
        ))}
      </Box>
    </Box>
  );
};

/**
 * 活動バーを表示するコンポーネント
 * @param {Object} props - コンポーネントのプロパティ
 * @param {Object} props.activity - 活動データ
 * @param {Date} props.date - 表示する日付
 * @param {string} props.color - バーの色
 * @param {Function} props.onClick - クリック時のコールバック関数
 * @returns {JSX.Element} ActivityBarコンポーネント
 */
const ActivityBar = ({ activity, date, color, onClick }) => {
  const startTime = activity.start_time
    ? parseISO(activity.start_time)
    : parseISO(activity.created_at);
  const endTime = activity.end_time
    ? parseISO(activity.end_time)
    : addMinutes(parseISO(activity.created_at), 15);

  const dayStart = startOfDay(date);
  const dayEnd = endOfDay(date);

  // 日付をまたぐ場合の開始時刻と終了時刻の調整
  const adjustedStartTime = startTime < dayStart ? dayStart : startTime;
  const adjustedEndTime = endTime > dayEnd ? dayEnd : endTime;

  // 24時間を100%として、開始位置と幅を計算
  const startPercentage = ((adjustedStartTime.getHours() * 60 + adjustedStartTime.getMinutes()) / (24 * 60)) * 100;
  const endPercentage = ((adjustedEndTime.getHours() * 60 + adjustedEndTime.getMinutes()) / (24 * 60)) * 100;
  const durationPercentage = endPercentage - startPercentage;

  const tooltipContent = (
    <div>
      <Typography variant="subtitle2">目標: {activity.goals.title}</Typography>
      <Typography variant="body2">
        {format(startTime, 'MM/dd HH:mm')} - {format(endTime, 'MM/dd HH:mm')}
        {startTime.getDate() !== endTime.getDate()}
      </Typography>
      <Typography variant="body2">
        達成内容: 
        {activity.achievement ? (
          activity.achievement.split('\n').map((line, index) => (
            <React.Fragment key={index}>
              {line}
              {index < activity.achievement.split('\n').length - 1 && <br />}
            </React.Fragment>
          ))
        ) : '未記入'}
      </Typography>
      {!activity.start_time && !activity.end_time && (
        <Typography variant="caption">※ 時間が未設定のため、活動記録作成時刻を基準に表示</Typography>
      )}
    </div>
  );

  return (
    <Tooltip title={tooltipContent} arrow placement="top">
      <Box
        onClick={() => onClick(activity)}
        sx={{
          position: 'absolute',
          left: `${startPercentage}%`,
          width: `${durationPercentage}%`,
          height: '100%',
          backgroundColor: color,
          borderRadius: 1,
          cursor: 'pointer',
          border: startTime.getDate() !== endTime.getDate() ? '2px dashed rgba(255,255,255,0.5)' : 'none',
          '&:hover': {
            opacity: 0.8,
          },
        }}
      />
    </Tooltip>
  );
};

export default DailyActivityChart;
