import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

/**
 * プロジェクト完了日を選択するダイアログコンポーネント
 * 
 * @component
 * @param {Object} props - コンポーネントのプロパティ
 * @param {boolean} props.open - ダイアログの表示状態
 * @param {Object} props.project - 完了するプロジェクトの情報
 * @param {function} props.onClose - ダイアログを閉じる際のコールバック関数
 * @param {function} props.onComplete - プロジェクト完了時のコールバック関数
 * @returns {React.Element} プロジェクト完了ダイアログコンポーネント
 */
const ProjectCompleteDialog = ({ open, project, onClose, onComplete }) => {
  const [completeDate, setCompleteDate] = useState(new Date());

  /**
   * 完了確認ボタンクリック時の処理
   */
  const handleCompleteConfirm = () => {
    if (!project || !completeDate) return;

    const utcCompleteDate = new Date(Date.UTC(completeDate.getFullYear(), completeDate.getMonth(), completeDate.getDate()));
    onComplete(project.id, utcCompleteDate.toISOString());
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>プロジェクト完了日の選択</DialogTitle>
        <DialogContent>
          <DatePicker
            label="完了日"
            value={completeDate}
            onChange={(newValue) => setCompleteDate(newValue)}
            format="yyyy/MM/dd"
            slotProps={{ textField: { fullWidth: true, margin: 'normal' } }}
            views={['year', 'month', 'day']}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>キャンセル</Button>
          <Button onClick={handleCompleteConfirm} color="primary">
            完了
          </Button>
        </DialogActions>
      </Dialog>
    </LocalizationProvider>
  );
};

export default ProjectCompleteDialog;
