/**
 * @file PrivateHeader.js
 * @description ログイン後のヘッダーを表示するReactコンポーネント
 * 
 * このコンポーネントは、ログイン後のヘッダーバーを実装します。
 * メニュー、通知、ユーザーアカウントへのアクセスを提供します。
 * 
 * @requires React
 * @requires react-router-dom
 * @requires @mui/material
 * @requires @mui/icons-material
 */

import React, { useState } from 'react';
import {
  AppBar,
  Toolbar,
  IconButton,
  Box,
  Menu,
  MenuItem,
  Avatar,
  Button,
  ListItemIcon,
  ListItemText,
  Divider,
} from '@mui/material';
import { Link } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import {
  Dashboard as DashboardIcon,
  Flag as GoalIcon,
  Assignment as TaskIcon,
  EmojiEvents as AchievementIcon,
  Assessment as ReportIcon,
  Person as ProfileIcon,
  Settings as SettingsIcon,
  Logout as LogoutIcon,
  AccountTree as ProjectIcon,
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';

/**
 * プライベートヘッダーコンポーネント
 * @param {Object} props - コンポーネントのプロパティ
 * @param {Function} props.toggleMenu - メニュー開閉のコールバック関数
 * @param {Function} props.onLogout - ログアウト処理のコールバック関数
 * @returns {JSX.Element} プライベートヘッダーコンポーネント
 */
const PrivateHeader = ({ toggleMenu, onLogout }) => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleProfile = () => {
    handleClose();
    navigate('/profile');
  };

  const handleSettings = () => {
    handleClose();
    navigate('/settings');
  };

  const handleLogout = () => {
    handleClose();
    onLogout();
  };

  // メインメニュー項目
  const mainMenuItems = [
    { text: 'ダッシュボード', icon: <DashboardIcon />, path: '/' },
    { text: 'プロジェクト管理', icon: <ProjectIcon />, path: '/projects' },
    { text: '目標管理', icon: <GoalIcon />, path: '/goals' },
    { text: 'タスク管理', icon: <TaskIcon />, path: '/tasks' },
    { text: '実績管理', icon: <AchievementIcon />, path: '/achievements' },
    { text: '日報管理', icon: <ReportIcon />, path: '/reports' },
  ];

  return (
    <AppBar 
      position="fixed" 
      sx={{ 
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        background: 'linear-gradient(45deg, #0131b4 0%, #0142b4 50%, #0175b4 100%)',
        boxShadow: '0 3px 5px 2px rgba(1, 49, 180, .3)'
      }}
    >
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="メニューを開く"
          edge="start"
          onClick={toggleMenu}
          sx={{ mr: 2 }}
        >
          <MenuIcon />
        </IconButton>
        
        <Link to="/" style={{ textDecoration: 'none', color: 'inherit', display: 'flex', alignItems: 'center' }}>
          <img 
            src="/images/logo.png" 
            alt="RefReco Logo" 
            style={{ height: '40px', maxWidth: '200px', objectFit: 'contain', marginTop: '4px' }} 
          />
        </Link>

        <Box sx={{ display: 'flex', gap: 1, ml: 4 }}>
          {mainMenuItems.map((item) => (
            <Button
              key={item.text}
              color="inherit"
              startIcon={item.icon}
              onClick={() => navigate(item.path)}
              sx={{ minWidth: 'auto' }}
            >
              {item.text}
            </Button>
          ))}
        </Box>

        <Box sx={{ flexGrow: 1 }} />

        <Box>
          <IconButton
            size="large"
            aria-label="ユーザーメニュー"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
          >
            <Avatar
              alt={user?.email}
              src={user?.user_metadata?.avatar_url}
              sx={{ width: 32, height: 32 }}
            />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleProfile}>
              <ListItemIcon>
                <ProfileIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>プロフィール</ListItemText>
            </MenuItem>
            <MenuItem onClick={handleSettings}>
              <ListItemIcon>
                <SettingsIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>設定</ListItemText>
            </MenuItem>
            <Divider />
            <MenuItem onClick={handleLogout}>
              <ListItemIcon>
                <LogoutIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>ログアウト</ListItemText>
            </MenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default PrivateHeader;
