/**
 * @file ReflectionSummaryStats.js
 * @description 振り返りの統計情報を表示するコンポーネント
 * 
 * 振り返りの総数、総振り返り時間、達成目標数、平均振り返り時間などの
 * サマリー情報を表示します。
 */

import React, { useEffect, useState } from 'react';
import {
  Box,
  Paper,
  Grid,
  Typography,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import DescriptionIcon from '@mui/icons-material/Description';
import AvTimerIcon from '@mui/icons-material/AvTimer';

/**
 * 振り返り統計情報コンポーネント
 * @param {Object} props
 * @param {Array} props.reflections - 振り返りデータの配列
 * @param {string|null} props.projectId - プロジェクトID
 * @returns {JSX.Element} 統計情報を表示するコンポーネント
 */
const ReflectionSummaryStats = ({ reflections, projectId }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [stats, setStats] = useState({
    totalReflections: 0,
    totalDuration: 0,
    completedGoals: 0,
    averageReflectionTime: 0
  });

  useEffect(() => {
    // プロジェクトに紐づく振り返りのみをフィルタリング
    const filteredReflections = projectId
      ? reflections.filter(ref => 
          ref.project_id === projectId || 
          ref.goals?.project_id === projectId
        )
      : reflections;

    // 統計データの計算
    const totalReflections = filteredReflections.length;
    const totalDuration = filteredReflections.reduce((acc, ref) => 
      acc + (ref.reflection_duration || 0), 0);
    
    // 達成目標数の計算（goals.is_completedがtrueの目標の数）
    const completedGoalsCount = filteredReflections.reduce((acc, ref) => 
      ref.goals?.is_completed ? acc + 1 : acc, 0);

    setStats({
      totalReflections,
      totalDuration,
      completedGoals: completedGoalsCount,
      averageReflectionTime: totalReflections > 0
        ? Math.round(totalDuration / totalReflections)
        : 0
    });
  }, [reflections, projectId]);

  const StatItem = ({ icon, label, value, unit }) => (
    <Box sx={{ 
      display: 'flex', 
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
      p: 2,
      height: '100%'
    }}>
      <Typography 
        variant="body2" 
        color="text.secondary"
        sx={{
          whiteSpace: 'nowrap',
          fontSize: '0.85rem',
          mb: 1
        }}
      >
        {label}
      </Typography>
      <Typography 
        variant="h4" 
        sx={{ 
          fontWeight: 'bold',
          display: 'flex',
          alignItems: 'baseline',
          justifyContent: 'center',
          mb: 1
        }}
      >
        {value}
        {unit && (
          <Typography 
            variant="body2" 
            component="span" 
            sx={{ 
              ml: 0.5,
              fontSize: '0.9rem',
              color: 'text.secondary'
            }}
          >
            {unit}
          </Typography>
        )}
      </Typography>
      {icon}
    </Box>
  );

  return (
    <Paper sx={{ p: 2, mb: 3 }}>
      <Grid container spacing={2}>
        <Grid item xs={6} sm={3}>
          <StatItem
            icon={<DescriptionIcon sx={{ fontSize: 32, color: 'primary.main' }} />}
            label="総振り返り回数"
            value={stats.totalReflections}
          />
        </Grid>
        <Grid item xs={6} sm={3}>
          <StatItem
            icon={<AccessTimeIcon sx={{ fontSize: 32, color: 'primary.main' }} />}
            label="総振り返り時間"
            value={Math.round(stats.totalDuration / 60)}
            unit="時間"
          />
        </Grid>
        <Grid item xs={6} sm={3}>
          <StatItem
            icon={<EmojiEventsIcon sx={{ fontSize: 32, color: 'primary.main' }} />}
            label="達成目標数"
            value={stats.completedGoals}
          />
        </Grid>
        <Grid item xs={6} sm={3}>
          <StatItem
            icon={<AvTimerIcon sx={{ fontSize: 32, color: 'primary.main' }} />}
            label="平均振り返り時間"
            value={stats.averageReflectionTime}
            unit="分"
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ReflectionSummaryStats; 