/**
 * @file DashboardPage.js
 * @description ダッシュボードページコンポーネント
 * @version 2.0.0
 */

import React, { useState } from 'react';
import {
  Box,
  Typography,
  Paper,
  Grid,
  Button,
  ButtonGroup,
  Tabs,
  Tab,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  FormControlLabel,
  Switch,
  ToggleButtonGroup,
  ToggleButton,
  Tooltip,
  Fab,
  SpeedDial,
  SpeedDialAction,
  SpeedDialIcon,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ViewListIcon from '@mui/icons-material/ViewList';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import FlagIcon from '@mui/icons-material/Flag';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import DescriptionIcon from '@mui/icons-material/Description';
import { useNavigate } from 'react-router-dom';
import GoalTreeChart from '../components/charts/GoalTreeChart';
import GoalsTable from '../components/goals/GoalsTable';
import { useProjectData } from '../hooks/useProjectData';
import { useGoalData } from '../hooks/useGoalData';
import { goalService } from '../services/goalService';
import { reflectionService } from '../services/reflectionService';
import { supabase } from '../utils/supabase';
import SlidingPage from '../components/common/SlidingPage';
import GoalForm from './goals/GoalForm';
import GoalDetailPage from './GoalDetailPage';
import ProjectForm from '../components/projects/ProjectForm';
import ProjectDetailPage from '../components/projects/ProjectDetailPage';
import RecentReflections from '../components/reflections/RecentReflections';
import ReflectionTable from '../components/reflections/ReflectionTable';
import { useReflectionData } from '../hooks/useReflectionData';
import ReflectionForm from '../components/reflections/ReflectionForm';

const DashboardPage = () => {
  const navigate = useNavigate();
  const [selectedProject, setSelectedProject] = useState(null);
  const [showCompletedProjects, setShowCompletedProjects] = useState(false);
  const [viewMode, setViewMode] = useState('tree'); // 'tree' or 'table'
  const [isGoalFormOpen, setIsGoalFormOpen] = useState(false);
  const [isProjectFormOpen, setIsProjectFormOpen] = useState(false);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [goalToDelete, setGoalToDelete] = useState(null);
  const [goalToEdit, setGoalToEdit] = useState(null);
  const [projectToEdit, setProjectToEdit] = useState(null);
  const [isProjectDetailOpen, setIsProjectDetailOpen] = useState(false);
  const [selectedDetailProject, setSelectedDetailProject] = useState(null);
  
  const { projects, handleCreateProject, handleUpdateProject } = useProjectData();
  const { goals, completedGoals, fetchGoals, handleDeleteGoal } = useGoalData(selectedProject);
  const { 
    reflections, 
    loading: reflectionsLoading, 
    error: reflectionsError, 
    handleDeleteReflection,
    handleEditReflection: updateReflection,
    fetchReflections 
  } = useReflectionData(null, selectedProject);

  // フィルタリングされたプロジェクトリストを取得
  const filteredProjects = React.useMemo(() => {
    return projects.filter(project => showCompletedProjects || !project.is_completed);
  }, [projects, showCompletedProjects]);

  // 目標データにプロジェクト情報を追加
  const goalsWithProjects = React.useMemo(() => {
    return goals.map(goal => ({
      ...goal,
      project: projects.find(p => p.id === goal.project_id),
    }));
  }, [goals, projects]);

  // プロジェクト作成ハンドラー
  const handleCreateProjectClick = () => {
    setProjectToEdit(null);
    setIsProjectFormOpen(true);
  };

  // プロジェクトフォームを閉じるハンドラー
  const handleCloseProjectForm = () => {
    setIsProjectFormOpen(false);
    setProjectToEdit(null);
  };

  // プロジェクト保存ハンドラー
  const handleProjectSave = async (projectData) => {
    try {
      if (projectToEdit) {
        await handleUpdateProject(projectToEdit.id, projectData);
      } else {
        await handleCreateProject(projectData);
      }
      handleCloseProjectForm();
    } catch (error) {
      console.error('プロジェクトの保存に失敗しました:', error);
    }
  };

  // 目標作成ハンドラー
  const handleCreateGoal = (initialData = {}) => {
    setGoalToEdit({
      ...initialData,
      project_id: initialData.project_id || null,
      parent_goal_id: initialData.parent_goal_id || null,
      parentGoal: initialData.parentGoal || null
    });
    setIsGoalFormOpen(true);
  };

  // 目標編集ハンドラー
  const handleEditGoal = (goal) => {
    const goalWithProject = {
      ...goal,
      project: projects.find(p => p.id === goal.project_id)
    };
    setGoalToEdit(goalWithProject);
    setIsGoalFormOpen(true);
  };

  // 目標フォームを閉じるハンドラー
  const handleCloseGoalForm = () => {
    setIsGoalFormOpen(false);
    setGoalToEdit(null);
  };

  // 目標保存後のハンドラー
  const handleGoalSave = async (savedGoal) => {
    await fetchGoals();
    handleCloseGoalForm();
  };

  // 振り返り作成用の状態を追加
  const [isNewReflectionFormOpen, setIsNewReflectionFormOpen] = useState(false);

  // 振り返り作成ハンドラー
  const handleCreateReflection = () => {
    setIsNewReflectionFormOpen(true);
  };

  // 新規振り返りフォームを閉じるハンドラー
  const handleCloseNewReflectionForm = () => {
    setIsNewReflectionFormOpen(false);
  };

  // 新規振り返り保存ハンドラー
  const handleSaveNewReflection = async (reflectionData) => {
    try {
      await reflectionService.createReflection(reflectionData);
      await fetchReflections();
      handleCloseNewReflectionForm();
    } catch (error) {
      console.error('振り返りの保存に失敗しました:', error);
      alert('振り返りの保存中にエラーが発生しました。');
    }
  };

  const handleCreateReport = () => navigate('/reports/new');

  // プロジェクト選択ハンドラー
  const handleProjectChange = (event, newValue) => {
    setSelectedProject(newValue);
  };

  // 表示モード切り替えハンドラー
  const handleViewModeChange = (event, newValue) => {
    setViewMode(newValue);
  };

  // 目標削除確認ダイアログを開く
  const handleDeleteConfirmOpen = (goal) => {
    setGoalToDelete(goal);
    setDeleteConfirmOpen(true);
  };

  // 目標削除確認ダイアログを閉じる
  const handleDeleteConfirmClose = () => {
    setDeleteConfirmOpen(false);
    setGoalToDelete(null);
  };

  // 目標削除の実行
  const handleDeleteConfirmed = async () => {
    if (goalToDelete) {
      try {
        await handleDeleteGoal(goalToDelete);
        await fetchGoals();
      } catch (error) {
        console.error('目標の削除に失敗しました:', error);
        alert('目標の削除に失敗しました');
      }
    }
    handleDeleteConfirmClose();
  };

  // 目標詳細表示用の状態を追加
  const [isDetailOpen, setIsDetailOpen] = useState(false);
  const [selectedDetailGoalId, setSelectedDetailGoalId] = useState(null);

  // 目標クリック時の処理を追加
  const handleGoalClick = (goalId) => {
    setSelectedDetailGoalId(goalId);
    setIsDetailOpen(true);
  };

  // 目標詳細を閉じる処理を追加
  const handleCloseDetail = () => {
    setIsDetailOpen(false);
    setSelectedDetailGoalId(null);
  };

  // プロジェクト詳細表示用の状態を追加
  const handleProjectClick = (project) => {
    setSelectedDetailProject(project);
    setIsProjectDetailOpen(true);
  };

  // プロジェクト詳細を閉じる処理を追加
  const handleCloseProjectDetail = () => {
    setIsProjectDetailOpen(false);
    setSelectedDetailProject(null);
  };

  // 振り返り表示用の状態を追加
  const [reflectionViewMode, setReflectionViewMode] = useState('card');
  const [selectedReflections, setSelectedReflections] = useState([]);

  // 振り返り表示モード切り替えハンドラー
  const handleReflectionViewModeChange = (event, newMode) => {
    if (newMode !== null) {
      setReflectionViewMode(newMode);
    }
  };

  // 振り返り編集ハンドラー
  const handleReflectionEditClick = (reflection) => {
    setEditingReflection(reflection);
    setIsReflectionFormOpen(true);
  };

  // 振り返り削除ハンドラー
  const handleDeleteReflectionClick = async (reflection) => {
    try {
      await handleDeleteReflection({ id: reflection.id });
    } catch (error) {
      console.error('振り返りの削除に失敗しました:', error);
      alert('振り返りの削除中にエラーが発生しました。');
    }
  };

  // 振り返り確認用の状態を追加
  const [editingReflection, setEditingReflection] = useState(null);
  const [isReflectionFormOpen, setIsReflectionFormOpen] = useState(false);

  // 振り返り保存ハンドラー
  const handleSaveReflection = async (updatedReflection) => {
    try {
      await updateReflection(updatedReflection);
      setIsReflectionFormOpen(false);
      setEditingReflection(null);
      // 振り返りデータを再取得
      fetchReflections();
    } catch (error) {
      console.error('振り返りの更新に失敗しました:', error);
      alert('振り返りの更新中にエラーが発生しました。');
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      {/* アクションボタン */}
      <Box sx={{ position: 'fixed', bottom: 24, right: 24, zIndex: 1000 }}>
        <SpeedDial
          ariaLabel="アクション"
          icon={<SpeedDialIcon />}
          sx={{
            '& .MuiFab-primary': {
              background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
              boxShadow: '0 3px 5px 2px rgba(33, 203, 243, .3)',
              '&:hover': {
                background: 'linear-gradient(45deg, #1976D2 30%, #00BCD4 90%)',
                transform: 'scale(1.05)',
                transition: 'transform 0.2s ease-in-out',
              },
            }
          }}
        >
          <SpeedDialAction
            icon={<AddIcon />}
            tooltipTitle="プロジェクト"
            onClick={handleCreateProjectClick}
            sx={{
              background: 'linear-gradient(45deg, #4CAF50 30%, #8BC34A 90%)',
              color: 'white',
              '&:hover': {
                background: 'linear-gradient(45deg, #388E3C 30%, #689F38 90%)',
              },
            }}
          />
          <SpeedDialAction
            icon={<FlagIcon />}
            tooltipTitle="目標"
            onClick={handleCreateGoal}
            sx={{
              background: 'linear-gradient(45deg, #FF9800 30%, #FFC107 90%)',
              color: 'white',
              '&:hover': {
                background: 'linear-gradient(45deg, #F57C00 30%, #FFA000 90%)',
              },
            }}
          />
          <SpeedDialAction
            icon={<EmojiEventsIcon />}
            tooltipTitle="実績"
            onClick={handleCreateReflection}
            sx={{
              background: 'linear-gradient(45deg, #9C27B0 30%, #E91E63 90%)',
              color: 'white',
              '&:hover': {
                background: 'linear-gradient(45deg, #7B1FA2 30%, #C2185B 90%)',
              },
            }}
          />
          <SpeedDialAction
            icon={<DescriptionIcon />}
            tooltipTitle="日報"
            onClick={handleCreateReport}
            sx={{
              background: 'linear-gradient(45deg, #3F51B5 30%, #2196F3 90%)',
              color: 'white',
              '&:hover': {
                background: 'linear-gradient(45deg, #303F9F 30%, #1976D2 90%)',
              },
            }}
          />
        </SpeedDial>
      </Box>

      {/* プロジェクト選択 */}
      <Paper sx={{ mb: 3, p: 2 }}>
        <Typography variant="h6" gutterBottom>プロジェクト</Typography>
        <Box sx={{ 
          display: 'flex', 
          flexDirection: 'column', 
          gap: 2 
        }}>
          <FormControlLabel
            control={
              <Switch
                checked={showCompletedProjects}
                onChange={(e) => setShowCompletedProjects(e.target.checked)}
                color="primary"
                size="small"
              />
            }
            label="完了済みプロジェクトを表示"
          />
          <Tabs
            value={selectedProject}
            onChange={handleProjectChange}
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab label="すべて" value={null} />
            {filteredProjects.map((project) => (
              <Tab key={project.id} label={project.name} value={project.id} />
            ))}
          </Tabs>
        </Box>
      </Paper>

      {/* 目標管理 */}
      <Paper sx={{ p: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Typography variant="h6">目標管理</Typography>
          <Tabs value={viewMode} onChange={handleViewModeChange}>
            <Tab label="ツリー表示" value="tree" />
            <Tab label="テーブル表示" value="table" />
          </Tabs>
        </Box>
        <Box sx={{ minHeight: 400 }}>
          {viewMode === 'tree' ? (
            <GoalTreeChart 
              goals={goalsWithProjects}
              projects={projects}
              completedGoals={completedGoals}
              onAddChildGoal={handleCreateGoal}
              onGoalClick={handleGoalClick}
              onProjectClick={handleProjectClick}
              selectedProjectId={selectedProject}
            />
          ) : (
            <GoalsTable 
              goals={goalsWithProjects}
              onGoalClick={(id) => handleGoalClick(id)}
              onEditGoal={handleEditGoal}
              onDeleteGoal={handleDeleteConfirmOpen}
            />
          )}
        </Box>
      </Paper>

      {/* プロジェクト作成/編集フォーム */}
      <SlidingPage isOpen={isProjectFormOpen} onClose={handleCloseProjectForm}>
        <ProjectForm
          project={projectToEdit}
          onClose={handleCloseProjectForm}
          onSave={handleProjectSave}
        />
      </SlidingPage>

      {/* 目標作成/編集フォーム */}
      <SlidingPage isOpen={isGoalFormOpen} onClose={handleCloseGoalForm}>
        <GoalForm
          goal={goalToEdit}
          onClose={handleCloseGoalForm}
          onSave={handleGoalSave}
        />
      </SlidingPage>

      {/* 目標詳細スライディングページを追加 */}
      <SlidingPage isOpen={isDetailOpen} onClose={handleCloseDetail}>
        {selectedDetailGoalId && (
          <GoalDetailPage
            goalId={selectedDetailGoalId}
            onClose={handleCloseDetail}
            onUpdate={fetchGoals}
          />
        )}
      </SlidingPage>

      {/* プロジェクト詳細スライディングページを追加 */}
      <SlidingPage isOpen={isProjectDetailOpen} onClose={handleCloseProjectDetail}>
        {selectedDetailProject && (
          <ProjectDetailPage
            project={selectedDetailProject}
            onClose={handleCloseProjectDetail}
          />
        )}
      </SlidingPage>

      {/* 振り返りセクションを更新 */}
      <Paper sx={{ p: 2, mt: 3 }}>
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center', 
          mb: 2 
        }}>
          <Typography variant="h6">最近の振り返り</Typography>
          <ToggleButtonGroup
            value={reflectionViewMode}
            exclusive
            onChange={handleReflectionViewModeChange}
            aria-label="表示モード"
            size="small"
          >
            <Tooltip title="カード表示">
              <ToggleButton value="card" aria-label="カード表示">
                <ViewModuleIcon />
              </ToggleButton>
            </Tooltip>
            <Tooltip title="リスト表示">
              <ToggleButton value="list" aria-label="リスト表示">
                <ViewListIcon />
              </ToggleButton>
            </Tooltip>
          </ToggleButtonGroup>
        </Box>

        {reflectionsLoading ? (
          <Typography>振り返りデータを読み込み中...</Typography>
        ) : reflectionsError ? (
          <Typography color="error">エラー: {reflectionsError}</Typography>
        ) : reflectionViewMode === 'list' ? (
          <ReflectionTable
            reflections={reflections}
            selectedReflections={selectedReflections}
            onSelectionChange={setSelectedReflections}
            projectId={selectedProject}
            onView={handleReflectionEditClick}
            onDelete={handleDeleteReflectionClick}
            initialDisplayCount={10}
          />
        ) : (
          <RecentReflections
            defaultProjectFilter={selectedProject}
            refreshTrigger={reflectionViewMode}
            onEdit={handleReflectionEditClick}
          />
        )}
      </Paper>

      {/* ReflectionFormを追加 */}
      <SlidingPage isOpen={isReflectionFormOpen} onClose={() => {
        setIsReflectionFormOpen(false);
        setEditingReflection(null);
      }}>
        {editingReflection && (
          <ReflectionForm
            goal={editingReflection.goals}
            reflection={editingReflection}
            onClose={() => {
              setIsReflectionFormOpen(false);
              setEditingReflection(null);
            }}
            onSave={handleSaveReflection}
            isDialog={true}
          />
        )}
      </SlidingPage>

      {/* 新規振り返りフォーム */}
      <SlidingPage isOpen={isNewReflectionFormOpen} onClose={handleCloseNewReflectionForm}>
        <ReflectionForm
          onClose={handleCloseNewReflectionForm}
          onSave={handleSaveNewReflection}
          isDialog={true}
        />
      </SlidingPage>

      {/* 削除確認ダイアログ */}
      <Dialog
        open={deleteConfirmOpen}
        onClose={handleDeleteConfirmClose}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <DialogTitle id="delete-dialog-title">
          目標の削除確認
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-dialog-description">
            {goalToDelete?.title} を削除してもよろしいですか？
            {goalToDelete?.child_goals?.length > 0 && (
              <Typography color="error" sx={{ mt: 1 }}>
                警告: この目標には{goalToDelete.child_goals.length}個のサブ目標が含まれています。
                削除すると、すべてのサブ目標も削除されます。
              </Typography>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteConfirmClose}>
            キャンセル
          </Button>
          <Button onClick={handleDeleteConfirmed} color="error" variant="contained">
            削除
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default DashboardPage; 