/**
 * @file Navigation.js
 * @description ナビゲーションドロワーコンポーネント
 */

import React from 'react';
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  Divider,
} from '@mui/material';
import {
  Dashboard as DashboardIcon,
  Assignment as TaskIcon,
  Flag as GoalIcon,
  EmojiEvents as AchievementIcon,
  Assessment as ReportIcon,
  Settings as SettingsIcon,
  AdminPanelSettings as AdminIcon,
  Person as ProfileIcon,
  AccountTree as ProjectIcon,
} from '@mui/icons-material';
import { useNavigate, useLocation } from 'react-router-dom';

/**
 * ナビゲーションドロワーコンポーネント
 * @param {Object} props - コンポーネントのプロパティ
 * @param {boolean} props.isOpen - ドロワーの開閉状態
 * @param {Function} props.toggleMenu - ドロワーの開閉を制御するコールバック関数
 * @param {boolean} props.isAdmin - 管理者権限の有無
 * @returns {JSX.Element} ナビゲーションドロワーコンポーネント
 */
const Navigation = ({ isOpen, toggleMenu, isAdmin }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const menuItems = [
    { text: 'ダッシュボード', icon: <DashboardIcon />, path: '/' },
    { text: 'プロジェクト管理', icon: <ProjectIcon />, path: '/projects' },
    { text: '目標管理', icon: <GoalIcon />, path: '/goals' },
    { text: 'タスク管理', icon: <TaskIcon />, path: '/tasks' },
    { text: '実績管理', icon: <AchievementIcon />, path: '/achievements' },
    { text: '日報管理', icon: <ReportIcon />, path: '/reports' },
  ];

  const handleNavigate = (path) => {
    navigate(path);
    toggleMenu();
  };

  return (
    <Drawer
      anchor="left"
      open={isOpen}
      onClose={toggleMenu}
      sx={{
        '& .MuiDrawer-paper': {
          width: 240,
          boxSizing: 'border-box',
          top: { xs: 0, sm: 64 },
          height: { xs: '100%', sm: 'calc(100% - 64px)' },
        },
      }}
    >
      <List>
        {menuItems.map((item) => (
          <ListItem key={item.text} disablePadding>
            <ListItemButton
              selected={location.pathname === item.path}
              onClick={() => handleNavigate(item.path)}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>

      <Divider />

      <List>
        <ListItem disablePadding>
          <ListItemButton
            selected={location.pathname === '/profile'}
            onClick={() => handleNavigate('/profile')}
          >
            <ListItemIcon>
              <ProfileIcon />
            </ListItemIcon>
            <ListItemText primary="プロフィール" />
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding>
          <ListItemButton
            selected={location.pathname === '/settings'}
            onClick={() => handleNavigate('/settings')}
          >
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText primary="設定" />
          </ListItemButton>
        </ListItem>

        {isAdmin && (
          <ListItem disablePadding>
            <ListItemButton
              selected={location.pathname === '/admin'}
              onClick={() => handleNavigate('/admin')}
            >
              <ListItemIcon>
                <AdminIcon />
              </ListItemIcon>
              <ListItemText primary="管理画面" />
            </ListItemButton>
          </ListItem>
        )}
      </List>
    </Drawer>
  );
};

export default Navigation;
