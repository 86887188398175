/**
 * @file GanttChart.js
 * @description 共通のガントチャートコンポーネント
 */

import React, { useState, useMemo } from 'react';
import {
  Box,
  Paper,
  ToggleButtonGroup,
  ToggleButton,
  Typography,
  Tooltip,
  Chip,
} from '@mui/material';
import {
  startOfMonth,
  endOfMonth,
  eachDayOfInterval,
  eachWeekOfInterval,
  eachMonthOfInterval,
  format,
  isSameDay,
  differenceInDays,
  isWithinInterval,
} from 'date-fns';
import { ja } from 'date-fns/locale';

/**
 * ガントチャートコンポーネント
 * 
 * @param {Object} props
 * @param {Array} props.items - 表示するアイテムの配列
 * @param {string} props.nameKey - アイテム名を取得するキー
 * @param {string} props.startDateKey - 開始日を取得するキー
 * @param {string} props.endDateKey - 終了日を取得するキー
 * @param {string} props.statusKey - ステータスを取得するキー
 * @param {function} props.getStatusColor - ステータスに応じた色を返す関数
 * @param {function} props.onItemClick - アイテムクリック時のコールバック
 * @param {Object} props.relatedItems - 関連アイテムの情報（オプション）
 */
const GanttChart = ({
  items,
  nameKey = 'name',
  startDateKey = 'start_date',
  endDateKey = 'end_date',
  statusKey = 'status',
  getStatusColor,
  onItemClick,
  relatedItems,
}) => {
  const [timeUnit, setTimeUnit] = useState('month');
  const [startDate, setStartDate] = useState(() => {
    const dates = items
      .map(item => item[startDateKey])
      .filter(Boolean)
      .map(date => new Date(date));
    return dates.length > 0 ? new Date(Math.min(...dates)) : new Date();
  });

  const endDate = useMemo(() => {
    const dates = items
      .map(item => item[endDateKey])
      .filter(Boolean)
      .map(date => new Date(date));
    return dates.length > 0 ? new Date(Math.max(...dates)) : new Date();
  }, [items, endDateKey]);

  // タイムラインの期間を計算
  const timelineInterval = useMemo(() => {
    const start = startOfMonth(startDate);
    const end = endOfMonth(endDate);
    
    switch (timeUnit) {
      case 'day':
        return eachDayOfInterval({ start, end });
      case 'week':
        return eachWeekOfInterval({ start, end });
      case 'month':
        return eachMonthOfInterval({ start, end });
      default:
        return eachDayOfInterval({ start, end });
    }
  }, [startDate, endDate, timeUnit]);

  // タイムラインのヘッダーフォーマット
  const getHeaderFormat = (date) => {
    switch (timeUnit) {
      case 'day':
        return format(date, 'M/d', { locale: ja });
      case 'week':
        return `${format(date, 'M/d', { locale: ja })}週`;
      case 'month':
        return format(date, 'yyyy年M月', { locale: ja });
      default:
        return format(date, 'M/d', { locale: ja });
    }
  };

  // バーの位置とサイズを計算
  const getBarStyle = (item) => {
    if (!item[startDateKey] || !item[endDateKey]) return null;

    const itemStart = new Date(item[startDateKey]);
    const itemEnd = new Date(item[endDateKey]);
    const totalDays = differenceInDays(timelineInterval[timelineInterval.length - 1], timelineInterval[0]);
    const startOffset = differenceInDays(itemStart, timelineInterval[0]);
    const duration = differenceInDays(itemEnd, itemStart);

    return {
      left: `${(startOffset / totalDays) * 100}%`,
      width: `${(duration / totalDays) * 100}%`,
      backgroundColor: getStatusColor ? getStatusColor(item[statusKey]) : '#2196f3',
      height: '30px',
      position: 'absolute',
      borderRadius: '4px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      '&:hover': {
        opacity: 0.8,
      },
    };
  };

  return (
    <Paper sx={{ p: 2 }}>
      <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h6">ガントチャート</Typography>
        <ToggleButtonGroup
          value={timeUnit}
          exclusive
          onChange={(e, newValue) => newValue && setTimeUnit(newValue)}
          size="small"
        >
          <ToggleButton value="day">日</ToggleButton>
          <ToggleButton value="week">週</ToggleButton>
          <ToggleButton value="month">月</ToggleButton>
        </ToggleButtonGroup>
      </Box>

      <Box sx={{ display: 'flex' }}>
        {/* 左側の項目リスト */}
        <Box sx={{ width: '200px', borderRight: '1px solid rgba(0, 0, 0, 0.12)', pr: 2 }}>
          <Box sx={{ height: '50px', borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }} />
          {items.map((item, index) => (
            <Box
              key={item.id}
              sx={{
                height: '50px',
                display: 'flex',
                alignItems: 'center',
                borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
              }}
            >
              <Box sx={{ mr: 1 }}>
                <Typography variant="body2" noWrap>
                  {item[nameKey]}
                </Typography>
                {item[statusKey] && (
                  <Chip
                    label={item[statusKey]}
                    size="small"
                    sx={{
                      bgcolor: getStatusColor ? getStatusColor(item[statusKey]) : undefined,
                      color: 'white',
                      mt: 0.5,
                    }}
                  />
                )}
              </Box>
            </Box>
          ))}
        </Box>

        {/* 右側のタイムライン */}
        <Box sx={{ flex: 1, overflow: 'auto' }}>
          {/* タイムラインヘッダー */}
          <Box
            sx={{
              display: 'flex',
              borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
              height: '50px',
            }}
          >
            {timelineInterval.map((date) => (
              <Box
                key={date.toISOString()}
                sx={{
                  flex: 1,
                  minWidth: timeUnit === 'day' ? '50px' : '100px',
                  p: 1,
                  textAlign: 'center',
                  borderRight: '1px solid rgba(0, 0, 0, 0.08)',
                }}
              >
                <Typography variant="caption">{getHeaderFormat(date)}</Typography>
              </Box>
            ))}
          </Box>

          {/* タイムラインコンテンツ */}
          <Box>
            {items.map((item) => (
              <Box
                key={item.id}
                sx={{
                  height: '50px',
                  position: 'relative',
                  borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
                }}
              >
                {getBarStyle(item) && (
                  <Tooltip
                    title={
                      <Box>
                        <Typography variant="body2">{item[nameKey]}</Typography>
                        <Typography variant="caption">
                          {format(new Date(item[startDateKey]), 'yyyy/MM/dd')} ～{' '}
                          {format(new Date(item[endDateKey]), 'yyyy/MM/dd')}
                        </Typography>
                        {relatedItems && relatedItems[item.id] && (
                          <Box sx={{ mt: 1 }}>
                            <Typography variant="caption">
                              関連目標: {relatedItems[item.id].goals?.length || 0}
                              {' | '}
                              関連タスク: {relatedItems[item.id].tasks?.length || 0}
                            </Typography>
                          </Box>
                        )}
                      </Box>
                    }
                  >
                    <Box
                      sx={getBarStyle(item)}
                      onClick={() => onItemClick && onItemClick(item)}
                    />
                  </Tooltip>
                )}
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </Paper>
  );
};

export default GanttChart; 