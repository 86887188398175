/**
 * @fileoverview 直近の振り返りを表示し、フィルタリングするコンポーネント
 * @module RecentReflections
 */

import React, { useState, useMemo, useEffect } from 'react';
import { Typography, Paper, Box, Grid, Select, MenuItem, FormControl, InputLabel, Button, useMediaQuery, useTheme } from '@mui/material';
import { useProjectData } from '../../hooks/useProjectData';
import { useReflectionData } from '../../hooks/useReflectionData';
import ReflectionDialog from './ReflectionDialog';
import ReflectionRemoveDialog from './ReflectionRemoveDialog';
import ReflectionCard from './ReflectionCard';

/**
 * 直近の振り返りを表示し、フィルタリングするコンポーネント
 * @param {Object} props - コンポーネントのプロパティ
 * @param {string} [props.defaultProjectFilter] - デフォルトのプロジェクトフィルター
 * @param {string} [props.defaultGoalFilter] - デフォルトのゴールフィルター
 * @param {string} [props.targetUserId] - 表示対象のユーザーID
 * @param {boolean} [props.isViewingOther] - 他のユーザーの振り返りを表示しているかどうか
 * @param {Function} [props.onEdit] - 編集ボタンクリック時のコールバック
 * @returns {JSX.Element} 振り返り一覧のJSX
 */
const RecentReflections = ({ 
  defaultProjectFilter, 
  defaultGoalFilter, 
  refreshTrigger,
  targetUserId = null,
  isViewingOther = false,
  onEdit = () => {}
}) => {
  const { reflections, loading, error, fetchReflections, handleDeleteReflection } = useReflectionData(defaultGoalFilter, defaultProjectFilter, targetUserId);
  const { projects } = useProjectData(targetUserId);
  const [displayCount, setDisplayCount] = useState(4);
  const [projectFilter, setProjectFilter] = useState(defaultProjectFilter || '');
  const [goalFilter, setGoalFilter] = useState(defaultGoalFilter || '');
  const [skillFilter, setSkillFilter] = useState('');
  const [unitFilter, setUnitFilter] = useState('');
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [editingReflection, setEditingReflection] = useState(null);
  const [deletingReflection, setDeletingReflection] = useState(null);

  useEffect(() => {
    setProjectFilter(defaultProjectFilter || '');
  }, [defaultProjectFilter]);

  useEffect(() => {
    fetchReflections();
  }, [refreshTrigger, defaultProjectFilter]);

  /**
   * 表示する振り返りの数を増やす
   */
  const handleShowMore = () => {
    setDisplayCount(prevCount => prevCount + 8);
  };

  /**
   * プロジェクトオプションを生成する
   * @type {Array<{id: string, name: string}>}
   */
  const projectOptions = useMemo(() => {
    return projects.map(project => ({
      id: project.id,
      name: project.name
    }));
  }, [projects]);

  /**
   * ゴールオプションを生成する
   * @type {Array<{id: string, name: string}>}
   */
  const goalOptions = useMemo(() => {
    return [...new Set(reflections.map(r => r.goals?.id).filter(Boolean))].map(id => ({
      id,
      name: reflections.find(r => r.goals?.id === id)?.goals?.title || 'Unknown'
    }));
  }, [reflections]);

  /**
   * スキルオプションを生成する
   * @type {Array<{id: string, name: string}>}
   */
  const skillOptions = useMemo(() => {
    const skills = reflections.flatMap(r => r.skills || []);
    return [...new Set(skills.map(s => s?.id).filter(Boolean))].map(id => ({
      id,
      name: skills.find(s => s?.id === id)?.name || 'Unknown'
    }));
  }, [reflections]);

  /**
   * 単位オプションを生成する
   * @type {Array<{id: string, name: string}>}
   */
  const unitOptions = useMemo(() => {
    return [...new Set(reflections.map(r => r.goals?.unit_id).filter(Boolean))].map(id => ({
      id,
      name: reflections.find(r => r.goals?.unit_id === id)?.goals?.units?.name || 'Unknown'
    }));
  }, [reflections]);

  /**
   * フィルタリングされた振り返りを取得する
   * @type {Array<Object>}
   */
  const filteredReflections = useMemo(() => {
    return reflections.filter(reflection => {
      const matchesProject = !projectFilter || 
        reflection.project_id === projectFilter || 
        reflection.goals?.project_id === projectFilter;
      
      const matchesGoal = !goalFilter || reflection.goals?.id === goalFilter;
      
      const matchesSkill = !skillFilter || 
        reflection.skills?.some(skill => skill?.id === skillFilter);
      
      const matchesUnit = !unitFilter || reflection.goals?.unit_id === unitFilter;

      return matchesProject && matchesGoal && matchesSkill && matchesUnit;
    });
  }, [reflections, projectFilter, goalFilter, skillFilter, unitFilter]);

  const handleEditReflection = (reflection) => {
    if (!isViewingOther) {
      onEdit(reflection);
    }
  };

  const handleSaveReflection = async (updatedReflection) => {
    if (!isViewingOther) {
      await handleEditReflection(updatedReflection);
      setEditingReflection(null);
      fetchReflections();
    }
  };

  const handleDeleteClick = (reflection) => {
    if (!isViewingOther) {
      setDeletingReflection(reflection);
    }
  };

  const handleConfirmDelete = async () => {
    if (deletingReflection && !isViewingOther) {
      try {
        await handleDeleteReflection({ id: deletingReflection.id });
        setDeletingReflection(null);
        fetchReflections();
      } catch (error) {
        console.error('振り返りの削除に失敗しました:', error);
        alert('振り返りの削除中にエラーが発生しました。');
      }
    }
  };

  return (
    <Paper elevation={3} sx={{ p: 2 }}>
      <Typography variant="h6" gutterBottom>直近の振り返り</Typography>
      
      <Box sx={{
        display: 'flex',
        flexDirection: isSmallScreen ? 'column' : 'row',
        gap: 2,
        mb: 2,
        overflowX: isSmallScreen ? 'auto' : 'visible',
        '&::-webkit-scrollbar': {
          height: '8px',
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: theme.palette.grey[300],
          borderRadius: '4px',
        },
      }}>
        <FormControl size="small" sx={{ minWidth: 120 }}>
          <InputLabel>プロジェクト</InputLabel>
          <Select 
            value={projectFilter} 
            onChange={(e) => setProjectFilter(e.target.value)} 
            label="プロジェクト"
          >
            <MenuItem value="">全て</MenuItem>
            {projectOptions.map(option => (
              <MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl size="small" sx={{ minWidth: 120 }}>
          <InputLabel>ゴール</InputLabel>
          <Select value={goalFilter} onChange={(e) => setGoalFilter(e.target.value)} label="ゴール">
            <MenuItem value="">全て</MenuItem>
            {goalOptions.map(option => (
              <MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl size="small" sx={{ minWidth: 120 }}>
          <InputLabel>スキル</InputLabel>
          <Select value={skillFilter} onChange={(e) => setSkillFilter(e.target.value)} label="スキル">
            <MenuItem value="">全て</MenuItem>
            {skillOptions.map(option => (
              <MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl size="small" sx={{ minWidth: 120 }}>
          <InputLabel>単位</InputLabel>
          <Select value={unitFilter} onChange={(e) => setUnitFilter(e.target.value)} label="単位">
            <MenuItem value="">全て</MenuItem>
            {unitOptions.map(option => (
              <MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <Grid container spacing={2}>
        {filteredReflections.slice(0, displayCount).map((reflection) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={reflection.id}>
            <ReflectionCard
              reflection={reflection}
              onEdit={handleEditReflection}
              onDelete={handleDeleteClick}
              isViewingOther={isViewingOther}
              projectName={
                reflection.projects?.name || 
                reflection.goals?.projects?.name || 
                projects.find(p => p.id === (reflection.project_id || reflection.goals?.project_id))?.name
              }
            />
          </Grid>
        ))}
      </Grid>
      {displayCount < filteredReflections.length && (
        <Box sx={{ textAlign: 'center', mt: 2 }}>
          <Button variant="outlined" onClick={handleShowMore}>
            さらに表示する
          </Button>
        </Box>
      )}

      {!isViewingOther && editingReflection && (
        <ReflectionDialog
          goal={editingReflection.goals}
          reflection={editingReflection}
          onClose={() => setEditingReflection(null)}
          onSave={handleSaveReflection}
          open={Boolean(editingReflection)}
        />
      )}

      {!isViewingOther && deletingReflection && (
        <ReflectionRemoveDialog
          reflections={[deletingReflection.id]}
          onClose={() => setDeletingReflection(null)}
          onRemove={handleConfirmDelete}
          onActivityChange={fetchReflections}
        />
      )}
    </Paper>
  );
};

export default RecentReflections;
