/**
 * メッセージ通知用Snackbarコンポーネント
 * @module MessageSnackbar
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Snackbar, Alert } from '@mui/material';

/**
 * メッセージ通知用Snackbarコンポーネント
 * @param {Object} props - コンポーネントのプロパティ
 * @returns {React.Element} メッセージ通知
 */
export const MessageSnackbar = ({
  open,
  message,
  severity = 'info',
  onClose,
  autoHideDuration = 6000
}) => {
  return (
    <Snackbar
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={onClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    >
      <Alert onClose={onClose} severity={severity} sx={{ width: '100%' }}>
        {message}
      </Alert>
    </Snackbar>
  );
};

MessageSnackbar.propTypes = {
  open: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  severity: PropTypes.oneOf(['error', 'warning', 'info', 'success']),
  onClose: PropTypes.func.isRequired,
  autoHideDuration: PropTypes.number
};

export default MessageSnackbar; 