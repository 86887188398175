/**
 * @file ProjectGanttChart.js
 * @description プロジェクト用のガントチャートコンポーネント
 */

import React from 'react';
import GanttChart from '../common/GanttChart';

/**
 * プロジェクト用のガントチャートコンポーネント
 * 
 * @param {Object} props
 * @param {Array} props.projects - プロジェクトの配列
 * @param {Object} props.relatedItems - 関連する目標とタスクの情報
 * @param {function} props.onProjectClick - プロジェクトクリック時のコールバック
 */
const ProjectGanttChart = ({ projects, relatedItems, onProjectClick }) => {
  const getStatusColor = (status) => {
    switch (status) {
      case '未着手':
        return '#9e9e9e';
      case '進行中':
        return '#2196f3';
      case '完了':
        return '#4caf50';
      default:
        return '#9e9e9e';
    }
  };

  return (
    <GanttChart
      items={projects}
      nameKey="name"
      startDateKey="start_date"
      endDateKey="end_date"
      statusKey="status"
      getStatusColor={getStatusColor}
      onItemClick={onProjectClick}
      relatedItems={relatedItems}
    />
  );
};

export default ProjectGanttChart;
