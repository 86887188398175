/**
 * 日報データを管理するカスタムフック
 * @module useReportData
 */

import { useState, useEffect, useCallback } from 'react';
import { useAuth } from './useAuth';
import { supabase } from '../utils/supabase';

/**
 * 日報データを管理するフック
 * @param {Object} initialFilters - 初期フィルター
 * @returns {Object} 日報データと操作関数
 */
export const useReportData = (initialFilters = {}) => {
  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filters, setFilters] = useState(initialFilters);
  const { user } = useAuth();

  /**
   * 日報一覧を取得する
   */
  const fetchReportData = useCallback(async () => {
    if (!user) return;

    try {
      setLoading(true);
      let query = supabase
        .from('reports')
        .select(`
          *,
          project:project_id (
            id,
            name
          )
        `)
        .eq('user_id', user.id)
        .order('report_date', { ascending: false });

      if (filters.startDate) {
        query = query.gte('report_date', filters.startDate);
      }
      if (filters.endDate) {
        query = query.lte('report_date', filters.endDate);
      }
      if (filters.projectId) {
        query = query.eq('project_id', filters.projectId);
      }
      if (filters.searchText) {
        query = query.or(`title.ilike.%${filters.searchText}%,content.ilike.%${filters.searchText}%`);
      }
      if (filters.tags && filters.tags.length > 0) {
        query = query.contains('tags', filters.tags);
      }

      const { data: reportsData, error: fetchError } = await query;

      if (fetchError) throw fetchError;

      // 目標データを別途取得
      if (reportsData && reportsData.length > 0) {
        const allGoalIds = reportsData.flatMap(report => report.goal_ids || []);
        const uniqueGoalIds = [...new Set(allGoalIds)];

        if (uniqueGoalIds.length > 0) {
          const { data: goalsData, error: goalsError } = await supabase
            .from('goals')
            .select('id, title')
            .in('id', uniqueGoalIds);

          if (goalsError) throw goalsError;

          // 日報データに目標情報を追加
          const reportsWithGoals = reportsData.map(report => ({
            ...report,
            goals: (report.goal_ids || [])
              .map(goalId => goalsData.find(g => g.id === goalId))
              .filter(Boolean)
          }));

          setReports(reportsWithGoals);
        } else {
          setReports(reportsData);
        }
      } else {
        setReports([]);
      }

      setError(null);
    } catch (err) {
      console.error('Error fetching reports:', err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  }, [user, filters]);

  /**
   * 日報を作成する
   * @param {Object} reportData - 日報データ
   */
  const createReport = async (reportData) => {
    if (!user) throw new Error('認証が必要です');

    try {
      setLoading(true);
      const { data, error: createError } = await supabase
        .from('reports')
        .insert([{
          user_id: user.id,
          report_date: reportData.report_date,
          title: reportData.title,
          content: reportData.content,
          project_id: reportData.project_id,
          goal_ids: reportData.goal_ids || [],
          tags: reportData.tags || [],
          status: reportData.status || 'draft',
          is_ai_generated: reportData.is_ai_generated || false,
          ai_summary: reportData.ai_summary || null,
          created_at: new Date().toISOString(),
          last_edited_at: new Date().toISOString()
        }])
        .select()
        .single();

      if (createError) throw createError;
      await fetchReportData();
      return data;
    } catch (err) {
      console.error('Error creating report:', err);
      throw err;
    } finally {
      setLoading(false);
    }
  };

  /**
   * 日報を更新する
   * @param {number} id - 日報ID
   * @param {Object} updates - 更新データ
   */
  const updateReport = async (id, updates) => {
    if (!user) throw new Error('認証が必要です');

    try {
      setLoading(true);
      const { data, error: updateError } = await supabase
        .from('reports')
        .update({
          title: updates.title,
          content: updates.content,
          report_date: updates.report_date,
          project_id: updates.project_id,
          goal_ids: updates.goal_ids,
          last_edited_at: new Date().toISOString()
        })
        .eq('id', id)
        .eq('user_id', user.id)
        .select(`
          *,
          project:project_id (
            id,
            name
          )
        `)
        .single();

      if (updateError) throw updateError;
      await fetchReportData();
      return data;
    } catch (err) {
      console.error('Error updating report:', err);
      throw err;
    } finally {
      setLoading(false);
    }
  };

  /**
   * 日報を削除する
   * @param {number} id - 日報ID
   */
  const deleteReport = async (id) => {
    if (!user) throw new Error('認証が必要です');

    try {
      setLoading(true);
      const { error: deleteError } = await supabase
        .from('reports')
        .delete()
        .eq('id', id)
        .eq('user_id', user.id);

      if (deleteError) throw deleteError;
      await fetchReportData();
    } catch (err) {
      console.error('Error deleting report:', err);
      throw err;
    } finally {
      setLoading(false);
    }
  };

  /**
   * フィルターを更新する
   * @param {Object} newFilters - 新しいフィルター
   */
  const updateFilters = (newFilters) => {
    setFilters(prev => ({ ...prev, ...newFilters }));
  };

  useEffect(() => {
    if (user) {
      fetchReportData();
    }
  }, [user, fetchReportData]);

  return {
    reports,
    loading,
    error,
    createReport,
    updateReport,
    deleteReport,
    updateFilters,
    refreshReports: fetchReportData,
  };
}; 