/**
 * @file GoalsTable.js
 * @description 目標一覧を表形式で表示するコンポーネント
 * 
 * 目標の一覧をテーブル形式で表示し、各目標の詳細情報や操作ボタンを提供します。
 * 
 * @typedef {Object} Goal
 * @property {number} id - 目標ID
 * @property {string} title - 目標タイトル
 * @property {string} description - 目標の説明
 * @property {Object} project - 関連プロジェクト情報
 * @property {Object} parent_goal - 親目標情報
 * @property {number} taskCount - タスク数
 * @property {number} target_value - 目標値
 * @property {string} unit_name - 単位名
 * @property {number} totalActualValue - 実績値
 * @property {string} deadline - 期限
 * @property {number} attachmentCount - 添付ファイル数
 * @property {Array<Object>} reference_links - 参照情報
 */

import React from 'react';
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
  Chip,
  IconButton,
  Tooltip,
} from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { StyledTableCell } from '../../styles/tableStyles';
import GoalProgress from '../common/GoalProgress';
import { formatDate, formatNumber } from '../../utils/commonUtils';

/**
 * 目標一覧テーブルコンポーネント
 * @param {Object} props
 * @param {Array<Goal>} props.goals - 表示する目標一覧
 * @param {Function} props.onGoalClick - 目標クリック時のハンドラ
 * @param {Function} props.onEditGoal - 目標編集時のハンドラ
 * @param {Function} props.onDeleteGoal - 目標削除時のハンドラ
 */
const GoalsTable = ({ goals, onGoalClick, onEditGoal, onDeleteGoal }) => {
  // 参照情報を別タブで開く
  const handleReferenceClick = (e, links) => {
    e.stopPropagation();
    // 最初のリンクを開く
    if (links && links.length > 0 && links[0].url) {
      window.open(links[0].url, '_blank', 'noopener,noreferrer');
    }
  };

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 1200 }} size="small">
        <TableHead>
          <TableRow>
            <StyledTableCell>プロジェクト</StyledTableCell>
            <StyledTableCell>目標名</StyledTableCell>
            <StyledTableCell>概要</StyledTableCell>
            <StyledTableCell align="center">タスク</StyledTableCell>
            <StyledTableCell align="center">タスク進捗</StyledTableCell>
            <StyledTableCell align="center">KPI実績</StyledTableCell>
            <StyledTableCell align="center">KPI進捗</StyledTableCell>
            <StyledTableCell align="center">期限</StyledTableCell>
            <StyledTableCell align="center">参照情報</StyledTableCell>
            <StyledTableCell align="center">操作</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {goals.map((goal) => (
            <TableRow
              key={goal.id}
              sx={{
                '&:hover': { backgroundColor: 'action.hover' },
                cursor: 'pointer'
              }}
              onClick={() => onGoalClick(goal.id)}
            >
              <StyledTableCell>
                {goal.project ? (
                  <Chip
                    label={goal.project.name}
                    size="small"
                    color="primary"
                    variant="outlined"
                  />
                ) : (
                  <Typography variant="caption" color="text.secondary">
                    なし
                  </Typography>
                )}
              </StyledTableCell>
              <StyledTableCell>
                <Typography variant="body2" sx={{ fontWeight: 'medium' }}>
                  {goal.title}
                </Typography>
                {goal.parent_goal && (
                  <Typography variant="caption" color="text.secondary" display="block">
                    親目標: {goal.parent_goal.title}
                  </Typography>
                )}
              </StyledTableCell>
              <StyledTableCell>
                <Typography
                  variant="body2"
                  sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: 'vertical',
                    maxWidth: '300px'
                  }}
                >
                  {goal.description}
                </Typography>
              </StyledTableCell>
              <StyledTableCell align="center">
                <Typography variant="body2">
                  {goal.taskCount || 0}
                </Typography>
              </StyledTableCell>
              <StyledTableCell align="center">
                {goal.taskProgress}
              </StyledTableCell>
              <StyledTableCell align="center">
                {goal.target_value ? (
                  <Typography variant="body2">
                    {formatNumber(goal.totalActualValue || 0)} / {formatNumber(goal.target_value)} {goal.unit_name}
                  </Typography>
                ) : (
                  <Typography variant="caption" color="text.secondary">
                    未設定
                  </Typography>
                )}
              </StyledTableCell>
              <StyledTableCell align="center">
                <Box sx={{ width: 100, mx: 'auto' }}>
                  <GoalProgress goal={goal} height={4} showPercentage />
                </Box>
              </StyledTableCell>
              <StyledTableCell align="center">
                <Typography
                  variant="body2"
                  color={goal.deadline ? 'text.primary' : 'text.secondary'}
                >
                  {goal.deadline ? formatDate(goal.deadline) : '未設定'}
                </Typography>
              </StyledTableCell>
              <StyledTableCell align="center">
                {goal.reference_links && goal.reference_links.length > 0 ? (
                  <Tooltip 
                    title={
                      <Box>
                        {goal.reference_links.map((link, index) => (
                          <Box key={index} sx={{ mb: index !== goal.reference_links.length - 1 ? 1 : 0 }}>
                            <Typography variant="body2" component="div">
                              {link.title || 'リンク'}
                            </Typography>
                            <Typography 
                              variant="caption" 
                              component="div" 
                              sx={{ 
                                color: 'primary.light',
                                wordBreak: 'break-all'
                              }}
                            >
                              {link.url}
                            </Typography>
                          </Box>
                        ))}
                      </Box>
                    }
                  >
                    <Box 
                      sx={{ 
                        display: 'flex', 
                        alignItems: 'center', 
                        justifyContent: 'center',
                        gap: 0.5,
                        cursor: 'pointer',
                        '&:hover': {
                          opacity: 0.8
                        }
                      }}
                      onClick={(e) => handleReferenceClick(e, goal.reference_links)}
                    >
                      <AttachFileIcon fontSize="small" color="primary" />
                      <Typography variant="body2" component="span">
                        {goal.reference_links.length}
                      </Typography>
                    </Box>
                  </Tooltip>
                ) : (
                  <Typography variant="caption" color="text.secondary">
                    なし
                  </Typography>
                )}
              </StyledTableCell>
              <StyledTableCell align="center">
                <Box sx={{ display: 'flex', justifyContent: 'center', gap: 1 }}>
                  <Tooltip title="詳細">
                    <IconButton
                      size="small"
                      color="primary"
                      onClick={(e) => {
                        e.stopPropagation();
                        onGoalClick(goal.id);
                      }}
                    >
                      <OpenInNewIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="編集">
                    <IconButton
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        onEditGoal(goal);
                      }}
                    >
                      <EditIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="削除">
                    <IconButton
                      size="small"
                      color="error"
                      onClick={(e) => {
                        e.stopPropagation();
                        onDeleteGoal(goal);
                      }}
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </Box>
              </StyledTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default GoalsTable; 