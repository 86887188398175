/**
 * @file GoalSummaryStats.js
 * @description 目標の統計情報を表示するコンポーネント
 * 
 * 目標に関連する振り返りの総数、総振り返り時間、達成率、平均振り返り時間などの
 * サマリー情報を表示します。
 */

import React, { useEffect, useState } from 'react';
import {
  Box,
  Paper,
  Grid,
  Typography,
  useTheme,
  useMediaQuery,
  CircularProgress,
} from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import DescriptionIcon from '@mui/icons-material/Description';
import AvTimerIcon from '@mui/icons-material/AvTimer';
import { useTaskData } from '../../hooks/useTaskData';

/**
 * 目標統計情報コンポーネント
 * @param {Object} props
 * @param {Object} props.goal - 目標データ
 * @param {Array} props.reflections - 振り返りデータの配列
 * @returns {JSX.Element} 統計情報を表示するコンポーネント
 */
const GoalSummaryStats = ({ goal, reflections }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { tasks, loading: tasksLoading } = useTaskData(goal?.id);
  const [stats, setStats] = useState({
    totalReflections: 0,
    totalDuration: 0,
    achievementRate: 0,
    averageReflectionTime: 0
  });

  useEffect(() => {
    if (!goal || !reflections || tasksLoading) return;

    // 目標に紐づく振り返りのみをフィルタリング
    const filteredReflections = reflections.filter(ref => ref.goal_id === goal.id);

    // 統計データの計算
    const totalReflections = filteredReflections.length;
    const totalDuration = filteredReflections.reduce((acc, ref) => 
      acc + (ref.reflection_duration || 0), 0);
    
    // タスクの進捗率を計算
    const totalTasks = tasks.length;
    const completedTasks = tasks.filter(task => task.is_completed).length;
    const taskProgress = totalTasks > 0 
      ? Math.round((completedTasks / totalTasks) * 100)
      : 0;

    // 達成率の計算（タスクの進捗も考慮）
    let achievementRate = 0;
    if (goal.target_value && goal.target_value > 0) {
      // 目標の達成率とタスクの進捗率を組み合わせて計算
      const targetAchievement = Math.min(100, Math.round((goal.actual_value || 0) / goal.target_value * 100));
      achievementRate = totalTasks > 0
        ? Math.round((targetAchievement + taskProgress) / 2)
        : targetAchievement;
    } else if (totalTasks > 0) {
      // 目標値が設定されていない場合は、タスクの進捗率のみを使用
      achievementRate = taskProgress;
    }

    setStats({
      totalReflections,
      totalDuration,
      achievementRate,
      averageReflectionTime: totalReflections > 0
        ? Math.round(totalDuration / totalReflections)
        : 0
    });
  }, [goal, reflections, tasks, tasksLoading]);

  const StatItem = ({ icon, label, value, unit, showProgress }) => (
    <Box sx={{ 
      display: 'flex', 
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
      p: 2,
      height: '100%'
    }}>
      <Typography 
        variant="body2" 
        color="text.secondary"
        sx={{
          whiteSpace: 'nowrap',
          fontSize: '0.85rem',
          mb: 1
        }}
      >
        {label}
      </Typography>
      {showProgress ? (
        <Box sx={{ position: 'relative', display: 'inline-flex', mb: 1 }}>
          <CircularProgress
            variant="determinate"
            value={value}
            size={60}
            thickness={4}
            sx={{
              color: value >= 100 ? theme.palette.success.main : theme.palette.primary.main,
            }}
          />
          <Box
            sx={{
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography
              variant="caption"
              component="div"
              color="text.secondary"
              sx={{ fontSize: '1rem' }}
            >
              {`${value}%`}
            </Typography>
          </Box>
        </Box>
      ) : (
        <Typography 
          variant="h4" 
          sx={{ 
            fontWeight: 'bold',
            display: 'flex',
            alignItems: 'baseline',
            justifyContent: 'center',
            mb: 1
          }}
        >
          {value}
          {unit && (
            <Typography 
              variant="body2" 
              component="span" 
              sx={{ 
                ml: 0.5,
                fontSize: '0.9rem',
                color: 'text.secondary'
              }}
            >
              {unit}
            </Typography>
          )}
        </Typography>
      )}
      {icon}
    </Box>
  );

  if (tasksLoading) {
    return <Typography>読み込み中...</Typography>;
  }

  return (
    <Paper sx={{ p: 2, mb: 3 }}>
      <Grid container spacing={2}>
        <Grid item xs={6} sm={3}>
          <StatItem
            icon={<DescriptionIcon sx={{ fontSize: 32, color: 'primary.main' }} />}
            label="振り返り回数"
            value={stats.totalReflections}
          />
        </Grid>
        <Grid item xs={6} sm={3}>
          <StatItem
            icon={<AccessTimeIcon sx={{ fontSize: 32, color: 'primary.main' }} />}
            label="総振り返り時間"
            value={Math.round(stats.totalDuration / 60)}
            unit="時間"
          />
        </Grid>
        <Grid item xs={6} sm={3}>
          <StatItem
            icon={<TrendingUpIcon sx={{ fontSize: 32, color: 'primary.main' }} />}
            label="達成率"
            value={stats.achievementRate}
            showProgress={true}
          />
        </Grid>
        <Grid item xs={6} sm={3}>
          <StatItem
            icon={<AvTimerIcon sx={{ fontSize: 32, color: 'primary.main' }} />}
            label="平均振り返り時間"
            value={stats.averageReflectionTime}
            unit="分"
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default GoalSummaryStats; 