/**
 * @fileoverview タスクテーブルコンポーネント
 * @description タスク一覧を表示・編集するためのテーブルコンポーネント
 */

import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  IconButton,
  FormControl,
  Select,
  MenuItem,
  TextField,
  Checkbox,
  Box,
  Typography,
  Tooltip,
} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import ja from 'date-fns/locale/ja';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import ClearIcon from '@mui/icons-material/Clear';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { StyledTableCell } from '../../styles/tableStyles';
import ReflectionPage from '../../pages/ReflectionPage';

const TaskTable = ({
  tasks,
  projects,
  goals,
  page,
  rowsPerPage,
  selectedProject,
  selectedGoal,
  selectedPriority,
  selectedDueDate,
  handleToggleTask,
  handleTaskContentEdit,
  handleTaskProjectEdit,
  handleTaskGoalEdit,
  handleTaskPriorityEdit,
  handleTaskDueDateEdit,
  handleDeleteTask,
  handleFilterClick,
  setSelectedProject,
  setSelectedGoal,
  setSelectedPriority,
  setSelectedDueDate,
  setPage,
  setRowsPerPage,
  getPriorityColor,
}) => {
  const [editingTaskId, setEditingTaskId] = useState(null);
  const [editingContent, setEditingContent] = useState('');
  const [isReflectionOpen, setIsReflectionOpen] = useState(false);
  const [selectedTaskForReflection, setSelectedTaskForReflection] = useState(null);

  const handleStartEdit = (task) => {
    setEditingTaskId(task.id);
    setEditingContent(task.content);
  };

  const handleEndEdit = async (taskId) => {
    if (editingContent.trim() !== '') {
      await handleTaskContentEdit(taskId, editingContent);
    }
    setEditingTaskId(null);
    setEditingContent('');
  };

  const handleKeyPress = (e, taskId) => {
    if (e.nativeEvent.isComposing) {
      return;
    }

    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleEndEdit(taskId);
    } else if (e.key === 'Escape') {
      setEditingTaskId(null);
      setEditingContent('');
    }
  };

  // カーソルを文字列の最後に移動させる関数
  const moveCursorToEnd = (e) => {
    const length = e.target.value.length;
    e.target.setSelectionRange(length, length);
  };

  const handleTaskComplete = async (taskId, isCompleted) => {
    if (isCompleted) {
      const task = tasks.find(t => t.id === taskId);
      if (task && task.goal_id) {
        await handleToggleTask(taskId, isCompleted);
        const goal = goals.find(g => g.id === task.goal_id);
        if (goal) {
          setSelectedTaskForReflection({
            ...task,
            goal_title: goal.title,
            content: `- ${task.content}`,
            achievement: `- ${task.content}`
          });
          setIsReflectionOpen(true);
        }
      } else {
        await handleToggleTask(taskId, isCompleted);
      }
    } else {
      await handleToggleTask(taskId, isCompleted);
    }
  };

  // プロジェクト変更時の処理
  const handleProjectChange = async (taskId, projectId) => {
    // プロジェクトが変更されたら、関連する目標をリセット
    await handleTaskProjectEdit(taskId, projectId === '' ? null : projectId);
    await handleTaskGoalEdit(taskId, null);
  };

  // 目標変更時の処理
  const handleGoalChange = async (taskId, goalId, projectId) => {
    if (goalId === '') {
      // 目標が未選択の場合
      await handleTaskGoalEdit(taskId, null);
      return;
    }

    // 目標に関連するプロジェクトIDを取得
    const selectedGoal = goals.find(g => g.id === goalId);
    if (selectedGoal) {
      // プロジェクトIDが異なる場合は、プロジェクトも更新
      if (selectedGoal.project_id !== projectId) {
        await handleTaskProjectEdit(taskId, selectedGoal.project_id);
      }
      await handleTaskGoalEdit(taskId, goalId);
    }
  };

  // 参照情報を別タブで開く
  const handleReferenceClick = (e, links) => {
    e.stopPropagation();
    // 最初のリンクを開く
    if (links && links.length > 0 && links[0].url) {
      window.open(links[0].url, '_blank', 'noopener,noreferrer');
    }
  };

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell padding="checkbox" align="center" sx={{ width: '60px' }}>完了</StyledTableCell>
              <StyledTableCell sx={{ width: '35%' }}>タスク内容</StyledTableCell>
              <StyledTableCell sx={{ width: '15%' }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  プロジェクト
                  <IconButton
                    size="small"
                    onClick={(e) => handleFilterClick(e, 'project')}
                    sx={{ ml: 1 }}
                  >
                    <FilterListIcon fontSize="small" />
                  </IconButton>
                  {selectedProject !== 'all' && (
                    <IconButton
                      size="small"
                      onClick={() => setSelectedProject('all')}
                      sx={{ ml: 0.5 }}
                    >
                      <ClearIcon fontSize="small" />
                    </IconButton>
                  )}
                </Box>
              </StyledTableCell>
              <StyledTableCell sx={{ width: '15%' }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  目標
                  <IconButton
                    size="small"
                    onClick={(e) => handleFilterClick(e, 'goal')}
                    sx={{ ml: 1 }}
                  >
                    <FilterListIcon fontSize="small" />
                  </IconButton>
                  {selectedGoal !== 'all' && (
                    <IconButton
                      size="small"
                      onClick={() => setSelectedGoal('all')}
                      sx={{ ml: 0.5 }}
                    >
                      <ClearIcon fontSize="small" />
                    </IconButton>
                  )}
                </Box>
              </StyledTableCell>
              <StyledTableCell sx={{ width: '100px' }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  優先度
                  <IconButton
                    size="small"
                    onClick={(e) => handleFilterClick(e, 'priority')}
                    sx={{ ml: 1 }}
                  >
                    <FilterListIcon fontSize="small" />
                  </IconButton>
                  {selectedPriority !== 'all' && (
                    <IconButton
                      size="small"
                      onClick={() => setSelectedPriority('all')}
                      sx={{ ml: 0.5 }}
                    >
                      <ClearIcon fontSize="small" />
                    </IconButton>
                  )}
                </Box>
              </StyledTableCell>
              <StyledTableCell sx={{ width: '180px' }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  期限
                  <IconButton
                    size="small"
                    onClick={(e) => handleFilterClick(e, 'due_date')}
                    sx={{ ml: 1 }}
                  >
                    <FilterListIcon fontSize="small" />
                  </IconButton>
                  {selectedDueDate !== 'all' && (
                    <IconButton
                      size="small"
                      onClick={() => setSelectedDueDate('all')}
                      sx={{ ml: 0.5 }}
                    >
                      <ClearIcon fontSize="small" />
                    </IconButton>
                  )}
                </Box>
              </StyledTableCell>
              <StyledTableCell align="center" sx={{ width: '100px' }}>参照情報</StyledTableCell>
              <StyledTableCell sx={{ width: '60px' }}>操作</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tasks
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((task) => (
                <TableRow
                  key={task.id}
                  sx={{
                    backgroundColor: getPriorityColor(task.priority),
                    '&:hover': { backgroundColor: 'action.hover' },
                    textDecoration: task.is_completed ? 'line-through' : 'none',
                  }}
                >
                  <StyledTableCell padding="checkbox">
                    <Checkbox
                      checked={task.is_completed}
                      onChange={(e) => handleTaskComplete(task.id, e.target.checked)}
                      sx={{ 
                        '&.Mui-checked': {
                          color: 'success.main',
                        }
                      }}
                    />
                  </StyledTableCell>
                  <StyledTableCell
                    onClick={() => handleStartEdit(task)}
                    sx={{ cursor: 'pointer' }}
                  >
                    {editingTaskId === task.id ? (
                      <TextField
                        fullWidth
                        variant="standard"
                        value={editingContent}
                        onChange={(e) => setEditingContent(e.target.value)}
                        onBlur={() => handleEndEdit(task.id)}
                        onKeyDown={(e) => handleKeyPress(e, task.id)}
                        onFocus={moveCursorToEnd}
                        autoFocus
                        multiline
                        sx={{
                          '& .MuiInput-input': {
                            textDecoration: task.is_completed ? 'line-through' : 'none',
                          }
                        }}
                      />
                    ) : (
                      <Typography
                        variant="body2"
                        sx={{
                          textDecoration: task.is_completed ? 'line-through' : 'none',
                          whiteSpace: 'pre-wrap',
                          wordBreak: 'break-word'
                        }}
                      >
                        {task.content}
                      </Typography>
                    )}
                  </StyledTableCell>
                  <StyledTableCell>
                    <FormControl fullWidth size="small">
                      <Select
                        value={task.project_id || ''}
                        onChange={(e) => handleProjectChange(task.id, e.target.value)}
                        variant="standard"
                        displayEmpty
                      >
                        <MenuItem value="">未設定</MenuItem>
                        {projects.map((project) => (
                          <MenuItem key={project.id} value={project.id}>
                            {project.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </StyledTableCell>
                  <StyledTableCell>
                    <FormControl fullWidth size="small">
                      <Select
                        value={task.goal_id || ''}
                        onChange={(e) => handleGoalChange(task.id, e.target.value, task.project_id)}
                        variant="standard"
                        displayEmpty
                        disabled={!task.project_id}
                      >
                        <MenuItem value="">未設定</MenuItem>
                        {goals
                          .filter(goal => !task.project_id || goal.project_id === task.project_id)
                          .map((goal) => (
                            <MenuItem key={goal.id} value={goal.id}>
                              {goal.title}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </StyledTableCell>
                  <StyledTableCell>
                    <FormControl fullWidth size="small">
                      <Select
                        value={task.priority}
                        onChange={(e) => handleTaskPriorityEdit(task.id, e.target.value)}
                        variant="standard"
                      >
                        <MenuItem value="high">高</MenuItem>
                        <MenuItem value="medium">中</MenuItem>
                        <MenuItem value="low">低</MenuItem>
                      </Select>
                    </FormControl>
                  </StyledTableCell>
                  <StyledTableCell>
                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ja}>
                      <DateTimePicker
                        value={task.due_date ? new Date(task.due_date) : null}
                        onChange={(newValue) => handleTaskDueDateEdit(task.id, newValue)}
                        slotProps={{
                          textField: {
                            variant: 'standard',
                            size: 'small',
                            fullWidth: true,
                          }
                        }}
                      />
                    </LocalizationProvider>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {task.reference_links && task.reference_links.length > 0 ? (
                      <Tooltip 
                        title={
                          <Box>
                            {task.reference_links.map((link, index) => (
                              <Box key={index} sx={{ mb: index !== task.reference_links.length - 1 ? 1 : 0 }}>
                                <Typography variant="body2" component="div">
                                  {link.title || 'リンク'}
                                </Typography>
                                <Typography 
                                  variant="caption" 
                                  component="div" 
                                  sx={{ 
                                    color: 'primary.light',
                                    wordBreak: 'break-all'
                                  }}
                                >
                                  {link.url}
                                </Typography>
                              </Box>
                            ))}
                          </Box>
                        }
                      >
                        <Box 
                          sx={{ 
                            display: 'flex', 
                            alignItems: 'center', 
                            justifyContent: 'center',
                            gap: 0.5,
                            cursor: 'pointer',
                            '&:hover': {
                              opacity: 0.8
                            }
                          }}
                          onClick={(e) => handleReferenceClick(e, task.reference_links)}
                        >
                          <AttachFileIcon fontSize="small" color="primary" />
                          <Typography variant="body2" component="span">
                            {task.reference_links.length}
                          </Typography>
                        </Box>
                      </Tooltip>
                    ) : (
                      <Typography variant="caption" color="text.secondary">
                        なし
                      </Typography>
                    )}
                  </StyledTableCell>
                  <StyledTableCell>
                    <IconButton
                      size="small"
                      onClick={() => handleDeleteTask(task.id)}
                      color="error"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </StyledTableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        component="div"
        count={tasks.length}
        page={page}
        onPageChange={(e, newPage) => setPage(newPage)}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={(e) => {
          setRowsPerPage(parseInt(e.target.value, 10));
          setPage(0);
        }}
        labelRowsPerPage="表示件数"
      />

      {selectedTaskForReflection && (
        <ReflectionPage
          isOpen={isReflectionOpen}
          onClose={() => {
            setIsReflectionOpen(false);
            setSelectedTaskForReflection(null);
          }}
          goalId={selectedTaskForReflection.goal_id}
          defaultAchievement={selectedTaskForReflection.content}
          initialAchievement={selectedTaskForReflection.achievement}
        />
      )}
    </>
  );
};

export default TaskTable; 