/**
 * 日報管理ページコンポーネント
 * @module ReportsPage
 */

import React, { useState } from 'react';
import {
  Box,
  Typography,
  Container,
  Paper,
  ToggleButtonGroup,
  ToggleButton,
  TextField,
  InputAdornment,
  Grid,
  IconButton,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  ButtonBase,
  Zoom,
  Button,
  CircularProgress
} from '@mui/material';
import {
  Add as AddIcon,
  Search as SearchIcon,
  ViewList as ViewListIcon,
  ViewModule as ViewModuleIcon,
  Today as TodayIcon,
  Description as DescriptionIcon,
  AutoFixHigh as AutoFixHighIcon
} from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { format } from 'date-fns';

import { useReportData } from '../../hooks/useReportData';
import { useProjectData } from '../../hooks/useProjectData';
import { useGoalData } from '../../hooks/useGoalData';
import { useReflectionData } from '../../hooks/useReflectionData';
import { openaiService } from '../../services/openaiService';
import ReportTable from '../../components/reports/ReportTable';
import SlidingPage from '../../components/common/SlidingPage';
import ReportDetail from '../../components/reports/ReportDetail';
import { useMessage } from '../../hooks/useMessage';

/**
 * 日報管理ページコンポーネント
 * @returns {React.Element} 日報管理ページ
 */
export const ReportsPage = () => {
  const [viewMode, setViewMode] = useState('list');
  const [searchText, setSearchText] = useState('');
  const [selectedReport, setSelectedReport] = useState(null);
  const [isDetailOpen, setIsDetailOpen] = useState(false);
  const [dateRange, setDateRange] = useState([null, null]);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [reportToDelete, setReportToDelete] = useState(null);
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [isGeneratingSummary, setIsGeneratingSummary] = useState(false);

  const { showMessage, MessageSnackbar } = useMessage();

  const {
    reports,
    loading,
    error,
    createReport,
    updateReport,
    deleteReport,
    updateFilters,
    refreshReports,
  } = useReportData();

  const { projects } = useProjectData();
  const { goals } = useGoalData();

  const { reflections, fetchReflectionsByDateRange } = useReflectionData();

  const handleViewModeChange = (event, newMode) => {
    if (newMode !== null) {
      setViewMode(newMode);
    }
  };

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
    updateFilters({ searchText: event.target.value });
  };

  const handleDateRangeChange = (newValue, index) => {
    const newRange = [...dateRange];
    newRange[index] = newValue ? format(newValue, 'yyyy-MM-dd') : null;
    setDateRange(newRange);
    updateFilters({
      startDate: newRange[0],
      endDate: newRange[1],
    });
  };

  const handleCreateClick = () => {
    setSelectedDate(new Date());
    setCreateDialogOpen(true);
  };

  const handleCreateNormal = async () => {
    try {
      const targetDate = format(selectedDate, 'yyyy-MM-dd');
      const dailyReflections = await fetchReflectionsByDateRange(targetDate, targetDate);
      
      const newReport = await createReport({
        title: '',
        content: '',
        report_date: targetDate,
      });
      
      setSelectedReport({
        ...newReport,
        reflections: dailyReflections || []
      });
      setIsDetailOpen(true);
      setCreateDialogOpen(false);
      showMessage('新規日報を作成しました', 'success');
      await refreshReports();
    } catch (err) {
      console.error('Error creating report:', err);
      showMessage('日報の作成に失敗しました', 'error');
    }
  };

  const handleCreateWithAI = async () => {
    try {
      setIsGeneratingSummary(true);
      
      // 選択された日付の振り返りデータを取得
      const targetDate = format(selectedDate, 'yyyy-MM-dd');
      const dailyReflections = await fetchReflectionsByDateRange(targetDate, targetDate);
      
      if (!dailyReflections || dailyReflections.length === 0) {
        showMessage('選択された日付の振り返りデータがありません', 'warning');
        return;
      }

      // AI要約を生成
      const summary = await openaiService.generateSummary(dailyReflections, 'gpt-4');

      // プロジェクトと目標の抽出
      const projectIds = new Set();
      const goalIds = new Set();
      
      dailyReflections.forEach(reflection => {
        if (reflection.project_id) projectIds.add(reflection.project_id);
        if (reflection.goal_id) goalIds.add(reflection.goal_id);
      });

      // 新規レポートを作成
      const newReport = await createReport({
        title: `${format(selectedDate, 'yyyy/MM/dd')}の振り返り`,
        content: summary,
        report_date: targetDate,
        project_id: projectIds.size > 0 ? Array.from(projectIds)[0] : null,
        goal_ids: Array.from(goalIds),
        is_ai_generated: true,
        ai_summary: summary
      });

      setSelectedReport({
        ...newReport,
        reflections: dailyReflections || []
      });
      setIsDetailOpen(true);
      setCreateDialogOpen(false);
      showMessage('AI要約を使用して日報を作成しました', 'success');
      await refreshReports();
    } catch (err) {
      console.error('Failed to create report with AI:', err);
      showMessage('AI要約による日報作成に失敗しました', 'error');
    } finally {
      setIsGeneratingSummary(false);
    }
  };

  const handleEditClick = (report) => {
    setSelectedReport(report);
    setIsDetailOpen(true);
  };

  const handleDeleteClick = (report) => {
    setReportToDelete(report);
    setDeleteConfirmOpen(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      await deleteReport(reportToDelete.id);
      showMessage('日報を削除しました', 'success');
    } catch (err) {
      showMessage('日報の削除に失敗しました', 'error');
    } finally {
      setDeleteConfirmOpen(false);
      setReportToDelete(null);
    }
  };

  const handleDeleteCancel = () => {
    setDeleteConfirmOpen(false);
    setReportToDelete(null);
  };

  const handleDetailSubmit = async (updatedReport) => {
    try {
      const updated = await updateReport(selectedReport.id, updatedReport);
      // 更新されたレポートで状態を更新
      setSelectedReport(updated);
      // メッセージを表示
      showMessage('日報を更新しました', 'success');
      
      // レポート一覧を更新するために再フェッチ
      await refreshReports();
    } catch (err) {
      console.error('Failed to update report:', err);
      showMessage('日報の更新に失敗しました', 'error');
    }
  };

  const handleDetailClose = () => {
    setIsDetailOpen(false);
    setSelectedReport(null);
    refreshReports();
  };

  if (error) {
    return (
      <Container>
        <Typography color="error">エラーが発生しました: {error}</Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth="xl">
      <Box sx={{ py: 3 }}>
        {/* タイトルと追加ボタン */}
        <Stack direction="row" spacing={3} alignItems="center" sx={{ mb: 3 }}>
          <Typography variant="h4">日報管理</Typography>
          <Zoom in={true} style={{ transitionDelay: '100ms' }}>
            <ButtonBase
              onClick={handleCreateClick}
              sx={{
                borderRadius: '24px',
                overflow: 'hidden',
                position: 'relative',
                '&:hover': {
                  transform: 'scale(1.02)',
                  transition: 'transform 0.2s ease-in-out',
                },
              }}
            >
              <Box
                sx={{
                  background: 'linear-gradient(45deg, #3F51B5 30%, #2196F3 90%)',
                  color: 'white',
                  py: 1,
                  px: 2.5,
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                  boxShadow: '0 3px 5px 2px rgba(33, 150, 243, .3)',
                  '&:hover': {
                    background: 'linear-gradient(45deg, #303F9F 30%, #1976D2 90%)',
                  },
                }}
              >
                <AddIcon />
                <DescriptionIcon />
                <Typography variant="button">
                  新しい日報
                </Typography>
              </Box>
            </ButtonBase>
          </Zoom>
        </Stack>

        <Paper sx={{ p: 2, mb: 3 }}>
          <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' }}>
            <TextField
              placeholder="検索..."
              value={searchText}
              onChange={handleSearchChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              sx={{ flexGrow: 1 }}
            />

            <Box sx={{ display: 'flex', gap: 1 }}>
              <DatePicker
                label="開始日"
                value={dateRange[0] ? new Date(dateRange[0]) : null}
                onChange={(newValue) => handleDateRangeChange(newValue, 0)}
                renderInput={(params) => <TextField {...params} />}
              />
              <DatePicker
                label="終了日"
                value={dateRange[1] ? new Date(dateRange[1]) : null}
                onChange={(newValue) => handleDateRangeChange(newValue, 1)}
                renderInput={(params) => <TextField {...params} />}
              />
            </Box>

            <ToggleButtonGroup
              value={viewMode}
              exclusive
              onChange={handleViewModeChange}
            >
              <ToggleButton value="list">
                <ViewListIcon />
              </ToggleButton>
              <ToggleButton value="grid">
                <ViewModuleIcon />
              </ToggleButton>
              <ToggleButton value="calendar">
                <TodayIcon />
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
        </Paper>

        {loading ? (
          <Typography>読み込み中...</Typography>
        ) : (
          <ReportTable
            reports={reports}
            onEdit={handleEditClick}
            onDelete={handleDeleteClick}
            onView={handleEditClick}
          />
        )}
      </Box>

      <SlidingPage
        isOpen={isDetailOpen}
        onClose={() => {
          setIsDetailOpen(false);
          setSelectedReport(null);
          refreshReports();
        }}
      >
        {selectedReport && (
          <ReportDetail
            report={selectedReport}
            onEdit={handleDetailSubmit}
            reflections={selectedReport.reflections || []}
          />
        )}
      </SlidingPage>

      <Dialog
        open={deleteConfirmOpen}
        onClose={handleDeleteCancel}
      >
        <DialogTitle>
          日報の削除
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {reportToDelete?.title} を削除してもよろしいですか？
            この操作は取り消せません。
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel}>
            キャンセル
          </Button>
          <Button
            onClick={handleDeleteConfirm}
            color="error"
            variant="contained"
          >
            削除
          </Button>
        </DialogActions>
      </Dialog>

      {/* 新規作成ダイアログ */}
      <Dialog
        open={createDialogOpen}
        onClose={() => setCreateDialogOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>新規日報の作成</DialogTitle>
        <DialogContent>
          <Stack spacing={3} sx={{ mt: 2 }}>
            <DialogContentText>
              日報を作成する方法を選択してください。
            </DialogContentText>
            <DatePicker
              label="日付"
              value={selectedDate}
              onChange={setSelectedDate}
              slotProps={{
                textField: {
                  fullWidth: true,
                  required: true
                }
              }}
            />
            <Stack direction="row" spacing={2} justifyContent="center">
              <Button
                variant="outlined"
                onClick={handleCreateNormal}
                startIcon={<DescriptionIcon />}
              >
                通常作成
              </Button>
              <LoadingButton
                variant="contained"
                onClick={handleCreateWithAI}
                loading={isGeneratingSummary}
                loadingPosition="start"
                startIcon={<AutoFixHighIcon />}
                loadingIndicator={
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <CircularProgress size={16} sx={{ mr: 1 }} />
                    生成中...
                  </Box>
                }
              >
                {isGeneratingSummary ? '生成中...' : 'AI要約で作成'}
              </LoadingButton>
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setCreateDialogOpen(false)}>キャンセル</Button>
        </DialogActions>
      </Dialog>

      <MessageSnackbar />
    </Container>
  );
}; 