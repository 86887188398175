/**
 * @fileoverview 個々の振り返りカードを表示するコンポーネント
 * @module ReflectionCard
 */

import React, { useState } from 'react';
import { Typography, Card, CardContent, Button, Box, Chip, styled, Avatar, IconButton, Menu, ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { ReferenceLinks } from '../../utils/commonUtils';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import WorkIcon from '@mui/icons-material/Work';
import LinkIcon from '@mui/icons-material/Link';
import MarkdownRenderer from '../common/MarkdownRenderer';

/**
 * スタイル付きのカードコンポーネント
 * @type {import('@mui/material').StyledComponent}
 */
const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: theme.shadows[8],
  },
}));

/**
 * スタイル付きのアバターコンポーネント
 * @type {import('@mui/material').StyledComponent}
 */
const StyledAvatar = styled(Avatar)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  width: theme.spacing(5),
  height: theme.spacing(5),
}));

/**
 * 個々の振り返りカードを表示するコンポーネント
 * @param {Object} props - コンポーネントのプロパティ
 * @param {Object} props.reflection - 振り返りデータ
 * @param {Function} props.onEdit - 編集ハンドラー
 * @param {Function} props.onDelete - 削除ハンドラー
 * @param {boolean} props.isViewingOther - 他のユーザーの振り返りを表示しているかどうか
 * @param {string} props.projectName - プロジェクト名
 * @returns {JSX.Element} 振り返りカードのJSX
 */
const ReflectionCard = ({ 
  reflection, 
  onEdit, 
  onDelete, 
  isViewingOther = false,
  projectName
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isExpanded, setIsExpanded] = useState(false);

  const goalTitle = reflection.goals?.title || '目標なし';
  const goalUnit = reflection.goals?.units?.name || '';

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    onEdit(reflection);
    handleMenuClose();
  };

  const handleDelete = () => {
    onDelete(reflection);
    handleMenuClose();
  };

  const formatTime = (timeString) => {
    if (!timeString) return '';
    const date = new Date(timeString);
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  };

  const formatDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    const weekdays = ['日', '月', '火', '水', '木', '金', '土'];
    const weekday = weekdays[date.getDay()];
    return `${date.toLocaleDateString()} (${weekday})`;
  };

  const calculateDuration = (startTime, endTime) => {
    if (!startTime || !endTime) return '';
    const start = new Date(startTime);
    const end = new Date(endTime);
    const durationInMinutes = Math.round((end - start) / (1000 * 60));
    const hours = Math.floor(durationInMinutes / 60);
    const minutes = durationInMinutes % 60;
    return `${hours > 0 ? `${hours}時間` : ''}${minutes > 0 ? `${minutes}分` : ''}`;
  };

  const ContentBox = ({ title, content, icon }) => (
    <Box sx={{ mb: 2 }}>
      <Typography variant="subtitle2" sx={{ fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
        {icon} {title}:
      </Typography>
      <Box sx={{ 
        mt: 0.5, 
        maxHeight: isExpanded ? 'none' : '100px',
        overflow: 'hidden',
        position: 'relative'
      }}>
        <MarkdownRenderer>
          {content}
        </MarkdownRenderer>
        {!isExpanded && content.length > 150 && (
          <Box sx={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            height: '50px',
            background: 'linear-gradient(transparent, white)',
          }} />
        )}
      </Box>
    </Box>
  );

  return (
    <StyledCard elevation={2}>
      <CardContent>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <StyledAvatar>
              {goalTitle.charAt(0).toUpperCase()}
            </StyledAvatar>
            <Box sx={{ ml: 2 }}>
              <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                {goalTitle}
              </Typography>
              <Typography variant="caption" color="text.secondary" display="block">
                {formatDate(reflection.date)}
              </Typography>
              {projectName && (
                <Typography variant="caption" color="text.secondary" display="block">
                  プロジェクト: {projectName}
                </Typography>
              )}
            </Box>
          </Box>
          {!isViewingOther && (
            <>
              <IconButton size="small" onClick={handleMenuOpen}>
                <MoreVertIcon />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
              >
                <MenuItem onClick={handleEdit}>
                  <ListItemIcon>
                    <EditIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="編集" />
                </MenuItem>
                <MenuItem onClick={handleDelete}>
                  <ListItemIcon>
                    <DeleteIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="削除" />
                </MenuItem>
              </Menu>
            </>
          )}
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
          <AccessTimeIcon fontSize="small" sx={{ mr: 1, color: 'text.secondary' }} />
          <Typography variant="body2" color="text.secondary">
            {formatTime(reflection.start_time)} ~ {formatTime(reflection.end_time)}
            {reflection.start_time && reflection.end_time && (
              <span style={{ marginLeft: '8px' }}>
                ({calculateDuration(reflection.start_time, reflection.end_time)})
              </span>
            )}
          </Typography>
        </Box>

        <Box sx={{ mb: 2 }}>
          <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
            実績: {reflection.actual_value ? `${reflection.actual_value} ${goalUnit}` : '-'}
          </Typography>
        </Box>

        <ContentBox 
          title="達成したこと"
          content={reflection.achievement}
          icon={<EmojiEventsIcon fontSize="small" sx={{ mr: 1 }} />}
        />

        <ContentBox 
          title="学んだこと"
          content={reflection.learning}
          icon={<EmojiObjectsIcon fontSize="small" sx={{ mr: 1 }} />}
        />

        <ContentBox 
          title="次にやること・改善点"
          content={reflection.improvement}
          icon={<TrendingUpIcon fontSize="small" sx={{ mr: 1 }} />}
        />

        {reflection.artifacts && reflection.artifacts.length > 0 && (
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle2" sx={{ fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
              <WorkIcon fontSize="small" sx={{ mr: 1 }} /> 成果物:
            </Typography>
            <ReferenceLinks links={reflection.artifacts} />
          </Box>
        )}

        {reflection.reference_links && reflection.reference_links.length > 0 && (
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle2" sx={{ fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
              <LinkIcon fontSize="small" sx={{ mr: 1 }} /> 参考リンク:
            </Typography>
            <ReferenceLinks links={reflection.reference_links} />
          </Box>
        )}

        {(reflection.achievement.length > 150 ||
          reflection.learning.length > 150 ||
          reflection.improvement.length > 150) && (
          <Button 
            onClick={() => setIsExpanded(!isExpanded)}
            sx={{ mb: 2 }}
          >
            {isExpanded ? '閉じる' : 'さらに見る'}
          </Button>
        )}

        <Box sx={{ mt: 'auto' }}>
          {reflection.skills && reflection.skills.map((skill) => (
            <Chip key={skill.id} label={skill.name} size="small" sx={{ mr: 0.5, mb: 0.5 }} />
          ))}
        </Box>
      </CardContent>
    </StyledCard>
  );
};

export default ReflectionCard; 