/**
 * @fileoverview プロジェクトサービス
 * @description プロジェクトの取得、作成、更新、削除を行うサービス
 */

import { supabase } from '../utils/supabase';

export const projectService = {
  /**
   * ユーザーのプロジェクトを取得する
   * @param {string} userId - ユーザーID
   * @returns {Promise<Array>} プロジェクトの配列
   * @throws {Error} データ取得に失敗した場合
   */
  async fetchProjects(userId) {
    const { data, error } = await supabase
      .from('projects')
      .select(`
        id,
        created_at,
        updated_at,
        name,
        description,
        start_date,
        end_date,
        status,
        is_completed,
        complete_date,
        reference_links
      `)
      .eq('owner_id', userId)
      .order('created_at', { ascending: false });

    if (error) {
      console.error('プロジェクトの取得に失敗しました:', error);
      throw error;
    }

    // ステータスと完了状態の整合性を確保
    const normalizedData = data.map(project => ({
      ...project,
      status: project.is_completed ? '完了' : (project.status || '未着手'),
      is_completed: project.status === '完了' ? true : project.is_completed
    }));

    return normalizedData;
  },

  /**
   * 新しいプロジェクトを作成する
   * @param {Object} projectData - プロジェクトデータ
   * @returns {Promise<Object>} 作成されたプロジェクト
   * @throws {Error} 作成に失敗した場合
   */
  async createProject(projectData) {
    const newProject = {
      ...projectData,
      owner_id: projectData.owner_id,
      is_completed: projectData.status === '完了',
      complete_date: projectData.status === '完了' ? new Date().toISOString() : null,
      reference_links: projectData.reference_links || []
    };

    const { data, error } = await supabase
      .from('projects')
      .insert(newProject)
      .select();

    if (error) {
      console.error('プロジェクトの作成に失敗しました:', error);
      throw error;
    }

    return data[0];
  },

  /**
   * プロジェクトを更新する
   * @param {string} projectId - プロジェクトID
   * @param {Object} updatedProject - 更新するプロジェクトデータ
   * @returns {Promise<Object>} 更新されたプロジェクト
   * @throws {Error} 更新に失敗した場合
   */
  async updateProject(projectId, updatedProject) {
    // ステータスと完了状態の整合性を確保
    const projectToUpdate = {
      ...updatedProject,
      is_completed: updatedProject.status === '完了' ? true : updatedProject.is_completed,
      complete_date: updatedProject.status === '完了' && !updatedProject.complete_date ? 
        new Date().toISOString() : updatedProject.complete_date
    };

    const { data, error } = await supabase
      .from('projects')
      .update(projectToUpdate)
      .eq('id', projectId)
      .select();

    if (error) {
      console.error('プロジェクトの更新に失敗しました:', error);
      throw error;
    }

    return data[0];
  },

  /**
   * プロジェクトを削除する
   * @param {string} projectId - プロジェクトID
   * @throws {Error} 削除に失敗した場合
   */
  async deleteProject(projectId) {
    const { error } = await supabase
      .from('projects')
      .delete()
      .eq('id', projectId);

    if (error) {
      console.error('プロジェクトの削除に失敗しました:', error);
      throw error;
    }
  },

  /**
   * プロジェクトを完了する
   * @param {string} projectId - プロジェクトID
   * @param {string} completeDate - 完了日
   * @returns {Promise<Object>} 完了されたプロジェクト
   * @throws {Error} 完了に失敗した場合
   */
  async completeProject(projectId, completeDate) {
    const { data, error } = await supabase
      .from('projects')
      .update({
        is_completed: true,
        complete_date: completeDate,
        status: '完了'
      })
      .eq('id', projectId)
      .select();

    if (error) {
      console.error('プロジェクトの完了に失敗しました:', error);
      throw error;
    }

    return data[0];
  },

  /**
   * プロジェクトを元に戻す
   * @param {string} projectId - プロジェクトID
   * @returns {Promise<Object>} 元に戻されたプロジェクト
   * @throws {Error} 元に戻しに失敗した場合
   */
  async revertProject(projectId) {
    const { data, error } = await supabase
      .from('projects')
      .update({
        is_completed: false,
        complete_date: null,
        status: '進行中'
      })
      .eq('id', projectId)
      .select();

    if (error) {
      console.error('プロジェクトの復元に失敗しました:', error);
      throw error;
    }

    return data[0];
  }
};
