/**
 * メッセージ通知を管理するカスタムフック
 * @module useMessage
 */

import { useState } from 'react';
import MessageSnackbar from '../components/common/MessageSnackbar';

/**
 * メッセージ通知を管理するフック
 * @returns {Object} メッセージ状態と操作関数
 */
export const useMessage = () => {
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('info');
  const [open, setOpen] = useState(false);

  /**
   * メッセージを表示する
   * @param {string} text - 表示するメッセージ
   * @param {string} [type='info'] - メッセージの種類 ('success' | 'error' | 'warning' | 'info')
   */
  const showMessage = (text, type = 'info') => {
    setMessage(text);
    setSeverity(type);
    setOpen(true);
  };

  /**
   * メッセージを閉じる
   */
  const hideMessage = () => {
    setOpen(false);
  };

  /**
   * メッセージSnackbarコンポーネント
   * @returns {React.Element} メッセージSnackbar
   */
  const MessageSnackbarComponent = () => (
    <MessageSnackbar
      open={open}
      message={message}
      severity={severity}
      onClose={hideMessage}
    />
  );

  return {
    message,
    severity,
    open,
    showMessage,
    hideMessage,
    MessageSnackbar: MessageSnackbarComponent
  };
};

export default useMessage; 