/**
 * 日報一覧テーブルコンポーネント
 * @module ReportTable
 */

import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Tooltip,
  Checkbox,
  Collapse,
  Box,
  Typography,
  Chip,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
  Visibility as VisibilityIcon,
} from '@mui/icons-material';
import { format, parseISO, startOfDay, endOfDay } from 'date-fns';
import { ja } from 'date-fns/locale';
import { StyledTableCell } from '../../styles/tableStyles';
import MarkdownRenderer from '../common/MarkdownRenderer';
import SlidingPage from '../common/SlidingPage';
import ReportDetail from './ReportDetail';
import { useReflectionData } from '../../hooks/useReflectionData';

// 内容セルのスタイル
const StyledContentTableCell = styled(TableCell)(({ theme }) => ({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: '200px',
  height: '60px',
  padding: theme.spacing(1),
  '& > *': {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    lineHeight: '1.2em',
    maxHeight: '2.4em'
  },
  '&.expanded': {
    whiteSpace: 'normal',
    maxWidth: 'none'
  }
}));

/**
 * 日付を安全にフォーマットする
 * @param {string} dateString - 日付文字列
 * @returns {string} フォーマットされた日付
 */
const formatDate = (dateString) => {
  try {
    if (!dateString) return '-';
    return format(parseISO(dateString), 'yyyy/MM/dd (E)', { locale: ja });
  } catch (error) {
    console.error('Date formatting error:', error);
    return dateString || '-';
  }
};

/**
 * 日報テーブルの行コンポーネント
 */
const ReportRow = ({
  report,
  selected,
  onSelect,
  onEdit,
  onDelete,
  onView,
}) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <>
      <TableRow hover>
        <TableCell padding="checkbox">
          <Checkbox
            checked={selected}
            onChange={(e) => onSelect(report.id, e.target.checked)}
          />
        </TableCell>
        <TableCell>
          <IconButton size="small" onClick={() => setExpanded(!expanded)}>
            {expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <StyledTableCell>{formatDate(report.report_date)}</StyledTableCell>
        <StyledTableCell>{report.title}</StyledTableCell>
        <StyledTableCell>{report.project?.name || '-'}</StyledTableCell>
        <StyledTableCell>
          <Box sx={{ display: 'flex', gap: 0.5, flexWrap: 'wrap' }}>
            {report.goals?.map((goal) => (
              <Chip
                key={goal.id}
                label={goal.title}
                size="small"
                sx={{ maxWidth: '150px' }}
              />
            )) || '-'}
          </Box>
        </StyledTableCell>
        <StyledContentTableCell className={expanded ? 'expanded' : ''}>
          <MarkdownRenderer>
            {report.content}
          </MarkdownRenderer>
        </StyledContentTableCell>
        <TableCell>
          <IconButton size="small" onClick={() => onView?.(report)}>
            <VisibilityIcon />
          </IconButton>
          <IconButton size="small" onClick={() => onDelete?.(report)}>
            <DeleteIcon />
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 2 }}>
              <Typography variant="h6" gutterBottom component="div">
                内容
              </Typography>
              <MarkdownRenderer>
                {report.content}
              </MarkdownRenderer>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

/**
 * 日報一覧テーブルコンポーネント
 */
export const ReportTable = ({
  reports = [],
  selectedReports = [],
  onSelectionChange = () => {},
  onView,
  onEdit,
  onDelete,
}) => {
  const [selectedReport, setSelectedReport] = useState(null);
  const [isDetailOpen, setIsDetailOpen] = useState(false);

  // 選択された日報の日付に関連する振り返りデータを取得
  const { reflections } = useReflectionData(
    null,
    selectedReport?.project?.id,
    null,
    selectedReport?.report_date
  );

  // 選択された日付の振り返りデータをフィルタリング
  const filteredReflections = useMemo(() => {
    if (!selectedReport?.report_date) return [];
    
    const targetDate = parseISO(selectedReport.report_date);
    const dayStart = startOfDay(targetDate);
    const dayEnd = endOfDay(targetDate);

    return reflections.filter(reflection => {
      const reflectionDate = parseISO(reflection.date);
      return reflectionDate >= dayStart && reflectionDate <= dayEnd;
    });
  }, [reflections, selectedReport?.report_date]);

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      onSelectionChange(reports.map(r => r.id));
    } else {
      onSelectionChange([]);
    }
  };

  const handleSelectOne = (id, checked) => {
    const newSelected = checked
      ? [...selectedReports, id]
      : selectedReports.filter(rid => rid !== id);
    onSelectionChange(newSelected);
  };

  const handleView = (report) => {
    setSelectedReport(report);
    setIsDetailOpen(true);
  };

  const handleDetailClose = () => {
    setIsDetailOpen(false);
    setSelectedReport(null);
  };

  const handleDetailEdit = async (updatedReport) => {
    await onEdit(updatedReport);
    setIsDetailOpen(false);
    setSelectedReport(null);
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell padding="checkbox">
                <Checkbox
                  indeterminate={selectedReports.length > 0 && selectedReports.length < reports.length}
                  checked={reports.length > 0 && selectedReports.length === reports.length}
                  onChange={handleSelectAll}
                />
              </StyledTableCell>
              <StyledTableCell width={50} />
              <StyledTableCell>日付</StyledTableCell>
              <StyledTableCell>タイトル</StyledTableCell>
              <StyledTableCell>プロジェクト</StyledTableCell>
              <StyledTableCell>目標</StyledTableCell>
              <StyledTableCell>内容</StyledTableCell>
              <StyledTableCell>操作</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {reports.map((report) => (
              <ReportRow
                key={report.id}
                report={report}
                selected={selectedReports.includes(report.id)}
                onSelect={handleSelectOne}
                onEdit={onEdit}
                onDelete={onDelete}
                onView={() => handleView(report)}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <SlidingPage
        isOpen={isDetailOpen}
        onClose={handleDetailClose}
      >
        {selectedReport && (
          <ReportDetail
            report={selectedReport}
            onEdit={handleDetailEdit}
            reflections={filteredReflections}
          />
        )}
      </SlidingPage>
    </>
  );
};

ReportTable.propTypes = {
  reports: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      report_date: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      content: PropTypes.string,
      project: PropTypes.shape({
        name: PropTypes.string
      }),
      goals: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          title: PropTypes.string
        })
      )
    })
  ),
  selectedReports: PropTypes.arrayOf(PropTypes.string),
  onSelectionChange: PropTypes.func,
  onView: PropTypes.func,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func
};

export default ReportTable; 