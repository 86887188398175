/**
 * @file GoalsPage.js
 * @description 目標管理ページコンポーネント
 * 
 * プロジェクトごとの目標表示、ツリー/リスト/カンバン表示切り替え、
 * カンバンボードによる進行状態の確認機能を提供します。
 */

import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, useSearchParams, useLocation } from 'react-router-dom';
import {
  Box,
  Button,
  Container,
  Typography,
  ToggleButton,
  ToggleButtonGroup,
  Grid,
  Card,
  CardContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Stack,
  Fab,
  Zoom,
  Tooltip,
  ButtonBase,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import ViewListIcon from '@mui/icons-material/ViewList';
import FilterListIcon from '@mui/icons-material/FilterList';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import FlagIcon from '@mui/icons-material/Flag';
import ViewKanbanIcon from '@mui/icons-material/ViewKanban';
import { formatDate, formatNumber } from '../../utils/commonUtils';
import { StyledTableCell } from '../../styles/tableStyles';

import { useGoalData } from '../../hooks/useGoalData';
import { useProjectData } from '../../hooks/useProjectData';
import GoalTreeChart from '../../components/charts/GoalTreeChart';
import KanbanBoard from '../../components/common/KanbanBoard';
import GoalCard from '../../components/goals/GoalCard';
import SlidingPage from '../../components/common/SlidingPage';
import GoalForm from './GoalForm';
import GoalProgress from '../../components/common/GoalProgress';
import GoalDetailPage from '../GoalDetailPage';
import GoalsTable from '../../components/goals/GoalsTable';
import ProjectDetailPage from '../../components/projects/ProjectDetailPage';

const GoalsPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { goalId } = useParams();
  const [searchParams] = useSearchParams();
  const selectedGoalId = searchParams.get('goal');
  
  const [viewMode, setViewMode] = useState('tree');
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [selectedGoal, setSelectedGoal] = useState(null);
  const [selectedProjectId, setSelectedProjectId] = useState('all');
  const [isDetailOpen, setIsDetailOpen] = useState(false);
  const [selectedDetailGoalId, setSelectedDetailGoalId] = useState(null);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [goalToDelete, setGoalToDelete] = useState(null);
  const [isProjectDetailOpen, setIsProjectDetailOpen] = useState(false);
  const [selectedDetailProject, setSelectedDetailProject] = useState(null);

  const { 
    goals, 
    completedGoals, 
    handleStatusChange,
    handleDeleteGoal,
    completeGoal, 
    revertGoal,
    fetchGoals 
  } = useGoalData();
  const { projects } = useProjectData();

  // プロジェクトのフィルタリング
  const projectsToRender = React.useMemo(() => {
    if (selectedProjectId === 'all') {
      return projects;
    }
    return projects.filter(p => p.id === selectedProjectId);
  }, [projects, selectedProjectId]);

  // 目標をフィルタリング
  const filteredGoals = React.useMemo(() => {
    const addProjectInfo = (goal) => {
      if (!goal.project_id) return goal;
      const project = projects.find(p => p.id === goal.project_id);
      return { ...goal, project };
    };

    let filtered;
    if (selectedProjectId === 'all') {
      filtered = goals;
    } else if (selectedProjectId === 'none') {
      filtered = goals.filter(goal => !goal.project_id);
    } else {
      filtered = goals.filter(goal => goal.project_id === selectedProjectId);
    }
    return filtered.map(addProjectInfo);
  }, [goals, selectedProjectId, projects]);

  const filteredCompletedGoals = React.useMemo(() => {
    const addProjectInfo = (goal) => {
      if (!goal.project_id) return goal;
      const project = projects.find(p => p.id === goal.project_id);
      return { ...goal, project };
    };

    let filtered;
    if (selectedProjectId === 'all') {
      filtered = completedGoals;
    } else if (selectedProjectId === 'none') {
      filtered = completedGoals.filter(goal => !goal.project_id);
    } else {
      filtered = completedGoals.filter(goal => goal.project_id === selectedProjectId);
    }
    return filtered.map(addProjectInfo);
  }, [completedGoals, selectedProjectId, projects]);

  // 表示モード切り替え
  const handleViewModeChange = (event, newMode) => {
    if (newMode !== null) {
      setViewMode(newMode);
    }
  };

  // プロジェクトフィルター変更
  const handleProjectChange = (event) => {
    setSelectedProjectId(event.target.value);
  };

  // 新規目標作成
  const handleCreateGoal = async () => {
    setSelectedGoal({
      project_id: selectedProjectId !== 'all' ? selectedProjectId : null
    });
    setIsFormOpen(true);
  };

  // 子目標の作成
  const handleAddChildGoal = (parentInfo) => {
    setSelectedGoal({
      parent_goal_id: parentInfo.parent_goal_id,
      project_id: parentInfo.project_id,
      parentGoal: parentInfo.parentGoal
    });
    setIsFormOpen(true);
  };

  // 目標の編集
  const handleEditGoal = (goal) => {
    setSelectedGoal(goal);
    setIsFormOpen(true);
  };

  // フォームを閉じる
  const handleCloseForm = () => {
    setIsFormOpen(false);
    setSelectedGoal(null);
  };

  // カンバンボードでのドラッグ完了時の処理
  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const goalId = parseInt(result.draggableId);
    const newStatus = result.destination.droppableId;
    const goal = goals.find(g => g.id === goalId);
    
    if (goal && goal.status !== newStatus) {
      handleStatusChange(goal, newStatus);
    }
  };

  // 目標詳細ページへの遷移
  const handleGoalClick = (goalId) => {
    setSelectedDetailGoalId(goalId);
    setIsDetailOpen(true);
  };

  // 目標詳細ページを閉じる
  const handleCloseDetail = () => {
    setIsDetailOpen(false);
    setSelectedDetailGoalId(null);
  };

  // 目標削除確認ダイアログを開く
  const handleDeleteConfirmOpen = (goal) => {
    setGoalToDelete(goal);
    setDeleteConfirmOpen(true);
  };

  // 目標削除確認ダイアログを閉じる
  const handleDeleteConfirmClose = () => {
    setDeleteConfirmOpen(false);
    setGoalToDelete(null);
  };

  // 目標削除の実行
  const handleDeleteConfirmed = async () => {
    if (goalToDelete) {
      try {
        await handleDeleteGoal(goalToDelete);
        handleDeleteConfirmClose();
      } catch (error) {
        console.error('目標の削除に失敗しました:', error);
      }
    }
  };

  // プロジェクト詳細表示用の処理を追加
  const handleProjectClick = (project) => {
    setSelectedDetailProject(project);
    setIsProjectDetailOpen(true);
  };

  // プロジェクト詳細を閉じる処理を追加
  const handleCloseProjectDetail = () => {
    setIsProjectDetailOpen(false);
    setSelectedDetailProject(null);
  };

  return (
    <Container maxWidth="xl">
      {/* ヘッダー部分 */}
      <Box sx={{ mb: 4 }}>
        <Stack direction="column" spacing={2}>
          {/* 上部セクション：タイトルと追加ボタン */}
          <Stack 
            direction="row" 
            spacing={1.5}
            alignItems="center"
          >
            <Typography variant="h4" component="h1">
              目標管理
            </Typography>
            <Zoom in={true} style={{ transitionDelay: '100ms' }}>
              <ButtonBase
                onClick={handleCreateGoal}
                sx={{
                  borderRadius: '24px',
                  overflow: 'hidden',
                  position: 'relative',
                  ml: 1,
                  '&:hover': {
                    transform: 'scale(1.02)',
                    transition: 'transform 0.2s ease-in-out',
                  },
                }}
              >
                <Box
                  sx={{
                    background: 'linear-gradient(45deg, #FF9800 30%, #FFC107 90%)',
                    color: 'white',
                    py: 0.75,
                    px: 2,
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    boxShadow: '0 3px 5px 2px rgba(255, 193, 7, .3)',
                    '&:hover': {
                      background: 'linear-gradient(45deg, #F57C00 30%, #FFA000 90%)',
                    },
                  }}
                >
                  <AddIcon />
                  <FlagIcon />
                  <Typography variant="button">
                    新しい目標
                  </Typography>
                </Box>
              </ButtonBase>
            </Zoom>
          </Stack>

          {/* コントロールセクション：フィルターと表示モード切り替え */}
          <Stack 
            direction={{ xs: 'column', sm: 'row' }} 
            spacing={2} 
            alignItems={{ xs: 'stretch', sm: 'center' }}
            justifyContent="space-between"
          >
            <FormControl sx={{ minWidth: { xs: '100%', sm: 200 } }}>
              <InputLabel id="project-filter-label">
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <FilterListIcon sx={{ mr: 1 }} />
                  プロジェクト
                </Box>
              </InputLabel>
              <Select
                labelId="project-filter-label"
                value={selectedProjectId}
                onChange={handleProjectChange}
                label="プロジェクト"
              >
                <MenuItem value="all">すべて</MenuItem>
                <MenuItem value="none">プロジェクトなし</MenuItem>
                {projects.map(project => (
                  <MenuItem key={project.id} value={project.id}>
                    {project.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <ToggleButtonGroup
              value={viewMode}
              exclusive
              onChange={handleViewModeChange}
              aria-label="view mode"
              sx={{ 
                justifyContent: { xs: 'center', sm: 'flex-end' },
                width: { xs: '100%', sm: 'auto' }
              }}
            >
              <ToggleButton value="tree" aria-label="tree view">
                <AccountTreeIcon sx={{ mr: 1 }} />
                ツリー表示
              </ToggleButton>
              <ToggleButton value="list" aria-label="list view">
                <ViewListIcon sx={{ mr: 1 }} />
                リスト表示
              </ToggleButton>
              <ToggleButton value="kanban" aria-label="kanban view">
                <ViewKanbanIcon sx={{ mr: 1 }} />
                カンバン表示
              </ToggleButton>
            </ToggleButtonGroup>
          </Stack>
        </Stack>
      </Box>

      {/* 目標表示エリア */}
      <Box sx={{ mb: 4 }}>
        {viewMode === 'tree' && (
          <GoalTreeChart
            goals={filteredGoals}
            projects={projectsToRender}
            completedGoals={filteredCompletedGoals}
            onAddChildGoal={handleAddChildGoal}
            onGoalClick={handleGoalClick}
            onProjectClick={handleProjectClick}
          />
        )}
        {viewMode === 'list' && (
          <GoalsTable
            goals={[...filteredGoals, ...filteredCompletedGoals]}
            onGoalClick={handleGoalClick}
            onEditGoal={handleEditGoal}
            onDeleteGoal={handleDeleteConfirmOpen}
          />
        )}
        {viewMode === 'kanban' && (
          <KanbanBoard
            items={[...filteredGoals, ...filteredCompletedGoals]}
            onDragEnd={handleDragEnd}
            itemComponent={({ item }) => (
              <GoalCard
                goal={item}
                onEditClick={() => handleEditGoal(item)}
                onRemoveClick={() => handleDeleteConfirmOpen(item)}
                onCompleteClick={() => completeGoal(item)}
                onRevertClick={() => revertGoal(item)}
                onStatusChange={(newStatus) => handleStatusChange(item, newStatus)}
                isKanban={true}
                onGoalClick={() => handleGoalClick(item.id)}
              />
            )}
          />
        )}
      </Box>

      {/* 目標フォーム */}
      <SlidingPage isOpen={isFormOpen} onClose={handleCloseForm}>
        <GoalForm
          goal={selectedGoal}
          onClose={handleCloseForm}
          onSave={(savedGoal) => {
            fetchGoals();  // 目標一覧を更新
            handleCloseForm();  // フォームを閉じる
          }}
        />
      </SlidingPage>

      {/* 目標詳細スライディングページ */}
      <SlidingPage isOpen={isDetailOpen} onClose={handleCloseDetail}>
        {selectedDetailGoalId && (
          <GoalDetailPage
            goalId={selectedDetailGoalId}
            onClose={handleCloseDetail}
            onUpdate={fetchGoals}
          />
        )}
      </SlidingPage>

      {/* プロジェクト詳細スライディングページを追加 */}
      <SlidingPage isOpen={isProjectDetailOpen} onClose={handleCloseProjectDetail}>
        {selectedDetailProject && (
          <ProjectDetailPage
            project={selectedDetailProject}
            onClose={handleCloseProjectDetail}
          />
        )}
      </SlidingPage>

      {/* 削除確認ダイアログ */}
      <Dialog
        open={deleteConfirmOpen}
        onClose={handleDeleteConfirmClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          目標を削除しますか？
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {goalToDelete?.title}を削除します。この操作は取り消せません。
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteConfirmClose} color="primary">
            キャンセル
          </Button>
          <Button onClick={handleDeleteConfirmed} color="error" autoFocus>
            削除
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default GoalsPage; 