/**
 * @file AchievementsPage.js
 * @description 実績管理ページのコンポーネント
 * 
 * プロジェクトごとの実績の振り返りの管理や、目標達成度の可視化を行います。
 * ユーザーの成長を可視化することを目的としています。
 */

import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  Typography,
  Paper,
  ToggleButtonGroup,
  ToggleButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  useTheme,
  useMediaQuery,
  IconButton,
  Tooltip,
  ButtonBase,
  Stack,
  Zoom,
} from '@mui/material';
import ViewListIcon from '@mui/icons-material/ViewList';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import TimelineIcon from '@mui/icons-material/Timeline';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import AddIcon from '@mui/icons-material/Add';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { useProjectData } from '../../hooks/useProjectData';
import { useReflectionData } from '../../hooks/useReflectionData';
import { reflectionService } from '../../services/reflectionService';
import RecentReflections from '../../components/reflections/RecentReflections';
import ReflectionTimeSeriesChart from '../../components/charts/ReflectionTimeSeriesChart';
import ReflectionSkillPieChart from '../../components/charts/ReflectionSkillPieChart';
import ReflectionSummaryCard from '../../components/summary/ReflectionSummaryCard';
import AccumulatedProgressCard from '../../components/profile/AccumulatedProgressCard';
import ReflectionSummaryStats from '../../components/summary/ReflectionSummaryStats';
import ReflectionTable from '../../components/reflections/ReflectionTable';
import DailyActivityChart from '../../components/charts/DailyActivityChart';
import SlidingPage from '../../components/common/SlidingPage';
import ReflectionForm from '../../components/reflections/ReflectionForm';

/**
 * 実績管理ページコンポーネント
 * @returns {JSX.Element} 実績管理ページ
 */
const AchievementsPage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [expandedTable, setExpandedTable] = useState(false);
  const [selectedReflections, setSelectedReflections] = useState([]);
  const [editingReflection, setEditingReflection] = useState(null);
  const [isReflectionFormOpen, setIsReflectionFormOpen] = useState(false);
  const [refreshKey, setRefreshKey] = useState(0);

  // URLパラメータから初期値を取得
  const viewMode = searchParams.get('view') || 'card';
  const selectedProject = searchParams.get('project') || '';

  const { projects } = useProjectData();
  const { reflections, loading, error, handleEditReflection, handleDeleteReflection, fetchReflections } = useReflectionData(null, selectedProject);

  // 振り返りデータを活動データとして整形
  const formattedActivities = React.useMemo(() => {
    return reflections.map(reflection => ({
      ...reflection,
      type: 'reflection',
      goals: {
        ...reflection.goals,
        title: reflection.goals?.title || '目標なし'
      }
    }));
  }, [reflections]);

  const handleViewModeChange = (_, newMode) => {
    if (newMode !== null) {
      setSearchParams({ 
        ...Object.fromEntries(searchParams),
        view: newMode 
      });
    }
  };

  const handleProjectChange = (event) => {
    const newProject = event.target.value;
    setSearchParams({ 
      ...Object.fromEntries(searchParams),
      project: newProject 
    });
  };

  const handleExpandTable = () => {
    setExpandedTable(!expandedTable);
  };

  // 振り返り編集ハンドラー
  const handleReflectionEditClick = (reflection) => {
    setEditingReflection(reflection);
    setIsReflectionFormOpen(true);
  };

  // 振り返り保存ハンドラー
  const handleSaveReflection = async (reflectionData) => {
    try {
      if (editingReflection) {
        // 編集の場合
        await handleEditReflection(reflectionData);
      } else {
        // 新規作成の場合
        await reflectionService.createReflection(reflectionData);
      }
      setIsReflectionFormOpen(false);
      setEditingReflection(null);
      setRefreshKey(prev => prev + 1);
    } catch (error) {
      console.error('振り返りの保存に失敗しました:', error);
      alert('振り返りの保存中にエラーが発生しました。');
    }
  };

  // 振り返り削除ハンドラー
  const handleDeleteReflectionClick = async (reflection) => {
    try {
      await handleDeleteReflection({ id: reflection.id });
      setRefreshKey(prev => prev + 1);
    } catch (error) {
      console.error('振り返りの削除に失敗しました:', error);
      alert('振り返りの削除中にエラーが発生しました。');
    }
  };

  // 新規振り返り作成ハンドラー
  const handleCreateReflection = () => {
    setEditingReflection(null);
    setIsReflectionFormOpen(true);
  };

  const renderContent = () => {
    const AnalyticsLayout = () => (
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
            <ReflectionSummaryStats 
              reflections={reflections} 
              projectId={selectedProject === '' ? null : selectedProject} 
            />
            <Paper sx={{ p: 2 }}>
              <ReflectionSkillPieChart 
                projectID={selectedProject === '' ? null : selectedProject}
              />
            </Paper>
          </Box>
        </Grid>
        <Grid item xs={12} md={8}>
          <Paper sx={{ p: 2 }}>
            <ReflectionTimeSeriesChart 
              projectID={selectedProject === '' ? null : selectedProject}
            />
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <DailyActivityChart
            activities={formattedActivities}
            onActivityChange={() => {
              setRefreshKey(prev => prev + 1);
            }}
            selectedProject={selectedProject === '' ? null : selectedProject}
          />
        </Grid>
      </Grid>
    );

    switch (viewMode) {
      case 'analytics':
        return <AnalyticsLayout />;
      case 'list':
        return (
          <>
            <Paper sx={{ p: 2, mb: 3, overflow: 'hidden' }}>
              <Box sx={{ 
                display: 'flex', 
                justifyContent: 'space-between', 
                alignItems: 'center',
                mb: 1
              }}>
                <Typography variant="h6">振り返り一覧</Typography>
                <IconButton onClick={handleExpandTable}>
                  {expandedTable ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              </Box>
              <Box sx={{ 
                maxHeight: expandedTable ? 'none' : '400px',
                overflow: 'auto',
                transition: 'max-height 0.3s ease-in-out'
              }}>
                <ReflectionTable
                  reflections={reflections}
                  selectedReflections={selectedReflections}
                  onSelectionChange={setSelectedReflections}
                  projectId={selectedProject || null}
                  onView={handleReflectionEditClick}
                  onDelete={handleDeleteReflectionClick}
                />
              </Box>
            </Paper>
            <AnalyticsLayout />
          </>
        );
      case 'card':
      default:
        return (
          <>
            <Box sx={{ mb: 3 }}>
              <RecentReflections
                defaultProjectFilter={selectedProject}
                refreshTrigger={refreshKey}
                onEdit={handleReflectionEditClick}
              />
            </Box>
            <AnalyticsLayout />
          </>
        );
    }
  };

  if (loading) return <Typography>読み込み中...</Typography>;
  if (error) return <Typography color="error">エラー: {error}</Typography>;

  return (
    <Container maxWidth="xl">
      <Box sx={{ py: 3 }}>
        {/* タイトルと追加ボタン */}
        <Stack direction="row" spacing={3} alignItems="center" sx={{ mb: 3 }}>
          <Typography variant="h4">実績管理</Typography>
          <Zoom in={true} style={{ transitionDelay: '100ms' }}>
            <ButtonBase
              onClick={handleCreateReflection}
              sx={{
                borderRadius: '24px',
                overflow: 'hidden',
                position: 'relative',
                '&:hover': {
                  transform: 'scale(1.02)',
                  transition: 'transform 0.2s ease-in-out',
                },
              }}
            >
              <Box
                sx={{
                  background: 'linear-gradient(45deg, #9C27B0 30%, #E91E63 90%)',
                  color: 'white',
                  py: 1,
                  px: 2.5,
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                  boxShadow: '0 3px 5px 2px rgba(233, 30, 99, .3)',
                  '&:hover': {
                    background: 'linear-gradient(45deg, #7B1FA2 30%, #C2185B 90%)',
                  },
                }}
              >
                <AddIcon />
                <EmojiEventsIcon />
                <Typography variant="button">
                  新しい実績
                </Typography>
              </Box>
            </ButtonBase>
          </Zoom>
        </Stack>
        
        <Paper sx={{ p: 2, mb: 3 }}>
          <Box sx={{ 
            display: 'flex', 
            flexDirection: isMobile ? 'column' : 'row',
            gap: 2,
            alignItems: isMobile ? 'stretch' : 'center',
            mb: 2 
          }}>
            <FormControl sx={{ minWidth: 200 }}>
              <InputLabel>プロジェクト</InputLabel>
              <Select
                value={selectedProject}
                onChange={handleProjectChange}
                label="プロジェクト"
              >
                <MenuItem value="">全て</MenuItem>
                {projects.map((project) => (
                  <MenuItem key={project.id} value={project.id}>
                    {project.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <ToggleButtonGroup
              value={viewMode}
              exclusive
              onChange={handleViewModeChange}
              aria-label="表示モード"
              size={isMobile ? 'small' : 'medium'}
            >
              <Tooltip title="カード表示">
                <ToggleButton value="card" aria-label="カード表示">
                  <ViewModuleIcon />
                </ToggleButton>
              </Tooltip>
              <Tooltip title="リスト表示">
                <ToggleButton value="list" aria-label="リスト表示">
                  <ViewListIcon />
                </ToggleButton>
              </Tooltip>
              <Tooltip title="分析表示">
                <ToggleButton value="analytics" aria-label="分析表示">
                  <TimelineIcon />
                </ToggleButton>
              </Tooltip>
            </ToggleButtonGroup>
          </Box>
        </Paper>

        {renderContent()}

        {/* ReflectionFormを追加 */}
        <SlidingPage isOpen={isReflectionFormOpen} onClose={() => {
          setIsReflectionFormOpen(false);
          setEditingReflection(null);
        }}>
          <ReflectionForm
            goal={editingReflection?.goals}
            reflection={editingReflection}
            onClose={() => {
              setIsReflectionFormOpen(false);
              setEditingReflection(null);
            }}
            onSave={handleSaveReflection}
            isDialog={true}
          />
        </SlidingPage>
      </Box>
    </Container>
  );
};

export default AchievementsPage; 