/**
 * @fileoverview 振り返りの時系列データを表示するチャートコンポーネント
 * @module ReflectionTimeSeriesChart
 */

import React, { useState, useEffect } from 'react';
import { Typography, Box, ToggleButtonGroup, ToggleButton, Tabs, Tab } from '@mui/material';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, AreaChart, Area } from 'recharts';
import { useReflectionData } from '../../hooks/useReflectionData';
import { useGoalData } from '../../hooks/useGoalData';
import TimeRangeSelector from './common/TimeRangeSelector';
import DateRangePicker from './common/DateRangePicker';
import { getKey, formatXAxisLabel, getNextDate, getDefaultDateRange } from './common/chartUtils';

/**
 * カスタムTooltipコンポーネント
 * @param {Object} props - コンポーネントのプロパティ
 * @param {boolean} props.active - ツールチップがアクティブかどうか
 * @param {Array} props.payload - ツールチップのデータペイロード
 * @param {string} props.label - ツールチップのラベル
 * @returns {JSX.Element|null} カスタムTooltipコンポ-ネント
 */
const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div style={{ backgroundColor: 'white', padding: '10px', border: '1px solid #ccc' }}>
        <p>{`日付: ${label}`}</p>
        {payload.map((entry, index) => (
          entry.value > 0 ? (
            <p key={`item-${index}`} style={{ color: entry.color }}>
              {`${entry.name}: ${entry.value}`}
            </p>
          ) : null
        ))}
      </div>
    );
  }
  return null;
};

/**
 * 振り返りの時系列データを表示するチャートコンポーネント
 * @param {Object} props - コンポーネントのプロパティ
 * @param {string|null} props.goalID - 目標ID（オプション）
 * @param {string|null} props.projectID - プロジェクトID（オプション）
 * @param {string|null} props.targetUserId - 表示対象のユーザーID（オプション）
 * @param {boolean} props.isViewingOther - 他のユーザーのデータを表示しているかどうか
 * @returns {JSX.Element} ReflectionChartコンポーネント
 */
const ReflectionChart = ({ 
  goalID = null, 
  projectID = null,
  targetUserId = null,
  isViewingOther = false
}) => {
  const [timeRange, setTimeRange] = useState('daily');
  const [timeSeriesData, setTimeSeriesData] = useState([]);
  const [metricType, setMetricType] = useState('duration');
  const [viewType, setViewType] = useState('skill');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [activeSkills, setActiveSkills] = useState([]);

  const { reflections, skills } = useReflectionData(goalID, projectID, targetUserId);
  const { goals, completedGoals } = useGoalData(projectID, goalID, targetUserId);

  useEffect(() => {
    setDefaultDateRange();
  }, [timeRange]);

  useEffect(() => {
    processTimeSeriesData();
  }, [reflections, goals, completedGoals, timeRange, metricType, viewType, startDate, endDate]);

  /**
   * デフォルトの日付範囲を設定する
   */
  const setDefaultDateRange = () => {
    const { startDate, endDate } = getDefaultDateRange(timeRange);
    setStartDate(startDate);
    setEndDate(endDate);
  };

  /**
   * 時系列データを処理する
   */
  const processTimeSeriesData = () => {
    const groupedData = {};
    const activeSkills = new Set();

    const start = new Date(startDate);
    const end = new Date(endDate);
    if (isNaN(start.getTime()) || isNaN(end.getTime())) {
      console.error('無効な開始日または終了日:', startDate, endDate);
      return;
    }
    let currentDate = new Date(start);

    while (currentDate <= end) {
      const key = getKey(currentDate, timeRange);
      if (key) {
        groupedData[key] = initializeDataPoint(key, skills);
      }
      currentDate = getNextDate(currentDate, timeRange);
    }

    let hasNoSkillData = false;

    reflections.forEach(item => {
      const date = new Date(item.date);
      const key = getKey(date, timeRange);
      
      if (groupedData[key]) {
        groupedData[key].reflectionCount += 1;
        groupedData[key].duration += item.reflection_duration;

        if (!Array.isArray(item.skills) || item.skills.length === 0) {
          const value = metricType === 'count' ? 1 : item.reflection_duration;
          groupedData[key]['スキルなし'] += value;
          hasNoSkillData = true;
        } else {
          const skillCount = item.skills.length;
          item.skills.forEach(skill => {
            if (groupedData[key][skill.name] !== undefined) {
              const value = metricType === 'count' ? 1 : item.reflection_duration / skillCount;
              groupedData[key][skill.name] += value;
              if (value > 0) {
                activeSkills.add(skill.name);
              }
            }
          });
        }
      }
    });

    if (hasNoSkillData) {
      activeSkills.add('スキルなし');
    }

    goals.forEach(item => {
      const creationDate = new Date(item.created_at);
      const creationKey = getKey(creationDate, timeRange);
      
      if (groupedData[creationKey]) {
        groupedData[creationKey].goalCreationCount += 1;
      }
    });

    completedGoals.forEach(item => {
      const completionDate = new Date(item.complete_date);
      const completionKey = getKey(completionDate, timeRange);
      
      if (groupedData[completionKey]) {
        groupedData[completionKey].goalCompletionCount += 1;
      }
    });

    const sortedData = Object.entries(groupedData)
      .sort(([a], [b]) => new Date(a) - new Date(b))
      .map(([key, value]) => ({ date: key, ...value }));

    setTimeSeriesData(sortedData);
    setActiveSkills(Array.from(activeSkills));
  };

  const filterDataByDateRange = (data) => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    return Object.entries(data).filter(([key]) => {
      const date = parseDate(key, timeRange);
      return date >= start && date <= end;
    }).reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
  };

  const parseDate = (dateString, range) => {
    if (range === 'weekly') {
      const [year, week] = dateString.split('-W');
      return getDateOfISOWeek(parseInt(week), parseInt(year));
    } else if (range === 'monthly') {
      return new Date(dateString + '-01');
    }
    return new Date(dateString);
  };

  const getDateOfISOWeek = (week, year) => {
    const d = new Date(Date.UTC(year, 0, 1 + (week - 1) * 7));
    const dayNum = d.getUTCDay() || 7;
    d.setUTCDate(d.getUTCDate() + 4 - dayNum);
    return d;
  };

  const sortDataByDate = (data) => {
    return Object.entries(data)
      .sort(([a], [b]) => parseDate(a, timeRange) - parseDate(b, timeRange))
      .map(([_, value]) => value);
  };

  const initializeDataPoint = (key, skills) => ({
    date: key,
    reflectionCount: 0,
    duration: 0,
    goalCreationCount: 0,
    goalCompletionCount: 0,
    'スキルなし': 0,
    ...skills.reduce((acc, skill) => ({ ...acc, [skill.name]: 0 }), {})
  });

  /**
   * 時間範囲の変更を処理する
   * @param {Event} _ - イベントオブジェクト（未使用）
   * @param {string} newRange - 新しい時間範囲
   */
  const handleTimeRangeChange = (_, newRange) => {
    if (newRange !== null && newRange !== timeRange) {
      setTimeRange(newRange);
      setDefaultDateRange(); // 時間範囲が変更されたときにデフォルトの日付範囲を設定
    }
  };

  /**
   * 日付の変更を処理する
   * @param {Event} event - 日付変更イベント
   * @param {boolean} isStart - 開始日かどうか
   */
  const handleDateChange = (event, isStart) => {
    const { value } = event.target;
    if (isStart) {
      setStartDate(value);
    } else {
      setEndDate(value);
    }
  };

  /**
   * メトリクスタイプの変更を処理する
   * @param {Event} event - イベントオブジェクト
   * @param {string} newMetricType - 新しいメトリクスタイプ
   */
  const handleMetricTypeChange = (event, newMetricType) => {
    if (newMetricType !== null) {
      setMetricType(newMetricType);
    }
  };

  /**
   * 表示タイプの変更を処理する
   * @param {Event} event - イベントオブジェクト
   * @param {string} newViewType - 新しい表示タイプ
   */
  const handleViewTypeChange = (event, newViewType) => {
    if (newViewType !== null) {
      setViewType(newViewType);
    }
  };

  const getBarColor = (index) => {
    const colors = ['#3f51b5', '#4caf50', '#f44336', '#ff9800', '#2196f3', '#9c27b0', '#00bcd4', '#ffeb3b', '#795548', '#607d8b'];
    return colors[index % colors.length];
  };

  console.log('レンダリング時のデータ:', { timeSeriesData, skills, viewType, metricType });

  return (
    <Box sx={{ mt: 4, overflowX: 'auto' }}>
      <Box sx={{ minWidth: '800px' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            振り返り推移
          </Typography>
          <Tabs 
            value={metricType} 
            onChange={handleMetricTypeChange} 
            aria-label="メトリクス選択"
            sx={{ minHeight: 0 }}
          >
            <Tab label="回数" value="count" sx={{ minHeight: 0, py: 1 }} />
            <Tab label="時間" value="duration" sx={{ minHeight: 0, py: 1 }} />
          </Tabs>
        </Box>
        
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mb: 2 }}>
          <TimeRangeSelector
            timeRange={timeRange}
            onTimeRangeChange={handleTimeRangeChange}
          />
          
          <ToggleButtonGroup
            value={viewType}
            exclusive
            onChange={handleViewTypeChange}
            aria-label="表示タイプ"
            size="small"
            fullWidth
          >
            <ToggleButton value="total" aria-label="合計">
              合計
            </ToggleButton>
            <ToggleButton value="skill" aria-label="スキル別">
              スキル別
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>

        <DateRangePicker
          timeRange={timeRange}
          startDate={startDate}
          endDate={endDate}
          onDateChange={handleDateChange}
        />

        <Box sx={{ height: 400, mt: 2 }}>
          <ResponsiveContainer width="100%" height="100%">
            {metricType === 'count' ? (
              <BarChart
                data={timeSeriesData}
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis 
                  dataKey="date" 
                  tickFormatter={(date) => formatXAxisLabel(date, timeRange)}
                  domain={[startDate, endDate]}
                  type="category"
                />
                <YAxis />
                <Tooltip content={<CustomTooltip />} />
                <Legend />
                {viewType === 'total' ? (
                  <>
                    <Bar dataKey="goalCreationCount" fill="#3f51b5" name="目標作成数" />
                    <Bar dataKey="reflectionCount" fill="#4caf50" name="振り返り数" />
                    <Bar dataKey="goalCompletionCount" fill="#f44336" name="目標完了数" />
                  </>
                ) : (
                  activeSkills.map((skillName, index) => (
                    <Bar key={skillName} dataKey={skillName} stackId="a" fill={getBarColor(index)} name={skillName} />
                  ))
                )}
              </BarChart>
            ) : (
              <AreaChart
                data={timeSeriesData}
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis 
                  dataKey="date" 
                  tickFormatter={(date) => formatXAxisLabel(date, timeRange)}
                  domain={[startDate, endDate]}
                  type="category"
                />
                <YAxis />
                <Tooltip content={<CustomTooltip />} />
                <Legend />
                {viewType === 'total' ? (
                  <Area type="linear" dataKey="duration" stroke="#4caf50" fill="#4caf50" name="実施時間（分）" />
                ) : (
                  activeSkills.map((skillName, index) => (
                    <Area
                      key={skillName}
                      type="linear"
                      dataKey={skillName}
                      stackId="1"
                      stroke={getBarColor(index)}
                      fill={getBarColor(index)}
                      name={`${skillName}（分）`}
                    />
                  ))
                )}
              </AreaChart>
            )}
          </ResponsiveContainer>
        </Box>
        {viewType === 'skill' && metricType === 'duration' && (
          <Typography variant="caption" sx={{ mt: 1 }}>
            ※ スキル別の時間は、各振り返りの時間を登録されたスキル数で均等に分配しています。
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default ReflectionChart;
