/**
 * @fileoverview 目標フォームページ
 * @description 目標の作成・編集を行うフォームページコンポーネント
 */

import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  Grid,
  Autocomplete,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Paper,
  Tooltip,
  Chip,
  IconButton,
  Card,
  CardContent,
  Collapse
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { parseISO } from 'date-fns';
import ja from 'date-fns/locale/ja';
import LinkIcon from '@mui/icons-material/Link';
import DeleteIcon from '@mui/icons-material/Delete';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { handleAddLink, handleLinkChange, handleRemoveLink } from '../../utils/commonUtils';
import { goalService } from '../../services/goalService';
import { unitService } from '../../services/unitService';
import { useProjectData } from '../../hooks/useProjectData';
import { supabase } from '../../utils/supabase';
import { MDXEditor } from '@mdxeditor/editor';
import '@mdxeditor/editor/style.css';
import { getMDXEditorPlugins, getMDXEditorStyles } from '../../utils/mdxEditorConfig';

const GoalForm = ({ goal, onClose, onSave }) => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [deadline, setDeadline] = useState(null);
  const [targetValue, setTargetValue] = useState('');
  const [units, setUnits] = useState([]);
  const [selectedUnit, setSelectedUnit] = useState(null);
  const [customUnit, setCustomUnit] = useState('');
  const [referenceLinks, setReferenceLinks] = useState([{ url: '', title: '' }]);
  const [status, setStatus] = useState('not_started');
  const [completeDate, setCompleteDate] = useState(null);
  const [projectId, setProjectId] = useState('');
  const [parentGoalId, setParentGoalId] = useState('');
  const { projects } = useProjectData();
  const [activeProjects, setActiveProjects] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const mdxPlugins = getMDXEditorPlugins();
  const mdxStyles = getMDXEditorStyles();
  const [showParentGoalDetails, setShowParentGoalDetails] = useState(false);
  const [availableParentGoals, setAvailableParentGoals] = useState([]);

  useEffect(() => {
    fetchUnits();
    setActiveProjects(projects.filter(project => !project.is_completed));
    fetchAvailableParentGoals();
    if (goal) {
      // 編集時の初期値設定
      setTitle(goal.title || '');
      setDescription(goal.description || '');
      setDeadline(goal.deadline ? parseISO(goal.deadline) : null);
      setTargetValue(goal.target_value || '');
      setStatus(goal.status || 'not_started');
      setCompleteDate(goal.complete_date ? parseISO(goal.complete_date) : null);
      setProjectId(goal.project_id || '');
      setParentGoalId(goal.parent_goal_id || '');
      setReferenceLinks(goal.reference_links || [{ url: '', title: '' }]);
      
      if (goal.unit_id) {
        const currentUnit = units.find(unit => unit.id === goal.unit_id);
        setSelectedUnit(currentUnit || null);
      }

      // 親目標の詳細情報が渡された場合、それを表示
      if (goal.parentGoal) {
        setShowParentGoalDetails(true);
      }
    }
  }, [goal, projects]);

  // プロジェクトIDが変更されたときに親目標リストを更新
  useEffect(() => {
    if (projectId) {
      fetchAvailableParentGoals();
    }
  }, [projectId]);

  const fetchUnits = async () => {
    try {
      const userId = await getUserId();
      const data = await unitService.fetchUnits(userId);
      setUnits(data);
      if (goal && goal.unit_id) {
        const currentUnit = data.find(unit => unit.id === goal.unit_id);
        setSelectedUnit(currentUnit || null);
      }
    } catch (error) {
      console.error('単位の取得に失敗しました:', error);
    }
  };

  const getUserId = async () => {
    const { data: { user } } = await supabase.auth.getUser();
    if (!user) throw new Error('ユーザーが見つかりません');
    return user.id;
  };

  const handleUnitChange = (event, newValue) => {
    if (typeof newValue === 'string') {
      setCustomUnit(newValue);
      setSelectedUnit(null);
    } else if (newValue && newValue.inputValue) {
      setCustomUnit(newValue.inputValue);
      setSelectedUnit(null);
    } else {
      setSelectedUnit(newValue);
      setCustomUnit('');
    }
  };

  const validateForm = () => {
    const errors = {};
    if (!title.trim()) errors.title = '目標名は必須です';
    if (targetValue && isNaN(parseFloat(targetValue))) errors.targetValue = '有効な数値を入力してください';
    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    try {
      const userId = await getUserId();
      const utcDeadline = deadline 
        ? new Date(Date.UTC(deadline.getFullYear(), deadline.getMonth(), deadline.getDate()))
        : null;
      const utcCompleteDate = completeDate
        ? new Date(Date.UTC(completeDate.getFullYear(), completeDate.getMonth(), completeDate.getDate()))
        : null;

      let unitId = null;
      if (selectedUnit) {
        unitId = selectedUnit.id;
      } else if (customUnit) {
        const newUnit = await unitService.createUnit(customUnit, userId);
        unitId = newUnit.id;
      }

      // 数値の適切な変換
      const parsedTargetValue = targetValue 
        ? parseFloat(targetValue.toString().replace(/,/g, ''))
        : null;

      if (targetValue && isNaN(parsedTargetValue)) {
        throw new Error('目標数値が正しくありません');
      }

      // 参照リンクの整形（空のエントリを除外）
      const cleanedReferenceLinks = referenceLinks.filter(link => 
        link.url.trim() !== '' || link.title.trim() !== ''
      );

      const goalData = {
        title: title.trim(),
        description: description || '',
        deadline: utcDeadline ? utcDeadline.toISOString() : null,
        target_value: parsedTargetValue,
        unit_id: unitId,
        reference_links: cleanedReferenceLinks,
        status: status || 'not_started',
        complete_date: status === 'completed' ? utcCompleteDate?.toISOString() : null,
        is_completed: status === 'completed',
        project_id: projectId || null,
        parent_goal_id: parentGoalId || null,
        user_id: userId
      };

      console.log('送信するデータ:', goalData); // デバッグ用

      let savedGoal;
      if (goal?.id) {
        savedGoal = await goalService.updateGoal(goal.id, goalData);
      } else {
        savedGoal = await goalService.createGoal(goalData);
      }

      if (onSave) {
        onSave(savedGoal);
      }
    } catch (error) {
      console.error('目標の保存中にエラーが発生しました:', error);
      // エラーメッセージをユーザーに表示
      setFormErrors({
        submit: error.message || '目標の保存中にエラーが発生しました。入力内容を確認してください。'
      });
    }
  };

  const fetchAvailableParentGoals = async () => {
    try {
      const userId = await getUserId();
      const allGoals = await goalService.fetchGoals(userId, false);
      
      // プロジェクトに基づいて親目標をフィルタリング
      const availableGoals = allGoals.filter(g => {
        // 基本的なフィルタリング条件
        const basicConditions = (!goal || g.id !== goal.id) && !g.is_completed;
        
        // プロジェクトに基づくフィルタリング
        if (projectId) {
          // プロジェクトが選択されている場合、そのプロジェクトの目標のみを表示
          return basicConditions && g.project_id === projectId;
        } else {
          // プロジェクトが選択されていない場合、プロジェクトに属していない目標のみを表示
          return basicConditions && !g.project_id;
        }
      });
      
      setAvailableParentGoals(availableGoals);
    } catch (error) {
      console.error('親目標の取得に失敗しました:', error);
    }
  };

  // プロジェクト選択時に親目標リストを更新
  const handleProjectChange = (e) => {
    const newProjectId = e.target.value;
    setProjectId(newProjectId);
    // プロジェクトが変更されたら親目標をクリア
    setParentGoalId('');
  };

  // 親目標カードのレンダリング
  const renderParentGoalCard = () => {
    if (!parentGoalId) return null;
    const selectedParentGoal = availableParentGoals.find(g => g.id === parentGoalId);
    if (!selectedParentGoal) return null;

    return (
      <Card variant="outlined" sx={{ mt: 2, mb: 2 }}>
        <CardContent>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography variant="subtitle1" component="div">
              親目標: {selectedParentGoal.title}
            </Typography>
            <IconButton
              onClick={() => setShowParentGoalDetails(!showParentGoalDetails)}
              aria-expanded={showParentGoalDetails}
            >
              {showParentGoalDetails ? <ExpandMoreIcon /> : <ChevronRightIcon />}
            </IconButton>
          </Box>
          <Collapse in={showParentGoalDetails}>
            <Box sx={{ mt: 2 }}>
              <Typography variant="body2" color="text.secondary">
                {selectedParentGoal.description}
              </Typography>
              {selectedParentGoal.target_value && (
                <Chip
                  label={`目標値: ${selectedParentGoal.target_value} ${selectedParentGoal.unit_name || ''}`}
                  size="small"
                  sx={{ mt: 1 }}
                />
              )}
            </Box>
          </Collapse>
        </CardContent>
      </Card>
    );
  };

  return (
    <Box sx={{ 
      height: '100%', 
      display: 'flex', 
      flexDirection: 'column',
      position: 'relative'
    }}>
      <Box 
        component="form" 
        onSubmit={handleSubmit} 
        sx={{ 
          flexGrow: 1,
          overflow: 'auto',
          pb: 10 // ボタン分の余白を確保
        }}
      >
        <Box sx={{ p: 3 }}>
          <Typography variant="h5" sx={{ mb: 3 }}>
            {goal ? '目標の編集' : '新しい目標'}
          </Typography>

          {/* 基本情報セクション */}
          <Paper sx={{ p: 3, mb: 3 }}>
            <Typography variant="h6" gutterBottom sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              基本情報
              <Tooltip title="目標の基本的な情報を入力してください">
                <HelpOutlineIcon fontSize="small" color="action" />
              </Tooltip>
            </Typography>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>プロジェクト</InputLabel>
                  <Select
                    value={projectId}
                    onChange={handleProjectChange}
                    label="プロジェクト"
                  >
                    <MenuItem value=""><em>なし</em></MenuItem>
                    {activeProjects.map((project) => (
                      <MenuItem key={project.id} value={project.id}>
                        {project.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>親目標</InputLabel>
                  <Select
                    value={parentGoalId}
                    onChange={(e) => setParentGoalId(e.target.value)}
                    label="親目標"
                  >
                    <MenuItem value=""><em>なし</em></MenuItem>
                    {availableParentGoals.map((parentGoal) => (
                      <MenuItem key={parentGoal.id} value={parentGoal.id}>
                        {parentGoal.title}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              {renderParentGoalCard()}

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="目標名"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  error={!!formErrors.title}
                  helperText={formErrors.title}
                  required
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ja}>
                  <DatePicker
                    label="期限"
                    value={deadline}
                    onChange={(newValue) => setDeadline(newValue)}
                    format="yyyy/MM/dd"
                    slotProps={{ 
                      textField: { 
                        fullWidth: true,
                        error: !!formErrors.deadline,
                        helperText: formErrors.deadline
                      } 
                    }}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>状態</InputLabel>
                  <Select
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                    label="状態"
                  >
                    <MenuItem value="not_started">未着手</MenuItem>
                    <MenuItem value="in_progress">進行中</MenuItem>
                    <MenuItem value="completed">完了</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              {status === 'completed' && (
                <Grid item xs={12} sm={6}>
                  <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ja}>
                    <DatePicker
                      label="完了日"
                      value={completeDate}
                      onChange={(newValue) => setCompleteDate(newValue)}
                      format="yyyy/MM/dd"
                      slotProps={{ 
                        textField: { 
                          fullWidth: true
                        } 
                      }}
                    />
                  </LocalizationProvider>
                </Grid>
              )}
            </Grid>
          </Paper>

          {/* 目標詳細セクション */}
          <Paper sx={{ p: 3, mb: 3 }}>
            <Typography variant="h6" gutterBottom sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              目標詳細
              <Tooltip title="目標の詳細な内容と数値目標を設定してください">
                <HelpOutlineIcon fontSize="small" color="action" />
              </Tooltip>
            </Typography>

            <Box sx={mdxStyles}>
              <MDXEditor
                markdown={description}
                onChange={(value) => setDescription(value)}
                plugins={mdxPlugins}
                contentEditableClassName="prose max-w-full"
                style={mdxStyles}
              />
            </Box>

            <Grid container spacing={3} sx={{ mt: 1 }}>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="目標数値"
                  type="number"
                  value={targetValue}
                  onChange={(e) => setTargetValue(e.target.value)}
                  fullWidth
                  error={!!formErrors.targetValue}
                  helperText={formErrors.targetValue}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  options={units}
                  getOptionLabel={(option) => option.name}
                  value={selectedUnit}
                  onChange={handleUnitChange}
                  onInputChange={(event, newInputValue) => {
                    setCustomUnit(newInputValue);
                  }}
                  renderInput={(params) => (
                    <TextField 
                      {...params} 
                      label="単位" 
                      placeholder='選択または入力'
                      error={!!formErrors.unit}
                      helperText={formErrors.unit}
                    />
                  )}
                  freeSolo
                />
              </Grid>
            </Grid>
          </Paper>

          {/* 参考情報セクション */}
          <Paper sx={{ p: 3 }}>
            <Typography variant="h6" gutterBottom sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              参考情報
              <Tooltip title="目標達成に参考となる資料やWebサイトのURLを追加してください">
                <HelpOutlineIcon fontSize="small" color="action" />
              </Tooltip>
            </Typography>

            {referenceLinks.map((link, index) => (
              <Box key={index} display="flex" alignItems="center" mb={2}>
                <TextField
                  label="URL"
                  value={link.url}
                  onChange={(e) => handleLinkChange(index, 'url', e.target.value, referenceLinks, setReferenceLinks)}
                  fullWidth
                  sx={{ mr: 1 }}
                  error={!!formErrors[`link_${index}_url`]}
                  helperText={formErrors[`link_${index}_url`]}
                />
                <TextField
                  label="タイトル"
                  value={link.title}
                  onChange={(e) => handleLinkChange(index, 'title', e.target.value, referenceLinks, setReferenceLinks)}
                  fullWidth
                  sx={{ mr: 1 }}
                  error={!!formErrors[`link_${index}_title`]}
                  helperText={formErrors[`link_${index}_title`]}
                />
                <IconButton onClick={() => handleRemoveLink(index, referenceLinks, setReferenceLinks)}>
                  <DeleteIcon />
                </IconButton>
              </Box>
            ))}
            <Button
              startIcon={<LinkIcon />}
              onClick={() => handleAddLink(setReferenceLinks)}
              variant="outlined"
              fullWidth
            >
              リンクを追加
            </Button>
          </Paper>
        </Box>
      </Box>

      {/* 固定フッターボタン */}
      <Box sx={{ 
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        p: 2,
        backgroundColor: 'background.paper',
        borderTop: 1,
        borderColor: 'divider',
        display: 'flex',
        justifyContent: 'flex-end',
        gap: 1,
        zIndex: 1
      }}>
        <Button onClick={onClose}>
          キャンセル
        </Button>
        <Button 
          type="submit" 
          variant="contained" 
          color="primary"
          onClick={handleSubmit}
        >
          保存
        </Button>
      </Box>
    </Box>
  );
};

export default GoalForm; 