/**
 * @fileoverview タスク管理ページ
 * @description プロジェクトや目標ごとのタスクをテーブル形式で管理するページ
 */

import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Paper,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Button,
  Collapse,
  ClickAwayListener,
  ButtonBase,
  Zoom,
  IconButton,
} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import ja from 'date-fns/locale/ja';
import AddIcon from '@mui/icons-material/Add';
import AssignmentIcon from '@mui/icons-material/Assignment';
import TaskTableContainer from '../../components/tasks/TaskTableContainer';
import TaskProgress from '../../components/tasks/TaskProgress';
import { useTaskData } from '../../hooks/useTaskData';
import { useProjectData } from '../../hooks/useProjectData';
import { useGoalData } from '../../hooks/useGoalData';
import DeleteIcon from '@mui/icons-material/Delete';
import LinkIcon from '@mui/icons-material/Link';

const TasksPage = () => {
  const [selectedProject, setSelectedProject] = useState('all');
  const [selectedGoal, setSelectedGoal] = useState('all');
  const [showCompleted, setShowCompleted] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [newTask, setNewTask] = useState({
    content: '',
    priority: 'medium',
    dueDate: null,
    project_id: null,
    goal_id: null,
    reference_links: [{ url: '', title: '' }],
  });
  const [selectedPriority, setSelectedPriority] = useState('all');
  const [selectedDueDate, setSelectedDueDate] = useState('all');
  const [showProjectGoalForm, setShowProjectGoalForm] = useState(false);
  const formRef = useRef(null);

  const { projects } = useProjectData();
  const { goals } = useGoalData(selectedProject === 'all' ? null : selectedProject);
  const { tasks, handleAddTask, handleEditTask, handleToggleTask, handleDeleteTask } = useTaskData(
    selectedProject === 'all' ? null : selectedProject,
    selectedGoal === 'all' ? null : selectedGoal
  );

  useEffect(() => {
    setNewTask(prev => ({
      ...prev,
      project_id: selectedProject === 'all' ? null : selectedProject,
      goal_id: selectedGoal === 'all' ? null : selectedGoal,
    }));
  }, [selectedProject, selectedGoal]);

  const handleFormFocus = () => {
    setShowProjectGoalForm(true);
  };

  const handleFormClickAway = () => {
    // フォーカスを外してもフォームは閉じない
    // if (!newTask.content.trim()) {
    //   setShowProjectGoalForm(false);
    // }
  };

  const resetForm = () => {
    setNewTask({
      content: '',
      priority: 'medium',
      dueDate: null,
      project_id: null,
      goal_id: null,
      reference_links: [{ url: '', title: '' }],
    });
    setShowProjectGoalForm(false);
  };

  const handleAddNewTask = async (e) => {
    e.preventDefault();
    if (newTask.content.trim()) {
      // 空の参照情報を除外
      const filteredLinks = newTask.reference_links.filter(link => link.url && link.title);
      await handleAddTask(
        newTask.content,
        newTask.priority,
        newTask.dueDate,
        newTask.project_id === 'none' ? null : newTask.project_id,
        newTask.goal_id === 'none' ? null : newTask.goal_id,
        filteredLinks
      );
      resetForm();
    }
  };

  const handleTaskContentEdit = async (taskId, content) => {
    await handleEditTask(taskId, { content });
  };

  const handleTaskProjectEdit = async (taskId, projectId) => {
    try {
      await handleEditTask(taskId, { 
        project_id: projectId || null,
        // プロジェクトが変更された場合は目標をクリア
        goal_id: null
      });
    } catch (error) {
      console.error('プロジェクトの更新に失敗しました:', error);
    }
  };

  const handleTaskGoalEdit = async (taskId, goalId) => {
    try {
      if (goalId) {
        // 目標が選択された場合、その目標のプロジェクトIDも設定
        const selectedGoal = goals.find(g => g.id === goalId);
        if (selectedGoal) {
          await handleEditTask(taskId, {
            goal_id: goalId,
            project_id: selectedGoal.project_id
          });
          return;
        }
      }
      // 目標が未選択の場合は単純に目標IDをnullに
      await handleEditTask(taskId, { goal_id: goalId || null });
    } catch (error) {
      console.error('目標の更新に失敗しました:', error);
    }
  };

  const handleTaskPriorityEdit = async (taskId, priority) => {
    await handleEditTask(taskId, { priority });
  };

  const handleTaskDueDateEdit = async (taskId, dueDate) => {
    await handleEditTask(taskId, { due_date: dueDate });
  };

  const handleProjectChange = (projectId) => {
    setSelectedProject(projectId);
    setSelectedGoal('all');
    setPage(0);
  };

  const handleProjectSelect = (projectId) => {
    setNewTask(prev => ({
      ...prev,
      project_id: projectId === 'none' ? null : projectId,
      goal_id: null,
    }));
  };

  const handleGoalSelect = (goalId) => {
    setNewTask(prev => ({
      ...prev,
      goal_id: goalId === 'none' ? null : goalId,
    }));
  };

  const handleAddLink = () => {
    setNewTask(prev => ({
      ...prev,
      reference_links: [...prev.reference_links, { url: '', title: '' }]
    }));
  };

  const handleRemoveLink = (index) => {
    setNewTask(prev => ({
      ...prev,
      reference_links: prev.reference_links.filter((_, i) => i !== index)
    }));
  };

  const handleLinkChange = (index, field, value) => {
    setNewTask(prev => ({
      ...prev,
      reference_links: prev.reference_links.map((link, i) => 
        i === index ? { ...link, [field]: value } : link
      )
    }));
  };

  const filteredTasks = tasks.filter(task => {
    const matchesProject = selectedProject === 'all' 
      ? true 
      : task.project_id === selectedProject;
    const matchesGoal = selectedGoal === 'all' 
      ? true 
      : task.goal_id === selectedGoal;
    const matchesCompletion = showCompleted || !task.is_completed;
    const matchesPriority = selectedPriority === 'all' || task.priority === selectedPriority;
    const matchesDueDate = selectedDueDate === 'all' || (() => {
      switch (selectedDueDate) {
        case 'overdue':
          return task.due_date && new Date(task.due_date) < new Date();
        case 'today':
          const today = new Date();
          const taskDate = task.due_date ? new Date(task.due_date) : null;
          return taskDate && 
            taskDate.getDate() === today.getDate() &&
            taskDate.getMonth() === today.getMonth() &&
            taskDate.getFullYear() === today.getFullYear();
        case 'thisWeek':
          const now = new Date();
          const weekEnd = new Date(now);
          weekEnd.setDate(now.getDate() + 7);
          return task.due_date && new Date(task.due_date) <= weekEnd;
        case 'noDueDate':
          return !task.due_date;
        default:
          return true;
      }
    })();
    return matchesProject && matchesGoal && matchesCompletion && matchesPriority && matchesDueDate;
  });

  const getPriorityColor = (priority) => {
    switch (priority) {
      case 'high':
        return '#ffebee';
      case 'medium':
        return '#fff3e0';
      case 'low':
        return '#f1f8e9';
      default:
        return 'transparent';
    }
  };

  const getPriorityText = (priority) => {
    switch (priority) {
      case 'high':
        return '高';
      case 'medium':
        return '中';
      case 'low':
        return '低';
      default:
        return '中';
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h5" sx={{ mb: 3 }}>
        タスク管理
      </Typography>

      <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
        <Box sx={{ mb: 4 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
            <FormControl size="small" sx={{ minWidth: 200 }}>
              <InputLabel>プロジェクト</InputLabel>
              <Select
                value={selectedProject}
                label="プロジェクト"
                onChange={(e) => handleProjectChange(e.target.value)}
              >
                <MenuItem value="all">すべてのプロジェクト</MenuItem>
                {projects.map((project) => (
                  <MenuItem key={project.id} value={project.id}>
                    {project.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl 
              size="small" 
              sx={{ minWidth: 200 }}
              disabled={selectedProject === 'all'}
            >
              <InputLabel>目標</InputLabel>
              <Select
                value={selectedGoal}
                label="目標"
                onChange={(e) => setSelectedGoal(e.target.value)}
              >
                <MenuItem value="all">すべての目標</MenuItem>
                {goals.map((goal) => (
                  <MenuItem key={goal.id} value={goal.id}>
                    {goal.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TaskProgress 
              tasks={selectedProject === 'all' ? tasks : tasks.filter(task => task.project_id === selectedProject)} 
            />
          </Box>
        </Box>

        <Box component="form" onSubmit={handleAddNewTask} sx={{ mb: 3 }} ref={formRef}>
          <Typography variant="h6" sx={{ mb: 2 }}>
            新規タスク
          </Typography>
          <ClickAwayListener onClickAway={handleFormClickAway}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <Box sx={{ display: 'flex', gap: 2, alignItems: 'flex-start' }}>
                <Box sx={{ flex: 1 }}>
                  <TextField
                    size="small"
                    value={newTask.content}
                    onChange={(e) => setNewTask({ ...newTask, content: e.target.value })}
                    onFocus={handleFormFocus}
                    placeholder="タスクを入力"
                    fullWidth
                  />
                  <Collapse in={showProjectGoalForm}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
                      <Box sx={{ display: 'flex', gap: 2 }}>
                        <FormControl size="small" sx={{ minWidth: 200 }}>
                          <InputLabel>プロジェクト（任意）</InputLabel>
                          <Select
                            value={newTask.project_id || 'none'}
                            label="プロジェクト（任意）"
                            onChange={(e) => handleProjectSelect(e.target.value)}
                          >
                            <MenuItem value="none">未設定</MenuItem>
                            {projects.map((project) => (
                              <MenuItem key={project.id} value={project.id}>
                                {project.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <FormControl 
                          size="small" 
                          sx={{ minWidth: 200 }}
                          disabled={!newTask.project_id}
                        >
                          <InputLabel>目標（任意）</InputLabel>
                          <Select
                            value={newTask.goal_id || 'none'}
                            label="目標（任意）"
                            onChange={(e) => handleGoalSelect(e.target.value)}
                          >
                            <MenuItem value="none">未設定</MenuItem>
                            {goals
                              .filter(goal => !newTask.project_id || goal.project_id === newTask.project_id)
                              .map((goal) => (
                                <MenuItem key={goal.id} value={goal.id}>
                                  {goal.title}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </Box>
                      
                      <Box sx={{ mt: 2 }}>
                        <Typography variant="subtitle2" gutterBottom>
                          参照情報
                        </Typography>
                        {newTask.reference_links.map((link, index) => (
                          <Box key={index} display="flex" alignItems="center" mb={2}>
                            <TextField
                              label="URL"
                              value={link.url}
                              onChange={(e) => handleLinkChange(index, 'url', e.target.value)}
                              size="small"
                              fullWidth
                              sx={{ mr: 1 }}
                            />
                            <TextField
                              label="タイトル"
                              value={link.title}
                              onChange={(e) => handleLinkChange(index, 'title', e.target.value)}
                              size="small"
                              fullWidth
                              sx={{ mr: 1 }}
                            />
                            <IconButton onClick={() => handleRemoveLink(index)}>
                              <DeleteIcon />
                            </IconButton>
                          </Box>
                        ))}
                        <Button
                          startIcon={<LinkIcon />}
                          onClick={handleAddLink}
                          variant="outlined"
                          size="small"
                          fullWidth
                        >
                          参照リンクを追加
                        </Button>
                      </Box>
                    </Box>
                  </Collapse>
                </Box>
                <FormControl size="small" sx={{ minWidth: 120 }}>
                  <InputLabel>優先度</InputLabel>
                  <Select
                    value={newTask.priority}
                    label="優先度"
                    onChange={(e) => setNewTask({ ...newTask, priority: e.target.value })}
                  >
                    <MenuItem value="high">高</MenuItem>
                    <MenuItem value="medium">中</MenuItem>
                    <MenuItem value="low">低</MenuItem>
                  </Select>
                </FormControl>
                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ja}>
                  <DateTimePicker
                    label="期限"
                    value={newTask.dueDate}
                    onChange={(newValue) => setNewTask({ ...newTask, dueDate: newValue })}
                    slotProps={{ textField: { size: 'small' } }}
                  />
                </LocalizationProvider>
                <Zoom in={true} style={{ transitionDelay: '100ms' }}>
                  <ButtonBase
                    type="submit"
                    sx={{
                      borderRadius: '24px',
                      overflow: 'hidden',
                      position: 'relative',
                      ml: 1,
                      '&:hover': {
                        transform: 'scale(1.02)',
                        transition: 'transform 0.2s ease-in-out',
                      },
                    }}
                  >
                    <Box
                      sx={{
                        background: 'linear-gradient(45deg, #4CAF50 30%, #8BC34A 90%)',
                        color: 'white',
                        py: 0.75,
                        px: 2,
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                        boxShadow: '0 3px 5px 2px rgba(139, 195, 74, .3)',
                        '&:hover': {
                          background: 'linear-gradient(45deg, #388E3C 30%, #689F38 90%)',
                        },
                      }}
                    >
                      <AddIcon />
                      <AssignmentIcon />
                      <Typography variant="button">
                        追加
                      </Typography>
                    </Box>
                  </ButtonBase>
                </Zoom>
              </Box>
            </Box>
          </ClickAwayListener>
        </Box>

        <TaskTableContainer
          tasks={filteredTasks}
          projects={projects}
          goals={goals}
          showCompleted={showCompleted}
          setShowCompleted={setShowCompleted}
          tableProps={{
            page,
            rowsPerPage,
            selectedProject,
            selectedGoal,
            selectedPriority,
            selectedDueDate,
            handleToggleTask,
            handleTaskContentEdit,
            handleTaskProjectEdit,
            handleTaskGoalEdit,
            handleTaskPriorityEdit,
            handleTaskDueDateEdit,
            handleDeleteTask,
            setSelectedProject,
            setSelectedGoal,
            setSelectedPriority,
            setSelectedDueDate,
            setPage,
            setRowsPerPage,
            getPriorityColor,
            getPriorityText,
          }}
        />
      </Paper>
    </Box>
  );
};

export default TasksPage; 