import React, { useState } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Button,
  Box,
  Chip,
  IconButton,
  Collapse,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Divider,
} from '@mui/material';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  CheckCircle as CheckCircleIcon,
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
  Flag as FlagIcon,
  Task as TaskIcon,
} from '@mui/icons-material';
import { format } from 'date-fns';
import { ja } from 'date-fns/locale';
import { ReferenceLinks } from '../../utils/commonUtils';
import MarkdownRenderer from '../common/MarkdownRenderer';

/**
 * プロジェクトの詳細情報を表示するカードコンポーネント
 * 
 * @component
 * @param {Object} props - コンポーネントのプロパティ
 * @param {Object} props.project - プロジェクトの情報
 * @param {Array} props.relatedGoals - 関連する目標の配列
 * @param {Array} props.relatedTasks - 関連するタスクの配列
 * @param {function} props.onEdit - 編集時のコールバック関数
 * @param {function} props.onDelete - 削除時のコールバック関数
 * @param {function} props.onComplete - 完了時のコールバック関数
 */
const ProjectCard = ({
  project,
  relatedGoals = [],
  relatedTasks = [],
  onEdit,
  onDelete,
  onComplete,
}) => {
  const [showDetails, setShowDetails] = useState(false);

  const getStatusColor = (status) => {
    switch (status) {
      case '未着手':
        return '#9e9e9e';
      case '進行中':
        return '#2196f3';
      case '完了':
        return '#4caf50';
      default:
        return '#9e9e9e';
    }
  };

  return (
    <Card>
      <CardContent>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', mb: 2 }}>
          <Typography variant="h6" component="div">
            {project.name}
          </Typography>
          <Box>
            <IconButton size="small" onClick={onEdit}>
              <EditIcon />
            </IconButton>
            <IconButton size="small" onClick={onDelete}>
              <DeleteIcon />
            </IconButton>
            {!project.is_completed && (
              <IconButton size="small" onClick={onComplete}>
                <CheckCircleIcon />
              </IconButton>
            )}
          </Box>
        </Box>

        <Chip
          label={project.status || '未着手'}
          sx={{
            bgcolor: getStatusColor(project.status),
            color: 'white',
            mb: 2,
          }}
        />

        {project.description && (
          <Box sx={{ mt: 1, mb: 2 }}>
            <MarkdownRenderer>
              {project.description}
            </MarkdownRenderer>
          </Box>
        )}

        <Typography variant="body2" color="text.secondary">
          期間: {project.start_date ? format(new Date(project.start_date), 'yyyy/MM/dd', { locale: ja }) : '未設定'}
          {' 〜 '}
          {project.end_date ? format(new Date(project.end_date), 'yyyy/MM/dd', { locale: ja }) : '未設定'}
        </Typography>

        {project.is_completed && (
          <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
            完了日: {format(new Date(project.complete_date), 'yyyy/MM/dd', { locale: ja })}
          </Typography>
        )}

        {(relatedGoals.length > 0 || relatedTasks.length > 0) && (
          <>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                mt: 2,
                cursor: 'pointer',
              }}
              onClick={() => setShowDetails(!showDetails)}
            >
              <Typography variant="body2" color="primary">
                関連する項目
              </Typography>
              {showDetails ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </Box>

            <Collapse in={showDetails}>
              {relatedGoals.length > 0 && (
                <>
                  <Typography variant="body2" color="text.secondary" sx={{ mt: 2, mb: 1 }}>
                    目標 ({relatedGoals.length})
                  </Typography>
                  <List dense>
                    {relatedGoals.map((goal) => (
                      <ListItem key={goal.id}>
                        <ListItemIcon>
                          <FlagIcon />
                        </ListItemIcon>
                        <ListItemText
                          primary={goal.name}
                          secondary={goal.status}
                        />
                      </ListItem>
                    ))}
                  </List>
                </>
              )}

              {relatedTasks.length > 0 && (
                <>
                  <Typography variant="body2" color="text.secondary" sx={{ mt: 2, mb: 1 }}>
                    タスク ({relatedTasks.length})
                  </Typography>
                  <List dense>
                    {relatedTasks.map((task) => (
                      <ListItem key={task.id}>
                        <ListItemIcon>
                          <TaskIcon />
                        </ListItemIcon>
                        <ListItemText
                          primary={task.content}
                          secondary={`期限: ${task.due_date ? format(new Date(task.due_date), 'yyyy/MM/dd', { locale: ja }) : '未設定'}`}
                        />
                      </ListItem>
                    ))}
                  </List>
                </>
              )}
            </Collapse>
          </>
        )}

        {project.reference_links && project.reference_links.length > 0 && (
          <Box sx={{ mt: 2 }}>
            <ReferenceLinks links={project.reference_links} />
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

export default ProjectCard;
