/**
 * @file ProjectForm.js
 * @description プロジェクトの作成・編集を行うフォームコンポーネント
 */

import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  Grid,
  Paper,
  Tooltip,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { parseISO } from 'date-fns';
import ja from 'date-fns/locale/ja';
import LinkIcon from '@mui/icons-material/Link';
import DeleteIcon from '@mui/icons-material/Delete';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { handleAddLink, handleLinkChange, handleRemoveLink } from '../../utils/commonUtils';
import { MDXEditor } from '@mdxeditor/editor';
import '@mdxeditor/editor/style.css';
import { getMDXEditorPlugins, getMDXEditorStyles } from '../../utils/mdxEditorConfig';

/**
 * プロジェクトフォームコンポーネント
 * @param {Object} props - コンポーネントのプロパティ
 * @param {Object} props.project - 編集するプロジェクトの情報（新規作成時はnull）
 * @param {Function} props.onClose - フォームを閉じる際のコールバック関数
 * @param {Function} props.onSave - プロジェクト保存時のコールバック関数
 */
const ProjectForm = ({ project, onClose, onSave }) => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [status, setStatus] = useState('未着手');
  const [referenceLinks, setReferenceLinks] = useState([{ url: '', title: '' }]);
  const [formErrors, setFormErrors] = useState({});

  const mdxPlugins = getMDXEditorPlugins();
  const mdxStyles = getMDXEditorStyles();

  useEffect(() => {
    if (project) {
      setName(project.name || '');
      setDescription(project.description || '');
      setStartDate(project.start_date ? parseISO(project.start_date) : null);
      setEndDate(project.end_date ? parseISO(project.end_date) : null);
      setStatus(project.status || '未着手');
      setReferenceLinks(
        project.reference_links && project.reference_links.length > 0
          ? project.reference_links
          : [{ url: '', title: '' }]
      );
    } else {
      // 新規作成時は初期値をリセット
      setName('');
      setDescription('');
      setStartDate(null);
      setEndDate(null);
      setStatus('未着手');
      setReferenceLinks([{ url: '', title: '' }]);
      setFormErrors({});
    }
  }, [project]);

  const validateForm = () => {
    const errors = {};
    if (!name.trim()) errors.name = 'プロジェクト名は必須です';
    if (startDate && endDate && startDate > endDate) {
      errors.endDate = '終了日は開始日より後の日付を選択してください';
    }
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    try {
      const projectData = {
        name: name.trim(),
        description: description || '',
        start_date: startDate ? startDate.toISOString() : null,
        end_date: endDate ? endDate.toISOString() : null,
        status,
        reference_links: referenceLinks.filter(link => link.url.trim() || link.title.trim()),
        is_completed: false
      };

      if (onSave) {
        await onSave(projectData);
      }
    } catch (error) {
      console.error('プロジェクトの保存中にエラーが発生しました:', error);
      setFormErrors({
        submit: error.message || 'プロジェクトの保存中にエラーが発生しました。入力内容を確認してください。'
      });
    }
  };

  return (
    <Box sx={{ 
      height: '100%', 
      display: 'flex', 
      flexDirection: 'column',
      position: 'relative'
    }}>
      <Box 
        component="form" 
        onSubmit={handleSubmit} 
        sx={{ 
          flexGrow: 1,
          overflow: 'auto',
          pb: 10
        }}
      >
        <Box sx={{ p: 3 }}>
          <Typography variant="h5" sx={{ mb: 3 }}>
            {project ? 'プロジェクトの編集' : '新しいプロジェクト'}
          </Typography>

          {/* 基本情報セクション */}
          <Paper sx={{ p: 3, mb: 3 }}>
            <Typography variant="h6" gutterBottom sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              基本情報
              <Tooltip title="プロジェクトの基本的な情報を入力してください">
                <HelpOutlineIcon fontSize="small" color="action" />
              </Tooltip>
            </Typography>

            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="プロジェクト名"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  error={!!formErrors.name}
                  helperText={formErrors.name}
                  required
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ja}>
                  <DatePicker
                    label="開始日"
                    value={startDate}
                    onChange={(newValue) => setStartDate(newValue)}
                    format="yyyy/MM/dd"
                    slotProps={{ 
                      textField: { 
                        fullWidth: true,
                        error: !!formErrors.startDate,
                        helperText: formErrors.startDate
                      } 
                    }}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={12} sm={6}>
                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ja}>
                  <DatePicker
                    label="終了日"
                    value={endDate}
                    onChange={(newValue) => setEndDate(newValue)}
                    format="yyyy/MM/dd"
                    slotProps={{ 
                      textField: { 
                        fullWidth: true,
                        error: !!formErrors.endDate,
                        helperText: formErrors.endDate
                      } 
                    }}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>状態</InputLabel>
                  <Select
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                    label="状態"
                  >
                    <MenuItem value="未着手">未着手</MenuItem>
                    <MenuItem value="進行中">進行中</MenuItem>
                    <MenuItem value="完了">完了</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Paper>

          {/* プロジェクト概要セクション */}
          <Paper sx={{ p: 3, mb: 3 }}>
            <Typography variant="h6" gutterBottom sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              プロジェクト概要
              <Tooltip title="プロジェクトの目的や概要を詳しく記述してください">
                <HelpOutlineIcon fontSize="small" color="action" />
              </Tooltip>
            </Typography>

            <Box sx={mdxStyles}>
              <MDXEditor
                markdown={description}
                onChange={(value) => setDescription(value)}
                plugins={mdxPlugins}
                contentEditableClassName="prose max-w-full"
              />
            </Box>
          </Paper>

          {/* 参考情報セクション */}
          <Paper sx={{ p: 3 }}>
            <Typography variant="h6" gutterBottom sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              参考情報
              <Tooltip title="プロジェクトに関連する参考資料やWebサイトのURLを追加してください">
                <HelpOutlineIcon fontSize="small" color="action" />
              </Tooltip>
            </Typography>

            {referenceLinks.map((link, index) => (
              <Box key={index} display="flex" alignItems="center" mb={2}>
                <TextField
                  label="URL"
                  value={link.url}
                  onChange={(e) => handleLinkChange(index, 'url', e.target.value, referenceLinks, setReferenceLinks)}
                  fullWidth
                  sx={{ mr: 1 }}
                  error={!!formErrors[`link_${index}_url`]}
                  helperText={formErrors[`link_${index}_url`]}
                />
                <TextField
                  label="タイトル"
                  value={link.title}
                  onChange={(e) => handleLinkChange(index, 'title', e.target.value, referenceLinks, setReferenceLinks)}
                  fullWidth
                  sx={{ mr: 1 }}
                  error={!!formErrors[`link_${index}_title`]}
                  helperText={formErrors[`link_${index}_title`]}
                />
                <IconButton onClick={() => handleRemoveLink(index, referenceLinks, setReferenceLinks)}>
                  <DeleteIcon />
                </IconButton>
              </Box>
            ))}
            <Button
              startIcon={<LinkIcon />}
              onClick={() => handleAddLink(setReferenceLinks)}
              variant="outlined"
              fullWidth
            >
              リンクを追加
            </Button>
          </Paper>
        </Box>
      </Box>

      {/* 固定フッターボタン */}
      <Box sx={{ 
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        p: 2,
        backgroundColor: 'background.paper',
        borderTop: 1,
        borderColor: 'divider',
        display: 'flex',
        justifyContent: 'flex-end',
        gap: 1,
        zIndex: 1
      }}>
        <Button onClick={onClose}>
          キャンセル
        </Button>
        <Button 
          type="submit" 
          variant="contained" 
          color="primary"
          onClick={handleSubmit}
        >
          保存
        </Button>
      </Box>
    </Box>
  );
};

export default ProjectForm; 