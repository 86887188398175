/**
 * @file ReflectionTable.js
 * @description 振り返り一覧を表示するテーブルコンポーネント
 */

import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  IconButton,
  Collapse,
  Box,
  Typography,
  Paper,
  Chip,
  Link,
  Button,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import WorkIcon from '@mui/icons-material/Work';
import LinkIcon from '@mui/icons-material/Link';
import MarkdownRenderer from '../common/MarkdownRenderer';
import { StyledTableCell } from '../../styles/tableStyles';
import { ReferenceLinks } from '../../utils/commonUtils';

const StyledContentTableCell = styled(TableCell)(({ theme }) => ({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: '200px',
  height: '60px',
  padding: theme.spacing(1),
  '& > *': {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    lineHeight: '1.2em',
    maxHeight: '2.4em'
  },
  '&.expanded': {
    whiteSpace: 'normal',
    maxWidth: 'none'
  }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  height: '60px',
  '& > *': {
    borderBottom: 'unset'
  }
}));

/**
 * 振り返り一覧テーブルの行コンポーネント
 */
const ReflectionRow = ({ 
  reflection, 
  selected, 
  onSelect, 
  onView, 
  onDelete 
}) => {
  const [expanded, setExpanded] = React.useState(false);

  const formatTime = (timeString) => {
    if (!timeString) return '';
    const date = new Date(timeString);
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  };

  const calculateDuration = (startTime, endTime) => {
    if (!startTime || !endTime) return '';
    const start = new Date(startTime);
    const end = new Date(endTime);
    const durationInMinutes = Math.round((end - start) / (1000 * 60));
    const hours = Math.floor(durationInMinutes / 60);
    const minutes = durationInMinutes % 60;
    return `${hours > 0 ? `${hours}時間` : ''}${minutes > 0 ? `${minutes}分` : ''}`;
  };

  const ContentSection = ({ title, content, icon }) => (
    <Box sx={{ mb: 2 }}>
      <Typography variant="subtitle2" sx={{ fontWeight: 'bold', display: 'flex', alignItems: 'center', mb: 1 }}>
        {icon} {title}
      </Typography>
      <MarkdownRenderer>
        {content}
      </MarkdownRenderer>
    </Box>
  );

  return (
    <>
      <StyledTableRow hover>
        <TableCell padding="checkbox">
          <Checkbox
            checked={selected}
            onChange={(e) => onSelect(reflection.id, e.target.checked)}
          />
        </TableCell>
        <TableCell>
          <IconButton size="small" onClick={() => setExpanded(!expanded)}>
            {expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <StyledContentTableCell>{reflection.date}</StyledContentTableCell>
        <StyledContentTableCell>
          {reflection.projects?.name || reflection.goals?.projects?.name || '-'}
        </StyledContentTableCell>
        <StyledContentTableCell>{reflection.goals?.title || '-'}</StyledContentTableCell>
        <StyledContentTableCell>
          {reflection.actual_value} {reflection.goals?.units?.name}
        </StyledContentTableCell>
        <StyledContentTableCell className={expanded ? 'expanded' : ''}>
          <MarkdownRenderer>
            {reflection.achievement}
          </MarkdownRenderer>
        </StyledContentTableCell>
        <StyledContentTableCell className={expanded ? 'expanded' : ''}>
          <MarkdownRenderer>
            {reflection.learning}
          </MarkdownRenderer>
        </StyledContentTableCell>
        <StyledContentTableCell className={expanded ? 'expanded' : ''}>
          <MarkdownRenderer>
            {reflection.improvement}
          </MarkdownRenderer>
        </StyledContentTableCell>
        <StyledContentTableCell>
          {reflection.artifacts && reflection.artifacts.length > 0 && (
            <ReferenceLinks links={reflection.artifacts} maxDisplay={1} />
          )}
        </StyledContentTableCell>
        <TableCell>
          <IconButton size="small" onClick={() => onView(reflection)}>
            <VisibilityIcon />
          </IconButton>
          <IconButton size="small" onClick={() => onDelete(reflection)}>
            <DeleteIcon />
          </IconButton>
        </TableCell>
      </StyledTableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={11}>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 2 }}>
              <Box sx={{ mb: 2 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                  <AccessTimeIcon fontSize="small" sx={{ mr: 1, color: 'text.secondary' }} />
                  <Typography variant="body2" color="text.secondary">
                    {formatTime(reflection.start_time)} ~ {formatTime(reflection.end_time)}
                    {reflection.start_time && reflection.end_time && (
                      <span style={{ marginLeft: '8px' }}>
                        ({calculateDuration(reflection.start_time, reflection.end_time)})
                      </span>
                    )}
                  </Typography>
                </Box>
              </Box>

              <ContentSection 
                title="達成したこと"
                content={reflection.achievement}
                icon={<EmojiEventsIcon fontSize="small" sx={{ mr: 1 }} />}
              />

              <ContentSection 
                title="学んだこと"
                content={reflection.learning}
                icon={<EmojiObjectsIcon fontSize="small" sx={{ mr: 1 }} />}
              />

              <ContentSection 
                title="次にやること・改善点"
                content={reflection.improvement}
                icon={<TrendingUpIcon fontSize="small" sx={{ mr: 1 }} />}
              />

              {reflection.artifacts && reflection.artifacts.length > 0 && (
                <Box sx={{ mb: 2 }}>
                  <Typography variant="subtitle2" sx={{ fontWeight: 'bold', display: 'flex', alignItems: 'center', mb: 1 }}>
                    <WorkIcon fontSize="small" sx={{ mr: 1 }} /> 成果物:
                  </Typography>
                  <ReferenceLinks links={reflection.artifacts} />
                </Box>
              )}

              {reflection.reference_links && reflection.reference_links.length > 0 && (
                <Box sx={{ mb: 2 }}>
                  <Typography variant="subtitle2" sx={{ fontWeight: 'bold', display: 'flex', alignItems: 'center', mb: 1 }}>
                    <LinkIcon fontSize="small" sx={{ mr: 1 }} /> 参考リンク:
                  </Typography>
                  <ReferenceLinks links={reflection.reference_links} />
                </Box>
              )}

              {reflection.skills && (
                <Box sx={{ mt: 2 }}>
                  {reflection.skills.map((skill) => (
                    <Chip key={skill.id} label={skill.name} size="small" sx={{ mr: 0.5, mb: 0.5 }} />
                  ))}
                </Box>
              )}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

/**
 * 振り返り一覧テーブルコンポーネント
 * @param {Object} props - コンポーネントのプロパティ
 * @param {Array} props.reflections - 表示する振り返りの配列
 * @param {Array} props.selectedReflections - 選択された振り返りのID配列
 * @param {Function} props.onSelectionChange - 選択変更時のコールバック
 * @param {Function} props.onView - 確認時のコールバック
 * @param {Function} props.onDelete - 削除時のコールバック
 * @param {string} props.sortOrder - ソート順（'asc' または 'desc'）
 * @param {Function} props.onSortChange - ソート順変更時のコールバック
 * @param {string} [props.projectId] - フィルタリングするプロジェクトID
 * @param {number} [props.initialDisplayCount=10] - 初期表示件数
 */
const ReflectionTable = ({ 
  reflections = [], 
  selectedReflections = [], 
  onSelectionChange = () => {},
  onView = () => {},
  onDelete = () => {},
  sortOrder = 'desc',
  onSortChange = () => {},
  projectId = null,
  initialDisplayCount = 10
}) => {
  const [displayCount, setDisplayCount] = useState(initialDisplayCount);

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      onSelectionChange(reflections.map(r => r.id));
    } else {
      onSelectionChange([]);
    }
  };

  const handleSelectOne = (id, checked) => {
    const newSelected = checked
      ? [...selectedReflections, id]
      : selectedReflections.filter(rid => rid !== id);
    onSelectionChange(newSelected);
  };

  const handleShowMore = () => {
    setDisplayCount(prevCount => prevCount + 10);
  };

  if (!Array.isArray(reflections)) {
    return null;
  }

  // プロジェクトIDでフィルタリング
  const filteredReflections = projectId
    ? reflections.filter(reflection => 
        reflection.project_id === projectId || 
        reflection.goals?.project_id === projectId
      )
    : reflections;

  // 表示する振り返りを制限
  const displayedReflections = filteredReflections.slice(0, displayCount);

  return (
    <Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell padding="checkbox">
                <Checkbox
                  indeterminate={selectedReflections.length > 0 && selectedReflections.length < displayedReflections.length}
                  checked={displayedReflections.length > 0 && selectedReflections.length === displayedReflections.length}
                  onChange={handleSelectAll}
                />
              </StyledTableCell>
              <StyledTableCell width={50} />
              <StyledTableCell>日付</StyledTableCell>
              <StyledTableCell>プロジェクト</StyledTableCell>
              <StyledTableCell>目標</StyledTableCell>
              <StyledTableCell>実績</StyledTableCell>
              <StyledTableCell>達成したこと</StyledTableCell>
              <StyledTableCell>学んだこと</StyledTableCell>
              <StyledTableCell>次にやること</StyledTableCell>
              <StyledTableCell>成果物</StyledTableCell>
              <StyledTableCell>操作</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {displayedReflections.map((reflection) => (
              <ReflectionRow
                key={reflection.id}
                reflection={reflection}
                selected={selectedReflections.includes(reflection.id)}
                onSelect={handleSelectOne}
                onView={onView}
                onDelete={onDelete}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {displayCount < filteredReflections.length && (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2, mb: 2 }}>
          <Button 
            variant="outlined" 
            onClick={handleShowMore}
            size="small"
          >
            さらに表示する（{filteredReflections.length - displayCount}件）
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default ReflectionTable; 