/**
 * @fileoverview 振り返りサービス
 * @description 振り返りの取得、作成、更新、削除を行うサービス
 */

import { supabase } from '../utils/supabase';

export const reflectionService = {
  /**
   * ユーザーの振り返りを取得する
   * @param {string} userId - ユーザーID
   * @param {string|null} goalId - 目標ID (オプション)
   * @param {string|null} projectId - プロジェクトID (オプション)
   * @returns {Promise<Array>} 振り返りの配列
   * @throws {Error} データ取得に失敗した場合
   */
  async fetchReflections(userId, goalId = null, projectId = null) {
    try {
      // まず、プロジェクトに直接紐づく振り返りを取得
      let query1 = supabase
        .from('reflections')
        .select(`
          id,
          created_at,
          goal_id,
          achievement,
          learning,
          improvement,
          skills,
          user_id,
          date,
          start_time,
          end_time,
          reflection_duration,
          reference_links,
          artifacts,
          actual_value,
          updated_at,
          project_id,
          projects:project_id(id, name),
          goals!left(
            id,
            title,
            is_completed,
            project_id,
            unit_id,
            units:unit_id(name),
            projects:project_id(id, name)
          )
        `)
        .eq('user_id', userId);

      if (goalId) {
        query1 = query1.eq('goal_id', goalId);
      }

      if (projectId && projectId !== 'all') {
        query1 = query1.eq('project_id', projectId);
      }

      // 次に、目標を通じて紐づく振り返りを取得
      let query2 = supabase
        .from('reflections')
        .select(`
          id,
          created_at,
          goal_id,
          achievement,
          learning,
          improvement,
          skills,
          user_id,
          date,
          start_time,
          end_time,
          reflection_duration,
          reference_links,
          artifacts,
          actual_value,
          updated_at,
          project_id,
          projects:project_id(id, name),
          goals!inner(
            id,
            title,
            is_completed,
            project_id,
            unit_id,
            units:unit_id(name),
            projects:project_id(id, name)
          )
        `)
        .eq('user_id', userId);

      if (goalId) {
        query2 = query2.eq('goal_id', goalId);
      }

      if (projectId && projectId !== 'all') {
        query2 = query2.eq('goals.project_id', projectId);
      }

      // 両方のクエリを実行
      const [{ data: data1, error: error1 }, { data: data2, error: error2 }] = await Promise.all([
        query1,
        query2
      ]);

      if (error1 || error2) {
        throw error1 || error2;
      }

      // 結果をマージして重複を除去
      const allData = [...(data1 || []), ...(data2 || [])];
      const uniqueReflections = allData.reduce((acc, reflection) => {
        if (!acc.some(r => r.id === reflection.id)) {
          acc.push(reflection);
        }
        return acc;
      }, []);

      // 作成日時でソート
      return uniqueReflections.sort((a, b) => 
        new Date(b.created_at) - new Date(a.created_at)
      );

    } catch (error) {
      console.error('振り返りの取得に失敗しました:', error);
      throw error;
    }
  },

  /**
   * 指定された日付範囲の振り返りを取得する
   * @param {string} userId - ユーザーID
   * @param {string} startDate - 開始日（YYYY-MM-DD形式）
   * @param {string} endDate - 終了日（YYYY-MM-DD形式）
   * @returns {Promise<Array>} 振り返りの配列
   * @throws {Error} データ取得に失敗した場合
   */
  async fetchReflectionsByDateRange(userId, startDate, endDate) {
    try {
      const { data, error } = await supabase
        .from('reflections')
        .select(`
          id,
          created_at,
          goal_id,
          achievement,
          learning,
          improvement,
          skills,
          user_id,
          date,
          start_time,
          end_time,
          reflection_duration,
          reference_links,
          artifacts,
          actual_value,
          updated_at,
          project_id,
          projects:project_id(id, name),
          goals!left(
            id,
            title,
            is_completed,
            project_id,
            unit_id,
            units:unit_id(name),
            projects:project_id(id, name)
          )
        `)
        .eq('user_id', userId)
        .gte('date', startDate)
        .lte('date', endDate)
        .order('date', { ascending: true });

      if (error) {
        throw new Error(`振り返りの取得に失敗しました: ${error.message}`);
      }

      return data || [];
    } catch (error) {
      console.error('振り返りの取得中にエラーが発生しました:', error);
      throw error;
    }
  },

  /**
   * 新しい振り返りを作成する
   * @param {Object} reflectionData - 振り返りデータ
   * @returns {Promise<Object>} 作成された振り返り
   * @throws {Error} 作成に失敗した場合
   */
  async createReflection(reflectionData) {
    const { data, error } = await supabase
      .from('reflections')
      .insert(reflectionData)
      .select();

    if (error) {
      console.error('振り返りの作成に失敗しました:', error);
      throw error;
    }

    return data[0];
  },

  /**
   * 振り返りを更新する
   * @param {string} reflectionId - 振り返りID
   * @param {Object} updatedReflection - 更新する振り返りデータ
   * @returns {Promise<Object>} 更新された振り返り
   * @throws {Error} 更新に失敗した場合
   */
  async updateReflection(reflectionId, updatedReflection) {
    const { data, error } = await supabase
      .from('reflections')
      .update(updatedReflection)
      .eq('id', reflectionId)
      .select();

    if (error) {
      console.error('振り返りの更新に失敗しました:', error);
      throw error;
    }

    return data[0];
  },

  /**
   * 振り返りを削除する
   * @param {string} reflectionId - 振り返りID
   * @throws {Error} 削除に失敗した場合
   */
  async deleteReflection(reflectionId) {
    const { error } = await supabase
      .from('reflections')
      .delete()
      .eq('id', reflectionId);

    if (error) {
      console.error('振り返りの削除に失敗しました:', error);
      throw error;
    }
  }
};
