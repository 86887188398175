/**
 * プロジェクト管理ページコンポーネント
 * プロジェクトの一覧表示、作成、編集、削除、完了などの機能を提供します
 * 
 * @module ProjectsPage
 */

import React, { useState } from 'react';
import {
  Box,
  Typography,
  Button,
  Container,
  Grid,
  Tabs,
  Tab,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Menu,
  Paper,
  ToggleButtonGroup,
  ToggleButton,
  ButtonBase,
  Stack,
  Zoom,
} from '@mui/material';
import {
  Add as AddIcon,
  Sort as SortIcon,
  FilterList as FilterListIcon,
  AccountTree as AccountTreeIcon,
  ViewList as ViewListIcon,
  FolderSpecial as FolderSpecialIcon,
} from '@mui/icons-material';
import { useProjectData } from '../../hooks/useProjectData';
import { useGoalData } from '../../hooks/useGoalData';
import { useTaskData } from '../../hooks/useTaskData';
import ProjectCard from '../../components/projects/ProjectCard';
import ProjectDialog from '../../components/projects/ProjectDialog';
import ProjectCompleteDialog from '../../components/projects/ProjectCompleteDialog';
import ProjectGanttChart from '../../components/projects/ProjectGanttChart';
import ProjectTable from '../../components/projects/ProjectTable';
import SlidingPage from '../../components/common/SlidingPage';
import ProjectDetailPage from '../../components/projects/ProjectDetailPage';

const ProjectsPage = () => {
  // プロジェクトの表示状態
  const [showCompletedProjects, setShowCompletedProjects] = useState(false);
  const [viewMode, setViewMode] = useState('list'); // 'list' or 'gantt'
  
  // ソート・フィルター状態
  const [sortField, setSortField] = useState('created_at');
  const [sortDirection, setSortDirection] = useState('desc');
  const [filterStatus, setFilterStatus] = useState('進行中');
  const [sortAnchorEl, setSortAnchorEl] = useState(null);
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  
  // モーダルの表示状態
  const [showProjectModal, setShowProjectModal] = useState(false);
  const [showProjectCompleteModal, setShowProjectCompleteModal] = useState(false);
  
  // 選択中のプロジェクト
  const [editingProject, setEditingProject] = useState(null);
  const [selectedProjectForComplete, setSelectedProjectForComplete] = useState(null);
  const [selectedProject, setSelectedProject] = useState(null);
  const [isDetailOpen, setIsDetailOpen] = useState(false);

  // プロジェクト、目標、タスクデータの取得
  const {
    projects,
    loading: projectsLoading,
    error: projectsError,
    handleCreateProject,
    handleUpdateProject,
    handleDeleteProject,
    handleCompleteProject,
  } = useProjectData();

  const { goals } = useGoalData();
  const { tasks } = useTaskData();

  // フィルター済みプロジェクトの取得
  const getFilteredProjects = () => {
    let filtered = [...projects];

    // ステータスでフィルター
    if (filterStatus !== 'all') {
      filtered = filtered.filter(project => project.status === filterStatus);
    }

    // 完了状態でフィルター（"すべて"選択時は完了状態でフィルターしない）
    if (filterStatus !== 'all') {
      filtered = filtered.filter(project => {
        return showCompletedProjects ? project.is_completed : !project.is_completed;
      });
    }

    // ソート
    filtered.sort((a, b) => {
      let comparison = 0;
      switch (sortField) {
        case 'name':
          comparison = a.name.localeCompare(b.name);
          break;
        case 'start_date':
          comparison = new Date(a.start_date || 0) - new Date(b.start_date || 0);
          break;
        case 'end_date':
          comparison = new Date(a.end_date || 0) - new Date(b.end_date || 0);
          break;
        case 'status':
          comparison = (a.status || '未着手').localeCompare(b.status || '未着手');
          break;
        default:
          comparison = new Date(a.created_at) - new Date(b.created_at);
      }
      return sortDirection === 'asc' ? comparison : -comparison;
    });

    return filtered;
  };

  // 関連する目標とタスクの取得
  const getRelatedItems = () => {
    const items = {};
    projects.forEach(project => {
      items[project.id] = {
        goals: goals?.filter(goal => goal.project_id === project.id) || [],
        tasks: tasks?.filter(task => task.project_id === project.id) || []
      };
    });
    return items;
  };

  // プロジェクトの追加ボタンクリック時の処理
  const handleAddProject = () => {
    setEditingProject(null);
    setShowProjectModal(true);
  };

  // プロジェクトの編集ボタンクリック時の処理
  const handleEditProject = (project) => {
    setEditingProject(project);
    setShowProjectModal(true);
  };

  // プロジェクトの削除ボタンクリック時の処理
  const handleRemoveProject = (project) => {
    if (window.confirm('このプロジェクトを削除してもよろしいですか？')) {
      handleDeleteProject(project.id);
    }
  };

  // プロジェクトの完了ボタンクリック時の処理
  const handleCompleteProjectClick = async (project) => {
    setSelectedProjectForComplete(project);
    setShowProjectCompleteModal(true);
  };

  // プロジェクトの復元ボタンクリック時の処理
  const handleRevertProject = (project) => {
    handleCompleteProject(project.id, false);
  };

  // プロジェクトのステータス変更時の処理
  const handleStatusChange = async (projectId, newStatus) => {
    try {
      await handleUpdateProject(projectId, { status: newStatus });
    } catch (error) {
      console.error('ステータスの更新に失敗しました:', error);
    }
  };

  // 完了プロジェクト表示切り替え時の処理
  const handleCompletedProjectsChange = (event, newValue) => {
    setShowCompletedProjects(newValue === 1);
    // 完了プロジェクトの表示/非表示切り替え時にリスト表示に戻す
    setViewMode('list');
    // タブ切り替え時にフィルターをリセット
    setFilterStatus(newValue === 1 ? 'all' : '進行中');
  };

  // 表示モード切り替え時の処理
  const handleViewModeChange = (event, newValue) => {
    if (newValue !== null) {
      setViewMode(newValue);
    }
  };

  const handleProjectClick = (project) => {
    setSelectedProject(project);
    setIsDetailOpen(true);
  };

  const handleCloseDetail = () => {
    setIsDetailOpen(false);
    setSelectedProject(null);
  };

  // ステータスフィルター変更時の処理
  const handleStatusFilterChange = (newStatus) => {
    setFilterStatus(newStatus);
    setFilterAnchorEl(null);
    
    // ステータスが"完了"の場合、完了プロジェクトタブに切り替える
    if (newStatus === '完了' && !showCompletedProjects) {
      setShowCompletedProjects(true);
      setViewMode('list'); // ガントチャート表示を無効化
    }
    // ステータスが"完了"以外の場合で完了タブにいる場合、進行中タブに切り替える
    else if (newStatus !== '完了' && showCompletedProjects) {
      setShowCompletedProjects(false);
    }
  };

  if (projectsLoading) {
    return <Typography>読み込み中...</Typography>;
  }

  if (projectsError) {
    return <Typography color="error">エラー: {projectsError}</Typography>;
  }

  return (
    <Container maxWidth="xl">
      {/* ヘッダー部分 */}
      <Box sx={{ mb: 4 }}>
        <Stack direction="column" spacing={2}>
          {/* 上部セクション：タイトルと追加ボタン */}
          <Stack 
            direction="row" 
            spacing={1.5}
            alignItems="center"
          >
            <Typography variant="h4" component="h1">
              プロジェクト管理
            </Typography>
            <Zoom in={true} style={{ transitionDelay: '100ms' }}>
              <ButtonBase
                onClick={handleAddProject}
                sx={{
                  borderRadius: '24px',
                  overflow: 'hidden',
                  position: 'relative',
                  ml: 1,
                  '&:hover': {
                    transform: 'scale(1.02)',
                    transition: 'transform 0.2s ease-in-out',
                  },
                }}
              >
                <Box
                  sx={{
                    background: 'linear-gradient(45deg, #4CAF50 30%, #8BC34A 90%)',
                    color: 'white',
                    py: 0.75,
                    px: 2,
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    boxShadow: '0 3px 5px 2px rgba(139, 195, 74, .3)',
                    '&:hover': {
                      background: 'linear-gradient(45deg, #388E3C 30%, #689F38 90%)',
                    },
                  }}
                >
                  <AddIcon />
                  <FolderSpecialIcon />
                  <Typography variant="button">
                    新しいプロジェクト
                  </Typography>
                </Box>
              </ButtonBase>
            </Zoom>
          </Stack>

          {/* コントロールセクション：フィルターと表示モード切り替え */}
          <Stack 
            direction={{ xs: 'column', sm: 'row' }} 
            spacing={2} 
            alignItems={{ xs: 'stretch', sm: 'center' }}
            justifyContent="space-between"
          >
            <FormControl sx={{ minWidth: { xs: '100%', sm: 200 } }}>
              <InputLabel id="project-status-filter-label">
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <FilterListIcon sx={{ mr: 1 }} />
                  ステータス
                </Box>
              </InputLabel>
              <Select
                labelId="project-status-filter-label"
                value={filterStatus}
                onChange={(e) => handleStatusFilterChange(e.target.value)}
                label="ステータス"
              >
                <MenuItem value="all">すべて</MenuItem>
                <MenuItem value="未着手">未着手</MenuItem>
                <MenuItem value="進行中">進行中</MenuItem>
                <MenuItem value="完了">完了</MenuItem>
              </Select>
            </FormControl>

            <ToggleButtonGroup
              value={viewMode}
              exclusive
              onChange={handleViewModeChange}
              aria-label="view mode"
              sx={{ 
                justifyContent: { xs: 'center', sm: 'flex-end' },
                width: { xs: '100%', sm: 'auto' }
              }}
            >
              <ToggleButton value="list" aria-label="list view">
                <ViewListIcon sx={{ mr: 1 }} />
                リスト表示
              </ToggleButton>
              <ToggleButton 
                value="gantt" 
                aria-label="gantt view"
                disabled={showCompletedProjects}
              >
                <AccountTreeIcon sx={{ mr: 1 }} />
                ガントチャート
              </ToggleButton>
            </ToggleButtonGroup>
          </Stack>
        </Stack>
      </Box>

      <Box sx={{ mb: 3 }}>
        <Tabs 
          value={showCompletedProjects ? 1 : 0} 
          onChange={handleCompletedProjectsChange}
          textColor="primary"
          indicatorColor="primary"
        >
          <Tab label="進行中のプロジェクト" />
          <Tab label="完了したプロジェクト" />
        </Tabs>
      </Box>

      {viewMode === 'list' ? (
        <ProjectTable
          projects={getFilteredProjects()}
          relatedItems={getRelatedItems()}
          onEdit={handleEditProject}
          onDelete={handleRemoveProject}
          onComplete={(project) => {
            setSelectedProjectForComplete(project);
            setShowProjectCompleteModal(true);
          }}
          onStatusChange={handleStatusChange}
          onProjectClick={handleProjectClick}
        />
      ) : (
        <ProjectGanttChart
          projects={getFilteredProjects()}
          relatedItems={getRelatedItems()}
          onProjectClick={handleProjectClick}
        />
      )}

      {/* プロジェクト追加・編集モーダル */}
      {showProjectModal && (
        <ProjectDialog
          open={showProjectModal}
          project={editingProject}
          onClose={() => {
            setShowProjectModal(false);
            setEditingProject(null);
          }}
          onSave={async (projectData) => {
            if (editingProject) {
              await handleUpdateProject(editingProject.id, projectData);
            } else {
              await handleCreateProject(projectData);
            }
            setShowProjectModal(false);
            setEditingProject(null);
          }}
        />
      )}

      {/* プロジェクト完了モーダル */}
      {showProjectCompleteModal && (
        <ProjectCompleteDialog
          open={showProjectCompleteModal}
          project={selectedProjectForComplete}
          onClose={() => {
            setShowProjectCompleteModal(false);
            setSelectedProjectForComplete(null);
          }}
          onComplete={async (completeDate) => {
            if (selectedProjectForComplete) {
              await handleCompleteProject(selectedProjectForComplete.id, completeDate);
            }
            setShowProjectCompleteModal(false);
            setSelectedProjectForComplete(null);
          }}
        />
      )}

      <SlidingPage isOpen={isDetailOpen} onClose={handleCloseDetail}>
        <ProjectDetailPage project={selectedProject} />
      </SlidingPage>
    </Container>
  );
};

export default ProjectsPage;