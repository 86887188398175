/**
 * @fileoverview 振り返りタイマーコンポーネント
 * 
 * 振り返りの実行時間を計測・表示するためのタイマーコンポーネントです。
 * 開始・一時停止・停止の制御が可能で、経過時間をリアルタイムで表示します。
 */

import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Typography, 
  Button, 
  Paper,
  IconButton,
  Tooltip,
  Divider
} from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import StopIcon from '@mui/icons-material/Stop';
import RestartAltIcon from '@mui/icons-material/RestartAlt';

const ReflectionTimer = ({ onTimeUpdate }) => {
  const [isRunning, setIsRunning] = useState(false);
  const [time, setTime] = useState(0);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);

  useEffect(() => {
    let interval;
    if (isRunning) {
      interval = setInterval(() => {
        setTime(prevTime => prevTime + 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [isRunning]);

  const handleStart = () => {
    if (!startTime) {
      setStartTime(new Date());
    }
    setIsRunning(true);
    setEndTime(null); // 再開時に終了時刻をリセット
  };

  const handlePause = () => {
    setIsRunning(false);
  };

  const handleStop = () => {
    setIsRunning(false);
    const stopTime = new Date();
    setEndTime(stopTime);
    if (onTimeUpdate) {
      onTimeUpdate({
        duration: Math.floor(time / 60),
        startTime: startTime,
        endTime: stopTime
      });
    }
  };

  const handleReset = () => {
    setIsRunning(false);
    setTime(0);
    setStartTime(null);
    setEndTime(null);
  };

  // 時間のフォーマット（HH:MM:SS）
  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  // 日時のフォーマット（YYYY/MM/DD HH:MM）
  const formatDateTime = (date) => {
    if (!date) return '';
    return date.toLocaleString('ja-JP', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false
    });
  };

  return (
    <Paper 
      elevation={3}
      sx={{
        p: 2,
        mb: 3,
        backgroundColor: '#f8f9fa',
        border: '1px solid #e9ecef'
      }}
    >
      <Box sx={{ textAlign: 'center' }}>
        <Typography variant="h4" sx={{ fontFamily: 'monospace', mb: 2 }}>
          {formatTime(time)}
        </Typography>
        <Box sx={{ mb: 2 }}>
          {startTime && (
            <Typography variant="body2" color="text.secondary">
              開始: {formatDateTime(startTime)}
            </Typography>
          )}
          {endTime && (
            <Typography variant="body2" color="text.secondary">
              終了: {formatDateTime(endTime)}
            </Typography>
          )}
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center', gap: 1 }}>
          <Tooltip title={isRunning ? "一時停止" : "開始"}>
            <IconButton 
              onClick={isRunning ? handlePause : handleStart}
              color="primary"
              size="large"
            >
              {isRunning ? <PauseIcon /> : <PlayArrowIcon />}
            </IconButton>
          </Tooltip>
          <Tooltip title="停止">
            <span>
              <IconButton 
                onClick={handleStop}
                color="error"
                size="large"
                disabled={!startTime || endTime}
              >
                <StopIcon />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title="リセット">
            <IconButton 
              onClick={handleReset}
              color="default"
              size="large"
            >
              <RestartAltIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
    </Paper>
  );
};

export default ReflectionTimer;