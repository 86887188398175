/**
 * @file ProjectTable.js
 * @description プロジェクト一覧を表示するテーブルコンポーネント
 */

import React from 'react';
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Chip,
  Tooltip,
  Box,
} from '@mui/material';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  CheckCircle as CheckCircleIcon,
  Flag as FlagIcon,
  Task as TaskIcon,
} from '@mui/icons-material';
import { format } from 'date-fns';
import { ja } from 'date-fns/locale';
import { StyledTableCell } from '../../styles/tableStyles';
import MarkdownRenderer from '../common/MarkdownRenderer';

/**
 * プロジェクト一覧テーブルコンポーネント
 * 
 * @param {Object} props - コンポーネントのプロパティ
 * @param {Array} props.projects - プロジェクトの配列
 * @param {Object} props.relatedItems - プロジェクトに関連する目標とタスクの情報
 * @param {function} props.onEdit - 編集ボタンクリック時のコールバック
 * @param {function} props.onDelete - 削除ボタンクリック時のコールバック
 * @param {function} props.onComplete - 完了ボタンクリック時のコールバック
 * @param {function} props.onStatusChange - ステータス変更時のコールバック
 * @param {function} props.onProjectClick - プロジェクトクリック時のコールバック
 */
const ProjectTable = ({ projects, relatedItems, onEdit, onDelete, onComplete, onStatusChange, onProjectClick }) => {
  const getStatusColor = (status) => {
    switch (status) {
      case '未着手':
        return '#9e9e9e';
      case '進行中':
        return '#2196f3';
      case '完了':
        return '#4caf50';
      default:
        return '#9e9e9e';
    }
  };

  const handleStatusClick = (project) => {
    const currentStatus = project.status || '未着手';
    const nextStatus = {
      '未着手': '進行中',
      '進行中': '完了',
      '完了': '未着手'
    }[currentStatus];

    onStatusChange(project.id, nextStatus);
  };

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell>プロジェクト名</StyledTableCell>
            <StyledTableCell>概要</StyledTableCell>
            <StyledTableCell>期間</StyledTableCell>
            <StyledTableCell>状態</StyledTableCell>
            <StyledTableCell>関連項目</StyledTableCell>
            <StyledTableCell>操作</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {projects.map((project) => {
            const { goals = [], tasks = [] } = relatedItems[project.id] || {};
            return (
              <TableRow 
                key={project.id}
                onClick={() => onProjectClick(project)}
                sx={{ cursor: 'pointer', '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' } }}
              >
                <StyledTableCell>{project.name}</StyledTableCell>
                <StyledTableCell>
                  <Box sx={{ maxWidth: 300, maxHeight: 100, overflow: 'auto' }}>
                    <MarkdownRenderer>
                      {project.description || ''}
                    </MarkdownRenderer>
                  </Box>
                </StyledTableCell>
                <StyledTableCell>
                  {project.start_date && format(new Date(project.start_date), 'yyyy/MM/dd', { locale: ja })}
                  {' 〜 '}
                  {project.end_date && format(new Date(project.end_date), 'yyyy/MM/dd', { locale: ja })}
                </StyledTableCell>
                <StyledTableCell>
                  <Chip
                    label={project.status || '未着手'}
                    sx={{
                      bgcolor: getStatusColor(project.status),
                      color: 'white',
                      cursor: 'pointer',
                      '&:hover': {
                        opacity: 0.8,
                      },
                    }}
                    onClick={() => handleStatusClick(project)}
                  />
                </StyledTableCell>
                <StyledTableCell>
                  <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                    {goals.length > 0 && (
                      <Tooltip title={`関連目標: ${goals.length}件`}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <FlagIcon fontSize="small" color="action" />
                          {goals.length}
                        </Box>
                      </Tooltip>
                    )}
                    {tasks.length > 0 && (
                      <Tooltip title={`関連タスク: ${tasks.length}件`}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <TaskIcon fontSize="small" color="action" />
                          {tasks.length}
                        </Box>
                      </Tooltip>
                    )}
                  </Box>
                </StyledTableCell>
                <StyledTableCell>
                  <Box sx={{ display: 'flex', gap: 1 }}>
                    <IconButton size="small" onClick={() => onEdit(project)}>
                      <EditIcon fontSize="small" />
                    </IconButton>
                    <IconButton size="small" onClick={() => onDelete(project)}>
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                    {!project.is_completed && (
                      <IconButton size="small" onClick={() => onComplete(project)}>
                        <CheckCircleIcon fontSize="small" />
                      </IconButton>
                    )}
                  </Box>
                </StyledTableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ProjectTable; 