/**
 * @fileoverview タスクサービス
 * @description タスクの取得、作成、更新、削除を行うサービス
 */

import { supabase } from '../utils/supabase';

export const taskService = {
  /**
   * ユーザーのタスクを取得する
   * @param {string} userId - ユーザーID
   * @param {string} [projectId] - フィルタリングするプロジェクトのID（オプション）
   * @param {string} [goalId] - フィルタリングする目標のID（オプション）
   * @returns {Promise<Array>} タスクの配列
   */
  async fetchTasks(userId, projectId = null, goalId = null) {
    let query = supabase
      .from('tasks')
      .select(`
        id,
        created_at,
        user_id,
        project_id,
        goal_id,
        content,
        is_completed,
        updated_at,
        priority,
        due_date,
        reference_links,
        goals:goal_id(
          id,
          title,
          project_id,
          projects:project_id(
            id,
            name
          )
        )
      `)
      .eq('user_id', userId)
      .order('created_at', { ascending: false });

    if (projectId) {
      query = query.eq('project_id', projectId);
    }

    if (goalId) {
      query = query.eq('goal_id', goalId);
    }

    const { data, error } = await query;

    if (error) {
      console.error('タスクの取得に失敗しました:', error);
      throw error;
    }

    return data.map(task => ({
      ...task,
      project_id: task.project_id || task.goals?.project_id,
      project_name: task.goals?.projects?.name,
      goal_title: task.goals?.title
    }));
  },

  /**
   * 新しいタスクを作成する
   * @param {Object} taskData - タスクデータ
   * @param {string} taskData.user_id - ユーザーID
   * @param {string} taskData.content - タスク内容
   * @param {boolean} taskData.is_completed - 完了状態
   * @param {string} [taskData.priority] - 優先度（'high', 'medium', 'low'）
   * @param {Date} [taskData.due_date] - 期限
   * @param {string|null} [taskData.project_id] - プロジェクトID
   * @param {string|null} [taskData.goal_id] - 目標ID
   * @param {Array} [taskData.reference_links] - 参照情報の配列
   * @returns {Promise<Object>} 作成されたタスク
   */
  async createTask(taskData) {
    const { data, error } = await supabase
      .from('tasks')
      .insert({
        user_id: taskData.user_id,
        content: taskData.content,
        is_completed: taskData.is_completed,
        priority: taskData.priority || 'medium',
        due_date: taskData.due_date,
        project_id: taskData.project_id,
        goal_id: taskData.goal_id,
        reference_links: taskData.reference_links || [],
        updated_at: new Date()
      })
      .select(`
        id,
        created_at,
        user_id,
        project_id,
        goal_id,
        content,
        is_completed,
        updated_at,
        priority,
        due_date,
        reference_links,
        goals:goal_id(
          id,
          title,
          project_id,
          projects:project_id(
            id,
            name
          )
        )
      `);

    if (error) {
      console.error('タスクの作成に失敗しました:', error);
      throw error;
    }

    const task = data[0];
    return {
      ...task,
      project_id: task.goals?.project_id,
      project_name: task.goals?.projects?.name,
      goal_title: task.goals?.title
    };
  },

  /**
   * タスクを更新する
   * @param {string} taskId - タスクID
   * @param {Object} updatedTask - 更新するタスクデータ
   * @returns {Promise<Object>} 更新されたタスク
   */
  async updateTask(taskId, updatedTask) {
    const { data, error } = await supabase
      .from('tasks')
      .update({
        ...updatedTask,
        project_id: updatedTask.project_id === '' ? null : updatedTask.project_id,
        goal_id: updatedTask.goal_id === '' ? null : updatedTask.goal_id,
        reference_links: updatedTask.reference_links === undefined ? undefined : updatedTask.reference_links,
        updated_at: new Date()
      })
      .eq('id', taskId)
      .select(`
        id,
        created_at,
        user_id,
        project_id,
        goal_id,
        content,
        is_completed,
        updated_at,
        priority,
        due_date,
        reference_links,
        goals:goal_id(
          id,
          title,
          project_id,
          projects:project_id(
            id,
            name
          )
        )
      `);

    if (error) {
      console.error('タスクの更新に失敗しました:', error);
      throw error;
    }

    const task = data[0];
    return {
      ...task,
      project_id: task.project_id || task.goals?.project_id,
      project_name: task.goals?.projects?.name,
      goal_title: task.goals?.title
    };
  },

  /**
   * タスクを削除する
   * @param {string} taskId - タスクID
   */
  async deleteTask(taskId) {
    const { error } = await supabase
      .from('tasks')
      .delete()
      .eq('id', taskId);

    if (error) {
      console.error('タスクの削除に失敗しました:', error);
      throw error;
    }
  }
};
