/**
 * @file TaskTableContainer.js
 * @description タスクテーブルのコンテナコンポーネント
 * 
 * タスクテーブルのフィルタリング、ソート機能、およびフィルターメニューを管理します。
 */

import React, { useState } from 'react';
import {
  Box,
  Typography,
  Menu,
  MenuItem,
  IconButton as MuiIconButton,
  FormControlLabel,
  Switch,
} from '@mui/material';
import SortIcon from '@mui/icons-material/Sort';
import TaskTable from './TaskTable';

/**
 * タスクテーブルコンテナコンポーネント
 * @param {Object} props
 * @param {Array<Object>} props.tasks - タスク一覧
 * @param {Array<Object>} props.projects - プロジェクト一覧
 * @param {Array<Object>} props.goals - 目標一覧
 * @param {boolean} props.showCompleted - 完了タスクの表示フラグ
 * @param {Function} props.setShowCompleted - 完了タスク表示フラグの設定関数
 * @param {Object} props.tableProps - TaskTableコンポーネントに渡すその他のprops
 */
const TaskTableContainer = ({
  tasks = [],
  projects = [],
  goals = [],
  showCompleted,
  setShowCompleted,
  tableProps,
}) => {
  const [sortField, setSortField] = useState('created_at');
  const [sortDirection, setSortDirection] = useState('desc');
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const [filterColumn, setFilterColumn] = useState(null);
  const [sortMenuAnchorEl, setSortMenuAnchorEl] = useState(null);

  // ソートメニューの開閉
  const handleSortMenuClick = (event) => {
    setSortMenuAnchorEl(event.currentTarget);
  };

  const handleSortMenuClose = () => {
    setSortMenuAnchorEl(null);
  };

  // ソートフィールドの選択
  const handleSortSelect = (field) => {
    setSortField(field);
    handleSortMenuClose();
  };

  // フィルターメニューの開閉
  const handleFilterClick = (event, column) => {
    setFilterAnchorEl(event.currentTarget);
    setFilterColumn(column);
  };

  const handleFilterClose = () => {
    setFilterAnchorEl(null);
    setFilterColumn(null);
  };

  // ソートラベルの取得
  const getSortLabel = () => {
    switch (sortField) {
      case 'created_at':
        return '新規作成順';
      case 'priority':
        return '優先度順';
      case 'due_date':
        return '期限順';
      case 'project':
        return 'プロジェクト順';
      case 'goal':
        return '目標順';
      default:
        return '新規作成順';
    }
  };

  // タスクのソート
  const sortedTasks = [...tasks].sort((a, b) => {
    const direction = sortDirection === 'desc' ? 1 : -1;
    switch (sortField) {
      case 'priority':
        const priorityOrder = { high: 0, medium: 1, low: 2 };
        return direction * (priorityOrder[a.priority] - priorityOrder[b.priority]);
      case 'due_date':
        if (!a.due_date) return direction;
        if (!b.due_date) return -direction;
        return direction * (new Date(a.due_date) - new Date(b.due_date));
      case 'project':
        const projectNameA = projects.find(p => p.id === a.project_id)?.name || '';
        const projectNameB = projects.find(p => p.id === b.project_id)?.name || '';
        return direction * projectNameA.localeCompare(projectNameB);
      case 'goal':
        const goalTitleA = goals.find(g => g.id === a.goal_id)?.title || '';
        const goalTitleB = goals.find(g => g.id === b.goal_id)?.title || '';
        return direction * goalTitleA.localeCompare(goalTitleB);
      case 'created_at':
      default:
        return direction * (new Date(b.created_at) - new Date(a.created_at));
    }
  });

  // フィルターメニューのレンダリング
  const renderFilterMenu = () => {
    if (!filterColumn) return null;

    let filterContent = null;
    switch (filterColumn) {
      case 'project':
        filterContent = (
          <Menu
            anchorEl={filterAnchorEl}
            open={Boolean(filterAnchorEl)}
            onClose={handleFilterClose}
          >
            <MenuItem value="all" onClick={() => {
              tableProps.setSelectedProject('all');
              handleFilterClose();
            }}>
              すべて
            </MenuItem>
            {projects.map((project) => (
              <MenuItem
                key={project.id}
                onClick={() => {
                  tableProps.setSelectedProject(project.id);
                  handleFilterClose();
                }}
                selected={tableProps.selectedProject === project.id}
              >
                {project.name}
              </MenuItem>
            ))}
          </Menu>
        );
        break;
      case 'goal':
        filterContent = (
          <Menu
            anchorEl={filterAnchorEl}
            open={Boolean(filterAnchorEl)}
            onClose={handleFilterClose}
          >
            <MenuItem value="all" onClick={() => {
              tableProps.setSelectedGoal('all');
              handleFilterClose();
            }}>
              すべて
            </MenuItem>
            {goals.map((goal) => (
              <MenuItem
                key={goal.id}
                onClick={() => {
                  tableProps.setSelectedGoal(goal.id);
                  handleFilterClose();
                }}
                selected={tableProps.selectedGoal === goal.id}
              >
                {goal.title}
              </MenuItem>
            ))}
          </Menu>
        );
        break;
      case 'priority':
        filterContent = (
          <Menu
            anchorEl={filterAnchorEl}
            open={Boolean(filterAnchorEl)}
            onClose={handleFilterClose}
          >
            <MenuItem value="all" onClick={() => {
              tableProps.setSelectedPriority('all');
              handleFilterClose();
            }}>
              すべて
            </MenuItem>
            {['high', 'medium', 'low'].map((priority) => (
              <MenuItem
                key={priority}
                onClick={() => {
                  tableProps.setSelectedPriority(priority);
                  handleFilterClose();
                }}
                selected={tableProps.selectedPriority === priority}
              >
                {tableProps.getPriorityText(priority)}
              </MenuItem>
            ))}
          </Menu>
        );
        break;
      case 'due_date':
        filterContent = (
          <Menu
            anchorEl={filterAnchorEl}
            open={Boolean(filterAnchorEl)}
            onClose={handleFilterClose}
          >
            <MenuItem value="all" onClick={() => {
              tableProps.setSelectedDueDate('all');
              handleFilterClose();
            }}>
              すべて
            </MenuItem>
            <MenuItem
              onClick={() => {
                tableProps.setSelectedDueDate('overdue');
                handleFilterClose();
              }}
              selected={tableProps.selectedDueDate === 'overdue'}
            >
              期限超過
            </MenuItem>
            <MenuItem
              onClick={() => {
                tableProps.setSelectedDueDate('today');
                handleFilterClose();
              }}
              selected={tableProps.selectedDueDate === 'today'}
            >
              今日まで
            </MenuItem>
            <MenuItem
              onClick={() => {
                tableProps.setSelectedDueDate('thisWeek');
                handleFilterClose();
              }}
              selected={tableProps.selectedDueDate === 'thisWeek'}
            >
              1週間以内
            </MenuItem>
            <MenuItem
              onClick={() => {
                tableProps.setSelectedDueDate('noDueDate');
                handleFilterClose();
              }}
              selected={tableProps.selectedDueDate === 'noDueDate'}
            >
              期限なし
            </MenuItem>
          </Menu>
        );
        break;
      default:
        break;
    }

    return filterContent;
  };

  return (
    <>
      <Box sx={{ mb: 2, display: 'flex', gap: 2, alignItems: 'center' }}>
        <FormControlLabel
          control={
            <Switch
              checked={showCompleted}
              onChange={(e) => setShowCompleted(e.target.checked)}
            />
          }
          label="完了済みを表示"
        />
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Typography variant="body2" color="text.secondary">
            {getSortLabel()}
          </Typography>
          <MuiIconButton
            size="small"
            onClick={handleSortMenuClick}
          >
            <SortIcon fontSize="small" />
          </MuiIconButton>
          <Menu
            anchorEl={sortMenuAnchorEl}
            open={Boolean(sortMenuAnchorEl)}
            onClose={handleSortMenuClose}
          >
            <MenuItem
              onClick={() => handleSortSelect('created_at')}
              selected={sortField === 'created_at'}
            >
              新規作成順
            </MenuItem>
            <MenuItem
              onClick={() => handleSortSelect('priority')}
              selected={sortField === 'priority'}
            >
              優先度順
            </MenuItem>
            <MenuItem
              onClick={() => handleSortSelect('due_date')}
              selected={sortField === 'due_date'}
            >
              期限順
            </MenuItem>
            <MenuItem
              onClick={() => handleSortSelect('project')}
              selected={sortField === 'project'}
            >
              プロジェクト順
            </MenuItem>
            <MenuItem
              onClick={() => handleSortSelect('goal')}
              selected={sortField === 'goal'}
            >
              目標順
            </MenuItem>
          </Menu>
        </Box>
      </Box>

      <TaskTable
        {...tableProps}
        tasks={sortedTasks}
        projects={projects}
        goals={goals}
        handleFilterClick={handleFilterClick}
      />

      {renderFilterMenu()}
    </>
  );
};

export default TaskTableContainer; 